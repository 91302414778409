<script lang="ts" setup>
import { ref, watch } from 'vue'

import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import axios from 'axios'
import { API } from '@/utils/API'
import { formatPrice } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import { UInput, UISelect, UButton, UTable, USwitch } from 'unit-uikit'
import { useRouter } from 'vue-router'
import { useUiStore } from '@/stores/ui'

const loading = ref(true)
const showArchive = ref(false)
const filterField = ref('')
const selectedProject = ref('allProjects')
const optionsProjects = ref([]) as any
const selectedStatus = ref('')
const objects = ref([]) as any

const router = useRouter()
const uiStore = useUiStore()

const optionsStatus = [
  {
    id: 'all',
    name: 'Все'
  },
  {
    id: 'active',
    name: 'Активен'
  },
  {
    id: 'finished',
    name: 'Завершён'
  }
]

const getObjects = () => {
  loading.value = true
  axios
    .get(`${API.GET_OBJECT}${showArchive.value ? '?archive=true' : ''}`)
    .then((res: { data: any }) => {
      objects.value = res.data
      loading.value = false
    })
    .catch((e: any) => {
      console.error(e)
      loading.value = false
    })
}

watch(showArchive, () => {
  getObjects()
})

const getStatus = (status: string) => {
  if (status === 'active') {
    return 'Активен'
  }
  if (status === 'archive') {
    return 'В архиве'
  }
  return 'Неактивен'
}

const closeEditMenu = () => {
  objects.value = objects.value.map((obj: any) => {
    obj.showEditMenu = false
    return obj
  })
}

const openEditMenu = (id: number | string) => {
  objects.value = objects.value.map((obj: any) => {
    if (id + '' === obj.id + '') {
      obj.showEditMenu = !obj.showEditMenu
    } else {
      obj.showEditMenu = false
    }
    return obj
  })
}

const filterOrders = (id: any) => {
  uiStore.filters.object = id
  router.push({
    name: 'orders.list'
  })
}

const getFiltredObjects = (objects: any) => {
  return objects
    .filter((object: any) => {
      if (selectedProject.value && selectedProject.value !== 'allProjects') {
        return object?.project?.id + '' === selectedProject.value + ''
      }
      return true
    })
    .filter((object: any) => {
      if (selectedStatus.value && selectedStatus.value !== 'all') {
        return object?.status + '' === selectedStatus.value + ''
      }
      return true
    })
    .filter(
      (i: { name: string; address: string }) =>
        i.name.toLowerCase().includes(filterField.value.toLowerCase()) || i.address.toLowerCase().includes(filterField.value.toLowerCase())
    )
}

const setStatus = (objectStatus: string, id: string | number) => {
  axios
    .patch(API.CHANGE_OBJECT_STATUS(id), { status: objectStatus === 'active' ? 'archive' : 'active' })
    .then(() => {
      getObjects()
    })
    .catch((e: any) => {
      console.error(e)
    })
}

const project = uiStore.filters.project
selectedProject.value = project || 'allProjects'
getObjects()

axios
  .get(API.GET_PROJECT)
  .then((res: { data: any }) => {
    optionsProjects.value = [
      {
        id: 'allProjects',
        name: 'Показать все'
      },
      ...res.data
    ]
    loading.value = false
  })
  .catch((e: any) => {
    console.error(e)
    loading.value = false
  })
</script>

<template>
  <Loader v-if="loading" />
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>
  <div class="flex flex-col md:flex-row flex-wrap gap-3 lg:flex-nowrap my-5">
    <div class="flex flex-wrap md:flex-nowrap gap-3 !w-full lg:!w-1/2">
      <UInput
        class="w-full md:w-auto"
        searchInput
        placeholder="Поиск"
        :value="filterField"
        v-model="filterField"
        :disabled="!objects.length"
      />
      <UISelect
        class="w-full md:w-auto !min-w-[160px]"
        id="objectProject"
        label="Проект"
        unselectedLabel="Все проекты"
        v-model="selectedProject"
        :value="selectedProject"
        :options="optionsProjects"
        :disabled="!objects.length"
      />
    </div>
    <div class="flex flex-wrap md:flex-nowrap gap-3 !w-full lg:!w-1/2">
      <UISelect
        class="w-full md:w-auto"
        id="objectStatusSelector"
        v-if="!showArchive"
        label="Cтатус"
        unselectedLabel="Статус объекта"
        v-model="selectedStatus"
        :value="selectedStatus"
        :options="optionsStatus"
        :disabled="!objects.length"
      />
      <USwitch
        class="!border-gray-200 !w-full md:!w-auto"
        v-model="showArchive"
        id="archiveViewSwitcher"
        labelText="Архив"
        labelON="Скрыть"
        labelOFF="Показать"
      />
    </div>
  </div>
  <EmptyPagePlaceholder pageType="objects" title="Объекты" v-if="!objects.length && !loading" />
  <template v-else>
    <UTable v-if="getFiltredObjects(objects).length">
      <template #thead>
        <tr>
          <th class="w-[120px]">Название</th>
          <th class="w-[120px]">Проект</th>
          <th class="w-[160px]">Бюджет</th>
          <th class="w-[100px]">Заказов</th>
          <th class="w-[130px]">Статус</th>
          <th class="w-[60px]"></th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="obj of getFiltredObjects(objects)" :key="obj.id" class="whitespace-nowrap">
          <td class="link-ceil" @click="filterOrders(obj.id)">
            <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ obj.name }}</span>
          </td>
          <td>
            <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ obj.project?.name }}</span>
          </td>
          <td class="whitespace-nowrap">{{ formatPrice(obj.budget) }}&nbsp;₽</td>
          <td>{{ obj.orders }}</td>
          <td
            :class="{
              status__active: obj.status === 'active',
              status__inactive: obj.status !== 'active'
            }"
          >
            {{ getStatus(obj.status) }}
          </td>
          <td class="!overflow-visible">
            <button type="button" class="edit-btn" @click="openEditMenu(obj.id)">
              <img class="" src="/icons/extra.svg" alt="Редактировать" />
              <div v-if="obj.showEditMenu" class="edit-menu">
                <router-link :to="`/projects/objects/edit/${obj.id}`">
                  <button class="btn-transparent">Редактировать</button>
                </router-link>
                <button v-if="obj.status !== 'archive'" @click="setStatus(obj.status, obj.id)" class="btn-transparent">В архив</button>
                <button v-if="obj.status !== 'active'" @click="setStatus(obj.status, obj.id)" class="btn-transparent">В активные</button>
              </div>
            </button>
          </td>
        </tr>
      </template>
    </UTable>
    <span v-else class="bg-white block p-4">Нет данных</span>

    <div class="w-full flex justify-end">
      <UButton label="Добавить" @click.prevent="$router.push('/projects/objects/add')" />
    </div>
  </template>
</template>

<style lang="postcss" scoped>
.link-ceil:hover {
  @apply underline text-accent cursor-pointer;
}

.status {
  &__active {
    @apply text-success;
  }

  &__inactive {
    @apply !text-error;
  }
}

.edit-menu {
  @apply flex flex-col items-start top-full right-0 translate-x-0 translate-y-0 whitespace-nowrap;
}

td,
th {
  @apply overflow-hidden text-ellipsis px-[6px];
}

:deep(table) {
  @apply table-fixed;
}
</style>
