<template>
  <h2 class="order-title">Распределение заказов по исполнителям ({{ workersAmount }})</h2>
  <div class="flex-grow overflow-auto">
    <div class="error-block-message error-block-message--static" v-if="v$?.suborder.$error">
      Пожалуйста, выберите ровно {{ budget.workersAmount }} исполнителя
    </div>
    <div class="error-block-message error-block-message--static" v-if="limitPriceAll">Превышение суммы бюджета заказа</div>
    <UTable colspan="8" class="mt-5">
      <template #thead>
        <tr class="align-top">
          <th class="w-60">Исполнитель</th>
          <th class="w-[200px]">Название заказа</th>
          <th class="w-[170px]">Период</th>
          <th class="w-[100px] whitespace-nowrap">Объем работ</th>
          <th class="w-[100px]">Тип единиц</th>
          <th :class="{ '!w-[300px]': isLimitErr }" class="relative w-[120px]">
            <div class="relative whitespace-nowrap">
              Бюджет
              <div class="flex">
                <span class="whitespace-nowrap">(включая налог)</span>
                <UTooltip class="!relative !left-1 !top-0" tooltip="Конечная сумма, которую необходимо заплатить исполнителю" />
              </div>
            </div>
            <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatPrice(getTotalBudget) }}</p>
          </th>
          <th class="w-[120px]">
            <div class="relative whitespace-nowrap">
              <span class="whitespace-nowrap">Лимит на выплату</span>
              <div class="flex">
                <span class="whitespace-nowrap">(включая налог)</span>
                <UTooltip
                  class="!relative !left-1 !top-0"
                  :tooltip="
                    order?.withholding_tax
                      ? 'Максимально возможный платеж исполнителю включая налог с автоматическим удержанием налога банком партнером на текущий момент'
                      : 'Максимально возможный платеж исполнителю включая налог без автоматического удержания налога банком партнером'
                  "
                />
              </div>
            </div>
          </th>
          <th class="w-[50px]"></th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="suborder in order?.suborder" :key="suborder.id">
          <td class="min-w-[310px]">
            <WorkerTableItem class="whitespace-nowrap" :startDate="suborder.start_date" :worker="suborder" />
          </td>
          <td class="overflow-hidden whitespace-nowrap">
            <p class="whitespace-nowrap">
              <span v-if="isSuborderNameReady" class="block overflow-hidden text-ellipsis" v-tooltip> {{ suborder.name }}</span>
            </p>
          </td>
          <td class="relative whitespace-nowrap">
            <div class="flex items-center">
              <UTooltip
                v-if="suborder.current_agremeent_exist && suborder.new_agremeent_warning"
                info
                class="!static mr-3 min-w-[1rem]"
                :tooltip="`Действующий договор ${suborder.current_start_date} - ${suborder.current_end_date} будет завершен после создания заказа`"
              />
              {{ suborder?.start_date || formatDate(order?.start_date) }}
              <span class="mx-1">-</span>
              {{ suborder?.finish_date || formatDate(order?.finish_date) }}
            </div>
          </td>
          <td class="relative whitespace-nowrap">
            {{ suborder.quantity }}
          </td>
          <td class="whitespace-nowrap">
            <span class="block overflow-hidden text-ellipsis" v-tooltip> {{ getUnitType(suborder.unit_types) }}</span>
          </td>
          <td class="relative whitespace-nowrap">
            <WorkerPriceTableItem
              @limitError="handleLimitError"
              :tax="order.withholding_tax"
              :suborder="suborder"
              :price="suborder.price"
              :limitWithTax="order.monthly_limit_with_tax"
              :limitWithoutTax="order.monthly_limit_without_tax"
            />
          </td>
          <td>
            <WorkerLimitsTableItem
              :tax="order.withholding_tax"
              :order="suborder"
              :limitWithTax="suborder.month_limit"
              :limitWithoutTax="suborder.month_limit_without_tax"
            />
          </td>
          <td class="text-right">
            <button type="button" class="edit-btn bg-white static" @click="editableSuborder = suborder">
              <img src="/icons/pencil.svg" alt="Редактировать" />
            </button>
          </td>
        </tr>
      </template>
    </UTable>
  </div>

  <template v-if="editableSuborder">
    <ChangeSuborderModal
      title="Редактирование заказа"
      :tax="order.withholding_tax"
      :limit="order.withholding_tax ? order.monthly_limit_with_tax : order.monthly_limit_without_tax"
      :limitTypes="[order.monthly_limit_with_tax, order.monthly_limit_without_tax]"
      :show="true"
      :suborder="editableSuborder"
      @closeModal="editableSuborder = null"
      @successModal="changeSuborder"
    />
  </template>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import { UTooltip, UTable } from 'unit-uikit'
import { formatPrice } from '@/utils/helpers'
import { APIAddWorkersBudget, APIAddWorkersOrder } from '@/interfaces/main'
import moment from 'moment'
import WorkerTableItem from './OrderComponents/WorkerTableItem.vue'
import ChangeSuborderModal from '@/components/Modals/ChangeSuborderModal.vue'
import WorkerLimitsTableItem from './OrderComponents/WorkerLimitsTableItem.vue'
import WorkerPriceTableItem from './OrderComponents/WorkerPriceTableItem.vue'

export default defineComponent({
  name: 'AddOrder2',
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    v$: Object,
    budget: {
      type: Object as PropType<APIAddWorkersBudget>,
      required: true
    },
    specialErrors: {
      type: Object as any
    }
  },
  data() {
    return {
      unitTypesList: [],
      workersAmount: null as any,
      workersIDs: [] as any,
      editableSuborder: null as any,
      isLimitErr: false,
      isSuborderNameReady: false
    }
  },
  components: {
    UTooltip,
    WorkerTableItem,
    ChangeSuborderModal,
    UTable,
    WorkerLimitsTableItem,
    WorkerPriceTableItem
  },
  methods: {
    handleLimitError(limitError: any) {
      limitError ? (this.isLimitErr = true) : (this.isLimitErr = false)
    },
    formatDate(date: any) {
      return moment(date).format('DD.MM.yy')
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    getUnitType(type: number) {
      const result = this.unitTypesList
        .filter((item: any) => {
          return item.id + '' === type + ''
        })
        .map((item: any) => {
          return item.name
        })
      return (result && result[0]) || ''
    },
    changeSuborder(data: any) {
      this.order.suborder = this.order.suborder.map((order: any) => {
        if (order.id + '' === this.editableSuborder.id + '') {
          order.quantity = data.quantity
          order.price = data.price
          order.name = data.name
          order.description = data.description
          order.unit_types = data.unit_type
          order.start_date = this.formatDate(data.start_date)
          order.finish_date = this.formatDate(data.finish_date)
        }
        return order
      })
      axios
        .post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, {
          workers: this.workersIDs
        })
        .then((res: any) => {
          this.order.suborder.forEach((worker: any) => {
            if (worker?.start_date) {
              const newFormattedDate = new Date(
                `${worker.start_date.slice(6, 10)}.${worker.start_date.slice(3, 5)}.${worker.start_date.slice(0, 2)}`
              )
              res?.data.forEach((item: any) => {
                const oldFormattedDate = new Date(item.start_date)
                // newFormattedDate < oldFormattedDate ? (worker.new_agremeent_warning = true) : (worker.new_agremeent_warning = false)
                if (newFormattedDate < oldFormattedDate) {
                  if (worker.id === item.worker_id) {
                    worker.current_agremeent_exist = Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = Object.values(item).includes(worker.id)
                  }
                }
                if (newFormattedDate.toDateString() === oldFormattedDate.toDateString()) {
                  if (worker.id === item.worker_id) {
                    worker.current_agremeent_exist = !Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = !Object.values(item).includes(worker.id)
                  }
                }
              })
            }
          })
        })
        .catch((error: any) => {
          console.error(error)
        })
      this.editableSuborder = null
    }
  },
  created() {
    setTimeout(() => {
      this.workersAmount = this.budget.workersAmount

      this.order.suborder.forEach((worker: any) => {
        this.workersIDs.push(worker.id)
      })

      this.isSuborderNameReady = true

      axios
        .post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, {
          workers: this.workersIDs
        })
        .then((res: any) => {
          this.order.suborder.forEach((worker: any) => {
            if (worker?.start_date) {
              const newFormattedDate = new Date(
                `${worker.start_date.slice(6, 10)}.${worker.start_date.slice(3, 5)}.${worker.start_date.slice(0, 2)}`
              )
              res?.data.forEach((item: any) => {
                const oldFormattedDate = new Date(item.start_date)
                if (newFormattedDate < oldFormattedDate) {
                  if (worker.id === item.worker_id) {
                    worker.current_start_date = this.formatDate(item.start_date)
                    worker.current_end_date = this.formatDate(item.end_date)
                    worker.current_agremeent_exist = Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = Object.values(item).includes(worker.id)
                  }
                }
                if (newFormattedDate.toDateString() === oldFormattedDate.toDateString()) {
                  if (worker.id === item.worker_id) {
                    worker.current_start_date = this.formatDate(item.start_date)
                    worker.current_end_date = this.formatDate(item.end_date)
                    worker.current_agremeent_exist = !Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = !Object.values(item).includes(worker.id)
                  }
                }
              })
            }
          })
        })
        .catch((error: any) => {
          console.error(error)
        })

      axios.get(API.GET_UNIT_TYPES).then((res: any) => {
        this.unitTypesList = res.data
      })

      const length: any = this.budget.workersAmount

      let quantity = this.order?.quantity
      const budget = this.budget.workerWithTax ? this.budget.workerWithTax : 0
      const taskName = this.order?.name
      const unitTypes = this.order?.unit_type
      const startDate = this.formatDate(this.order?.start_date)
      const finishDate = this.formatDate(this.order?.finish_date)
      const description = this.order?.description || this.order?.name

      if (length) {
        const amount: any = quantity
        if (amount) {
          const value = amount / length
          quantity = value.toFixed(2)
        }
      }

      this.order.suborder = this.order.suborder.map((item: any) => {
        item.quantity = item.quantity || quantity
        item.price = item.price || budget
        item.name = item.name || taskName
        item.unit_types = item.unit_types || unitTypes
        item.start_date = item.start_date || startDate
        item.finish_date = item.finish_date || finishDate
        item.description = item.description || description

        return item
      })
    }, 200)
  },
  computed: {
    getTotalBudget() {
      const budget: any = this.budget?.workerWithTax ? this.budget?.workerWithTax : 0
      const limit = budget * this.workersAmount
      return limit
    },
    limitPriceAll() {
      const limit = this.getTotalBudget
      const usedValues = this.order?.suborder.reduce((prev: any, next: any) => {
        const price = (next.price + '').replaceAll(',', '.').replaceAll(' ', '')
        return prev + +price
      }, 0)

      if (limit - usedValues < 0) {
        if (this.specialErrors) {
          this.specialErrors.limitPrice = true
          return true
        }
      }

      if (this.specialErrors) {
        this.specialErrors.limitPrice = false
        return false
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
td,
th {
  @apply px-[6px];
}
:deep(table) {
  @apply table-fixed;
  & th {
    @apply whitespace-nowrap;
  }
}

.error-block-message.error-block-message--static {
  @apply mt-0 z-10 static;
}

:deep(.worker-description h3) {
  @apply whitespace-normal;
}
</style>
