const docTypesOptions = [
  {
    value: 'acceptance_certificate',
    label: 'Акты'
  },
  {
    value: 'task_order',
    label: 'Задания'
  },
  {
    value: 'agreement',
    label: 'Договоры'
  },
  {
    value: 'receipt',
    label: 'Чеки'
  },
  {
    value: 'document',
    label: 'Документы'
  },
  {
    value: 'start_work_notification',
    label: 'Приложение 7'
  },
  {
    value: 'end_work_notification',
    label: 'Приложение 8'
  }
]

const docTypesStatuses = [
  {
    value: 'signed',
    label: 'Подписан'
  },
  {
    value: 'not_signed',
    label: 'Не подписан'
  },
  {
    value: 'finished',
    label: 'Завершён'
  },
  {
    value: 'active',
    label: 'Действующий'
  },
  {
    value: 'filed',
    label: 'Подан'
  },
  {
    value: 'exists',
    label: 'Есть'
  },
  {
    value: 'rejected',
    label: 'Отклонен'
  },
  {
    value: 'terminated',
    label: 'Расторгнут'
  },
  {
    value: 'revoked',
    label: 'Отозван'
  }
]

function formatDocType(type: string): string {
  if (type && type.includes('cert') && !type.includes('acceptance_certificate')) {
    return 'Сертификат'
  }
  const typeMap: { [key: string]: string } = {
    start_work_notification: 'Приложение 7',
    end_work_notification: 'Приложение 8',
    agreement: 'Договор',
    task_order: 'Задание',
    acceptance_certificate: 'Акт',
    receipt: 'Чек',
    document: 'Документ'
  }
  return typeMap[type] || ''
}

export { docTypesOptions, docTypesStatuses, formatDocType }
