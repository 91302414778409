import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import { EAccountTypes } from '@/types/api-values'
import { SERVER_COUNTRIES } from '@/utils/consts'

function formatDate(date: string) {
  if (date) {
    const dateArr = date.split('.')
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
  return ''
}

function getVnzhArray(vnzh = '') {
  return vnzh.split('№')
}

export const useStaffStore = defineStore('staff', () => {
  const inn = ref('')
  const username = ref('')

  const info = ref({
    email: '',
    middleName: '',
    birthdate: '',
    name: '',
    surname: '',
    gender: ''
  })

  const passport = ref({
    passportType: 'Внутренний',
    passport_series: '',
    passport_number: '',
    passport_id_number: '',
    registered_address: '',
    postal_code: '',
    place_of_birth: '',
    date_of_issue: '',
    subdivision_code: '',
    passport_whom_given: '',
    vnzh: ''
  })

  const photos = ref({
    passportReversal: null,
    passportPhotoRegisteredAddress: null
  })

  const company_id = ref('')

  const company = ref({
    position: '',
    name: ''
  })

  const passportPhotoSelfie = ref(null)
  const citizenship = ref(null) as Ref<null | string>

  function $reset() {
    inn.value = ''
    username.value = ''
    info.value = {
      email: '',
      middleName: '',
      birthdate: '',
      name: '',
      surname: '',
      gender: ''
    }
    passport.value = {
      passportType: 'Внутренний',
      passport_series: '',
      passport_number: '',
      passport_id_number: '',
      registered_address: '',
      postal_code: '',
      place_of_birth: '',
      date_of_issue: '',
      subdivision_code: '',
      passport_whom_given: '',
      vnzh: ''
    }
    photos.value = {
      passportReversal: null,
      passportPhotoRegisteredAddress: null
    }
    company_id.value = ''
    company.value = {
      position: '',
      name: ''
    }

    passportPhotoSelfie.value = null
    citizenship.value = null
  }

  const getReferral = () => {
    if (company_id.value) {
      return company_id.value
    }
    const ref = sessionStorage.getItem('REF_ID')
    company_id.value = ref || ''
    return ref
  }

  const setReferral = (id: string) => {
    sessionStorage.setItem('REF_ID', id)
    company_id.value = id
  }

  const updateCompany = (data: { position: string; name: string; username: string }) => {
    company.value = {
      position: data.position,
      name: data.name
    }
    username.value = data.username
  }

  const updateSelfie = (data: any) => {
    passportPhotoSelfie.value = data.passportPhotoSelfie
  }

  const updatePhotos = (data: { passportReversal: any; passportPhotoRegisteredAddress: any }) => {
    photos.value = data
  }

  const updatePassport = (passportValue: any) => {
    passport.value.passport_series = passportValue.passport_series
    passport.value.passport_number = passportValue.passport_number
    passport.value.passport_id_number = passportValue.passport_id_number
    passport.value.registered_address = passportValue.registered_address
    passport.value.postal_code = passportValue.postal_code
    passport.value.place_of_birth = passportValue.place_of_birth
    passport.value.date_of_issue = passportValue.date_of_issue
    passport.value.subdivision_code = passportValue.subdivision_code
    passport.value.passport_whom_given = passportValue.passport_whom_given
    passport.value.vnzh = passportValue.vnzh
  }

  const updateCitizen = (citizen: string) => {
    citizenship.value = citizen
  }

  const updateInfo = (data: any) => {
    info.value = data
  }

  const updateInn = (newInn: string) => {
    inn.value = newInn
  }

  const getState = () => {
    return {
      inn: inn.value,
      username: username.value,
      info: info.value,
      passport: passport.value,
      photos: photos.value,
      company_id: company_id.value,
      company: company.value,
      passportPhotoSelfie: passportPhotoSelfie.value,
      citizenship: citizenship.value
    }
  }

  const getDataForAddProfile = () => {
    const date = new Date()
    const dateJoined = date.toISOString()
    const result: any = {
      user: {
        username: username.value,
        first_name: info.value.name,
        last_name: info.value.surname,
        middle_name: info.value.middleName,
        // complete_data: true,
        account_type: EAccountTypes.staff,
        email: info.value.email,
        date_joined: dateJoined,
        position: company.value.position
      },
      inner_passport: {
        type: passport.value.passportType,
        registered_address: passport.value.registered_address,
        place_of_birth: passport.value.place_of_birth,
        passport_series: passport.value.passport_series,
        passport_number: passport.value.passport_number,
        passport_id_number: passport.value.passport_id_number,
        passport_whom_given: passport.value.passport_whom_given,
        date_of_issue: formatDate(passport.value.date_of_issue),
        subdivision_code:
          citizenship.value === SERVER_COUNTRIES.RUSSIA ? passport.value.subdivision_code?.replaceAll('-', '').trim().slice(0, 6) : '',
        passport_photo_reversal: photos.value.passportReversal,
        passport_photo_registered_address: photos.value.passportPhotoRegisteredAddress,
        selfie: passportPhotoSelfie.value
      },
      company_id: company_id.value,
      // region: "Moscow",
      citizenship: citizenship.value,
      date_of_birth: formatDate(info.value.birthdate),
      sex: info.value.gender === 'male' ? 'Муж' : 'Жен',
      inn: inn.value,
      index: passport.value.postal_code,
      position: company.value.position
    }

    if (passport.value.vnzh && passport.value.vnzh.length > 3) {
      const [vnzhSeries, vnzhNumber] = getVnzhArray(passport.value.vnzh)
      result.residence_permit = {
        residence_permit_series: vnzhSeries,
        residence_permit_number: vnzhNumber
      }
    }

    return result
  }

  return {
    inn,
    username,
    info,
    passport,
    photos,
    company_id,
    company,
    passportPhotoSelfie,
    citizenship,
    getReferral,
    setReferral,
    updateCompany,
    updateSelfie,
    updatePhotos,
    updatePassport,
    updateCitizen,
    updateInfo,
    updateInn,
    getState,
    getDataForAddProfile,
    $reset
  }
})
