<template>
  <div class="text-left flex flex-col overflow-auto">
    <h2 class="text-2xl font-bold mb-5">Основная информация</h2>

    <Loader v-if="!loaded" />

    <div class="flex mb-5">
      <template v-if="loaded">
        <UISelect
          id="orderStatusSelector"
          label="Cтатус реестра заказов"
          unselectedLabel="Cтатус реестра заказов"
          v-model="order.status"
          :value="order.status || ''"
          :options="orderStatuses"
          :disabled="order.status === 'finished' || order.status === 'cancelled'"
        />
      </template>
    </div>

    <div class="bg-white space-y-4 p-3 rounded-lg shadow-main">
      <h2 class="text-lg font-medium break-words">{{ order.name }}</h2>
      <div class="grid grid-cols-3 gap-2">
        <div class="flex items-center">
          <img src="/icons/order/project.svg" alt="Project" class="w-5 h-5" />
          <p class="ml-2">{{ order.object?.project?.name || '' }}</p>
        </div>

        <div class="flex items-center">
          <img src="/icons/order/object.svg" alt="Project" class="w-5 h-5" />
          <p class="ml-2">{{ order?.object?.name || '' }}</p>
        </div>

        <div class="flex items-center">
          <img src="/icons/order/address.svg" alt="address" class="w-5 h-5" />
          <p class="ml-2">{{ order.address }}</p>
        </div>

        <div class="flex items-center">
          <img src="/icons/order/dates.svg" alt="Dates" class="w-5 h-5" />
          <p class="ml-2">{{ formatDateTime(order.start_date) }} &mdash; {{ formatDateTime(order.finish_date) }}</p>
        </div>

        <div class="flex items-center">
          <img src="/icons/order/budget.svg" alt="Project" class="w-5 h-5" />
          <p class="ml-2">{{ formatPrice(order.budget_total || order.budget) }} руб</p>
        </div>
      </div>
      <div class="flex">
        <div>
          <h3 class="text-grey mb-1">Описание услуг и работ</h3>
          <p class="text-sm font-medium">{{ order.description }}</p>
        </div>
      </div>

      <div class="flex gap-5 items-start mb-1">
        <div class="basis-1/3">
          <h3 class="text-grey">Специализированные требования к заказу</h3>
          <p class="text-sm font-medium">{{ order.special_requirements }}</p>
        </div>

        <div class="basis-1/3">
          <h3 class="text-grey">Применяемая техника, материалы, требования к допуску</h3>
          <p class="text-sm font-medium">{{ getEquipment() }}</p>
        </div>

        <div class="basis-1/3">
          <h3 class="text-grey">Требования к сертификатам</h3>
          <p class="text-sm font-medium">{{ getJobCerts() }}</p>
        </div>
      </div>

      <div :class="{ 'mb-3': !order.omvd }" class="flex gap-5 items-start">
        <div class="basis-1/3">
          <h3 class="text-grey">Шаблон рамочного договора</h3>
          <p class="text-sm font-medium">{{ formatTemplateName(order.agreement_template?.name) }}</p>
          <a target="_blank" :href="order.agreement_template?.file">Посмотреть шаблон</a>
        </div>

        <div class="basis-1/3">
          <h3 class="text-grey">Шаблон договора задания</h3>
          <p class="text-sm font-medium">{{ formatTemplateName(order.order_template?.name) }}</p>
          <a target="_blank" :href="order.order_template?.file">Посмотреть шаблон</a>
        </div>

        <div class="basis-1/3">
          <h3 class="text-grey">Шаблон акта</h3>
          <p class="text-sm font-medium">{{ formatTemplateName(order.acceptance_certificate_template?.name) }}</p>
          <a target="_blank" :href="order.acceptance_certificate_template?.file">Посмотреть шаблон</a>
        </div>
      </div>

      <div v-if="order.omvd" class="flex">
        <div>
          <h3 class="text-grey">Отделения МВД</h3>
          <p class="text-sm font-medium">{{ order.omvd }}</p>
        </div>
      </div>
    </div>
  </div>

  <UModal
    :show="showCancelPopup && showPopupAtCreated"
    title="Отмена заказа"
    cancelLabel="Закрыть"
    successLabel="Отменить заказ"
    @cancel="closePopup"
    @success="cancelOrder($route.params.id)"
  >
    <template #content>
      <p class="mb-4">Вы уверены что хотите отменить заказ?</p>
      <p v-if="signedError" class="text-error mb-3">Вы не можете отменить заказ так как один из документов подписан</p>
    </template>
  </UModal>

  <UModal
    :show="cannotCancelOrder"
    title="Ошибка в отмене заказа"
    cancelLabel=""
    successLabel="Понятно"
    @cancel="closeErrorPopup"
    @success="closeErrorPopup"
  >
    <template #content>
      <p>Невозможно отменить этот реестр заказов, так как один или несколько заказов еще активны</p>
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UModal, UISelect } from 'unit-uikit'
import { API } from '@/utils/API'
import axios from 'axios'
import { formatPrice } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import { useOrderStore } from '@/stores/order'

export default defineComponent({
  name: 'order',
  components: {
    Loader,
    UISelect,
    UModal
  },
  data() {
    return {
      orderStore: useOrderStore(),
      loaded: false,
      showCancelPopup: false,
      showPopupAtCreated: true,
      signedError: false,
      cannotCancelOrder: false,
      order: {} as any,
      orderStatuses: [
        {
          id: 'active',
          name: 'Активный'
        },
        {
          id: 'cancelled',
          name: 'Отменен'
        }
      ],
      pageReady: false
    }
  },
  created() {
    this.orderStore
      // @ts-ignore
      .fetchOrder(this.$route?.params?.id)
      .then((order: any) => {
        this.order = order
        if (this.order?.status === 'cancelled') {
          this.showCancelPopup = false
          this.showPopupAtCreated = false
        }
        if (order?.status === 'finished') {
          this.orderStatuses.push({
            id: 'finished',
            name: 'Завершён'
          })
        }
        this.loaded = true
      })
      .catch((e: any) => {
        console.error(e)
      })
    this.showCancelPopup = false
  },
  mounted() {
    this.pageReady = true
    this.showCancelPopup = false
  },
  methods: {
    getEquipment() {
      if (this.order.equipment) {
        if (Array.isArray(this.order.equipment)) {
          return this.order.equipment.length ? this.order.equipment.map((item: any) => item.name).join(', ') : 'Нет'
        }
        return this.order.equipment || 'Нет'
      }
      return 'Нет'
    },
    getJobCerts() {
      if (this.order.job_certs_requirements) {
        if (Array.isArray(this.order.job_certs_requirements)) {
          return this.order.job_certs_requirements.length
            ? this.order.job_certs_requirements.map((item: any) => item.name).join(', ')
            : 'Нет'
        }
        return this.order.job_certs_requirements || 'Нет'
      }
      return 'Нет'
    },
    getSingleOrderData(id: any) {
      this.orderStore
        .fetchOrder(id)
        .then((order: any) => {
          this.order = order
          this.loaded = true
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    formatDateTime(date: string) {
      if (date) {
        return date.split('-').reverse().join('.')
      }
    },
    getOrderEquipment(equipment: any) {
      if (equipment.length === 0) return 'Нет'
      return equipment
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatTemplateName(name: string) {
      if (name) {
        const chars = name.split('')
        chars[0] = chars[0].toUpperCase()
        return chars.join('').replaceAll('_', ' ')
      }
      return ''
    },
    cancelOrder(id: any) {
      axios
        .get(API.GET_ORDER_DOCUMENTS(id))
        .then((response: any) => {
          const data = response.data
          const result = data.some((item: any) => {
            const value = [item.agreement_status[0].status, item.task_order_status[0].statusOptions].includes('SIGNED')
            return value
          })

          if (!result) {
            this.signedError = false
            axios
              .patch(API.DELETE_SINGLE_ORDER(id), { status: 'cancelled' })
              .then(() => {
                this.getSingleOrderData(this.$route.params.id)
                this.showCancelPopup = false
              })
              .then((res: any) => console.log(res))
              .catch((e: any) => {
                this.signedError = true
                console.error(e)
              })
          } else {
            this.signedError = true
          }
        })
        .catch(() => {
          this.cannotCancelOrder = true
        })
    },
    closePopup() {
      this.order.status = 'active'
      this.signedError = false
      this.showCancelPopup = false
    },
    closeErrorPopup() {
      this.showCancelPopup = false
      this.cannotCancelOrder = false
      this.order.status = 'active'
    }
  },
  watch: {
    'order.status'(val) {
      if (val === 'cancelled') {
        this.showCancelPopup = true
      }
    }
  }
})
</script>
