import axios from 'axios'
import { API } from '@/utils/API'
import { useAuthStore } from '@/stores/auth'
import { TUser } from '@/types'

function saveUserPhone(phone: string) {
  localStorage.setItem('userPhone', phone)
}

class AuthService {
  async refreshToken() {
    const authStore = useAuthStore()
    const jwt = authStore.jwt
    const refresh_token = jwt?.refresh_token
    const access_token = jwt?.access_token

    if (refresh_token) {
      return axios
        .post(API.REFRESH_TOKEN, {
          refresh: refresh_token,
          old_access: access_token
        })
        .then(response => {
          const access_token = response?.data?.access

          if (access_token) {
            authStore.saveJWT({
              access_token,
              refresh_token
            })
          }

          return response.data
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
    return Promise.reject()
  }

  async login(user: TUser) {
    saveUserPhone(user?.phone)
    const authStore = useAuthStore()

    const phone = user?.phone?.replace(/-/g, '')
    return axios
      .post(API.AUTH_USERS, {
        phone,
        account_type: user.account_type
      })
      .then(response => {
        if (response.data.access_token) {
          authStore.saveJWT(response.data)
        }

        return response.data
      })
  }

  async loginConfirm(user: TUser) {
    saveUserPhone(user?.phone)
    const authStore = useAuthStore()
    const phone = user?.phone?.replace(/-/g, '')

    return axios
      .post(API.AUTH_USERS_OTP, {
        phone,
        account_type: user.account_type,
        code: user.code
      })
      .then(response => {
        if (response.data.access_token) {
          authStore.saveJWT(response.data)
        }

        return response.data
      })
  }

  async register(user: any) {
    saveUserPhone(user?.phone)
    const phone = user?.phone?.replace(/-/g, '')

    return axios
      .post(API.SIGNUP, {
        name: user.name,
        phone,
        account_type: user.account_type,
        company_id: user.company_id,
        is_invite: user.is_invite
      })
      .then(response => {
        return response.data
      })
  }

  async registerConfirm(user: any) {
    saveUserPhone(user?.phone)
    const authStore = useAuthStore()
    const phone = user?.phone?.replace(/-/g, '')

    return axios
      .post(API.SIGNUP_OTP, {
        name: user.name,
        phone,
        account_type: user.account_type,
        code: user.code
      })
      .then(response => {
        if (response.data.access_token) {
          authStore.saveJWT(response.data)
        }

        return response.data
      })
  }
}

export default new AuthService()
