<script setup lang="ts">
import { computed, ref, Ref, watch, onUnmounted } from 'vue'

import Loader from '@/components/Loader/Loader.vue'
import axios from 'axios'
import { API } from '@/utils/API'
import { formatPrice, getQiwiErrCode, formatPhone, formatName } from '@/utils/helpers'
import moment from 'moment'
import { UTooltip, UButton, UTable, UInput, UISelect, UModal, UICheckbox } from 'unit-uikit'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import CheckSmsCode from '@/components/Modals/CheckSmsCode.vue'
import d from '@/dictionary'
import MultiplePayment from '@/views/Finance/Components/MultiplePayment.vue'

import ErrorTooltip from '@/components/Tooltips/ErrorTooltip.vue'
import { TRANSACTION_STATUS } from '@/utils/consts'
import { ETransactionsStatus } from '@/types/api-values'
import debounce from '@/utils/debounce'

import { useSmsTimer } from '@/composables/sms-timer'
import { useLongPulling } from '@/views/Finance/Components/long-pulling'
import { useAuthStore } from '@/stores/auth'
import { useCompanyStore } from '@/stores/company'
import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
const { payload } = storeToRefs(authStore)
const companyStore = useCompanyStore()
const { isProcurationEnded } = storeToRefs(companyStore)

const phone = computed(() => {
  const _phone = formatPhone(payload.value?.username || '')
  return _phone
})

const bills = ref([]) as any
const checkedBills = ref([]) as Ref<string[]>
const loading = ref(true)
const showSMSPopup = ref(false)
const showErrorPopup = ref(false)
const errorMessage = ref('')
const created = ref(false)
const selectedStatus = ref('')
const searchFirstName = ref('')
const searchLastName = ref('')

const { timer, startTimer } = useSmsTimer()
const { addToPulling, fetchBillsInfo, clear } = useLongPulling()

const statusOptions = [
  {
    id: '',
    name: 'Все'
  },
  {
    id: ETransactionsStatus.awaiting,
    name: TRANSACTION_STATUS[ETransactionsStatus.awaiting]
  },
  {
    id: ETransactionsStatus.not_signed,
    name: TRANSACTION_STATUS[ETransactionsStatus.not_signed]
  },
  {
    id: ETransactionsStatus.processing,
    name: TRANSACTION_STATUS[ETransactionsStatus.processing]
  }
]

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

const updateData = async () => {
  if (bills.value.length < scrollData.count) {
    let queryParams = ''
    if (searchFirstName.value) {
      queryParams += `&first_name=${searchFirstName.value}`

      if (bills.value.length && !(bills.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (searchLastName.value) {
      queryParams += `&last_name=${searchLastName.value}`

      if (bills.value.length && !(bills.value.length < scrollData.requestCount)) {
        return
      }
    }

    if (selectedStatus.value) {
      queryParams += `&status=${selectedStatus.value}`

      if (bills.value.length && !(bills.value.length < scrollData.requestCount)) {
        return
      }
    }

    scrollData.page++

    const queryString = `?page=${scrollData.page}&page_size=${scrollData.size}` + queryParams
    try {
      const response = await axios.get(API.GET_BILLS_LIST + queryString)

      const data = response.data
      if (data.results && data.count) {
        scrollData.requestCount = data.count
        const result = data.results
        bills.value.push(...result)
        result.forEach((item: any) => {
          if (item.status === ETransactionsStatus.processing || (item.status === ETransactionsStatus.error && item.is_possible_recharge)) {
            addToPulling(item.id + '', bills)
          }
        })
      }
    } catch (e) {}
  }
}

const loadOrdersWithParams = () => {
  scrollData.page = 0
  bills.value = []
  updateData()
}

const debounceLoadOrdersWithParams = debounce(() => {
  loadOrdersWithParams()
})

const sucessCallback = () => {
  created.value = false
}

const cancelConfirmPayment = () => {
  showSMSPopup.value = false
  errorMessage.value = ''
}

const confirmPayment = (sms: string) => {
  if (sms) {
    loading.value = true

    axios
      .post(API.PAY_BILL, {
        code: sms,
        bill_ids: checkedBills.value
      })
      .then((res: any) => {
        const ids = [...checkedBills.value]

        const status = res.data?.status
        if (status === 'success') {
          showSMSPopup.value = false
          loading.value = false
          checkedBills.value = []
          created.value = true
          fetchBillsInfo(ids, bills)
          return
        }
        const details = res.data?.error_details
        let errors = ''
        if (details && details.length) {
          errors = details.join(', ')
        }
        errorMessage.value = errors
        showSMSPopup.value = false
        loading.value = false
        checkedBills.value = []
        showErrorPopup.value = true

        fetchBillsInfo(ids, bills)
        checkedBills.value = []
      })
      .catch((e: any) => {
        const ids = [...checkedBills.value]

        const details = e?.response?.data?.error_details
        let errors = ''
        if (details && details.length) {
          errors = details.join(', ')
        }
        errorMessage.value = errors
        if (errors !== 'Неправильный код') {
          showSMSPopup.value = false
          showErrorPopup.value = true
          checkedBills.value = []
        }
        loading.value = false

        fetchBillsInfo(ids, bills)
      })
  }
}

const getSMStoMultiPay = () => {
  startTimer()

  if (!checkedBills.value?.length) {
    return
  }

  axios
    .post(API.SENT_BILL_SMS, {
      bill_ids: checkedBills.value
    })
    .then(() => {
      showSMSPopup.value = true
    })
    .catch((e: any) => {
      const errors = e.response.data
      if (errors && errors.length) {
        const errorText = errors.join ? errors.join(', ') : errors
        errorMessage.value = errorText
        showErrorPopup.value = true
      }
      const ids = [...checkedBills.value]
      fetchBillsInfo(ids, bills)
    })
}

const getSMStoPay = (bill?: number | string, isPossibleRecharge?: boolean | undefined) => {
  startTimer()

  if (bill) {
    checkedBills.value = [bill as string]
  }

  if (isPossibleRecharge) {
    axios
      .post(API.PAY_BILL_REPLY(bill as string), {})
      .then((res: any) => {
        const result = res.data && res.data[0]
        const { bill_id } = result
        checkedBills.value = [bill_id]
        showSMSPopup.value = true
        fetchBillsInfo([bill_id, bill], bills)
      })
      .catch((e: any) => {
        const errors = e.response.data
        if (errors && errors.length) {
          const errorText = errors.join ? errors.join(', ') : errors
          errorMessage.value = errorText
          showErrorPopup.value = true
        }
      })
  } else {
    axios
      .post(API.SENT_BILL_SMS, {
        bill_ids: checkedBills.value
      })
      .then(() => {
        showSMSPopup.value = true
      })
      .catch((e: any) => {
        const errors = e.response.data
        if (errors && errors.length) {
          const errorText = errors.join ? errors.join(', ') : errors
          errorMessage.value = errorText
          showErrorPopup.value = true
        }
        const ids = [...checkedBills.value]
        fetchBillsInfo(ids, bills)
      })
  }
}

const getSMStoCancel = (id: string) => {
  axios
    .patch(API.CANCEL_BILL(id), {
      status: ETransactionsStatus.not_signed
    })
    .then(() => {
      fetchBillsInfo([id], bills)
      sucessCallback()
    })
    .catch((e: any) => {
      const error = e?.response?.data
      errorMessage.value = error
      showErrorPopup.value = true
      fetchBillsInfo([id], bills)
      sucessCallback()
    })
}

const formatDate = (date: any) => {
  return moment(new Date(date)).format('DD.MM.YY HH:mm')
}

const getWorkerStatus = (worker: any) => {
  if (worker && worker['is_self-employed']) {
    return 'СЗ'
  }
  return 'Нет статуса самозанятого'
}

const formatStatus = (status: ETransactionsStatus) => {
  return TRANSACTION_STATUS[status] || 'Ошибка'
}

const queryString = `?page=${scrollData.page}&page_size=${scrollData.size}`
axios
  .get(API.GET_BILLS_LIST + queryString)
  .then((response: any) => {
    scrollData.count = response.data.count
    const result = response.data.results
    bills.value.push(...result)
    loading.value = false
  })
  .catch(() => {
    loading.value = false
  })

watch([searchFirstName, searchLastName, selectedStatus], debounceLoadOrdersWithParams)

onUnmounted(() => {
  clear()
})
</script>

<template>
  <CheckSmsCode
    :loading="loading"
    :show="showSMSPopup"
    :timer="timer"
    :phone="phone"
    :error="errorMessage"
    @cancel="cancelConfirmPayment"
    @confirm="confirmPayment"
    @repeat="getSMStoPay"
  />

  <Loader v-if="loading" />

  <div class="flex flex-wrap lg:flex-nowrap gap-3 mb-2">
    <UInput
      id="searchLastName"
      class="basis-full lg:!basis-1/5"
      placeholder="Введите фамилию исполнителя"
      label="Поиск по фамилии"
      v-model="searchLastName"
      :value="searchLastName"
    />
    <UInput
      id="searchFirstName"
      class="basis-full lg:!basis-1/5"
      placeholder="Введите имя исполнителя"
      label="Поиск по имени"
      v-model="searchFirstName"
      :value="searchFirstName"
    />

    <UISelect
      id="financeStatusSelector"
      class="basis-full lg:!basis-1/5 flex-grow"
      label="Статус операции"
      unselectedLabel="Любой"
      v-model="selectedStatus"
      :value="selectedStatus"
      :options="statusOptions"
    />

    <div>
      <slot name="view-switcher" />
    </div>
  </div>

  <EmptyPagePlaceholder pageType="noData" title="" v-if="!bills.length && !loading" />
  <UTable class="min-h-[300px] lg:min-h-[auto]" v-else colspan="6" :callback="updateData">
    <template #thead>
      <tr>
        <th class="w-[40px] p-0 pl-3" />
        <th class="w-[150px]">№ Платежа</th>
        <th class="w-[180px]">Сумма операции и комиссия</th>
        <th class="w-[180px]">Исполнитель</th>
        <th class="w-[180px]">Заказ и тип операции</th>
        <th class="w-[250px]">Статус операции</th>
        <th class="w-[70px]">Чек</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="bill in bills" :key="bill.id" :class="{ 'bill-cancelled': bill.status === 'cancelled' }">
        <td>
          <UICheckbox v-model="checkedBills" :id="bill.id" :value="bill.id" v-if="bill.status === ETransactionsStatus.awaiting" />
        </td>
        <td>
          №{{ bill.id }}
          <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatDate(bill.created) }}</p>
        </td>
        <td class="relative">
          <UTooltip tooltip="С платежа будет удержан налог" v-if="bill.status === ETransactionsStatus.awaiting && bill.auto_withhold_tax" />
          <div class="flex">
            <UTooltip v-if="bill.withholding_tax" percent class="!static mr-3 relative self-center" :tooltip="d['payment-fee']" />
            <div>
              <span class="whitespace-nowrap">{{ formatPrice(bill.amount) }}&nbsp;₽</span>
              <p class="font-normal text-xs1 left-3 text-grey mt-1 whitespace-nowrap">{{ formatPrice(bill.commission) }}&nbsp;₽</p>
            </div>
          </div>
        </td>
        <td>
          {{ formatName(bill.worker) }}
          <p
            class="font-normal text-xs1 left-3 text-grey mt-1"
            :style="{
              color: bill.worker && bill.worker['is_self-employed'] === true ? '#2CB63A' : '#DB2929'
            }"
          >
            {{ getWorkerStatus(bill.worker) }}
          </p>
        </td>
        <td>
          <p>Заказ №{{ bill.suborder?.id }} к договору №{{ bill.suborder?.agreement }}</p>
          <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ bill.suborder?.name }}</p>
        </td>
        <td
          :style="{
            color: bill.status === 'paid' ? '#2CB63A' : ['cancelled', 'act_not_signed'].includes(bill.status) ? '#DB2929' : 'black'
          }"
        >
          <div class="flex items-center">
            <div class="mr-3" v-if="bill.status === 'error'">
              <ErrorTooltip :tooltip="getQiwiErrCode(bill.error_code) || 'Ошибка!'" />
            </div>
            <div>
              <template v-if="!(bill.status === 'error' && bill.is_possible_recharge)">
                {{ formatStatus(bill.status) }}
              </template>
              <p
                class="font-normal text-xs1 left-3 text-grey mt-1"
                v-if="['paid', 'sent'].includes(bill.status)"
                :style="{
                  color: bill.status === 'paid' ? '#2CB63A' : ['cancelled', 'act_not_signed'].includes(bill.status) ? '#DB2929' : 'black'
                }"
              >
                {{ formatDate(bill.last_updated) }}
              </p>
              <div class="flex" v-if="bill.status === 'error' && bill.is_possible_recharge">
                <UButton label="Оплатить повторно" size="sm" class="!w-auto px-5" @click="getSMStoPay(bill.id, bill.is_possible_recharge)">
                  <img class="pay-icon" src="/icons/update.svg" alt="repay" />
                </UButton>
              </div>
              <UButton label="Оплатить" size="sm" @click="getSMStoPay(bill.id)" v-if="bill.status === 'awaiting_payment'"></UButton>
              <UButton
                data-revoke-bills-list="true"
                label="Отклонить"
                :disabled="isProcurationEnded"
                size="sm"
                @click="getSMStoCancel(bill.id)"
                class="btn-cancel"
                v-if="bill.status === 'act_not_signed'"
              />
            </div>
          </div>
        </td>
        <td>
          <a
            :title="!bill.check_image ? '' : 'Открыть чек'"
            :href="bill.check_image"
            target="_blank"
            :class="{
              checkLink: true,
              isDisabled: !bill.check_image
            }"
          >
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.8357 5.55533C21.6394 5.32876 16.916 0 11 0C5.08399 0 0.360701 5.32876 0.164291 5.55533C-0.0547636 5.80858 -0.0547636 6.19142 0.164291 6.44467C0.360701 6.67124 5.08408 12 11 12C16.916 12 21.6393 6.67124 21.8357 6.44467C22.0548 6.19142 22.0548 5.80858 21.8357 5.55533ZM11 10.6667C8.51251 10.6667 6.4883 8.57293 6.4883 6C6.4883 3.42707 8.51251 1.33333 11 1.33333C13.4875 1.33333 15.5117 3.42707 15.5117 6C15.5117 8.57293 13.4875 10.6667 11 10.6667Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </td>
      </tr>
    </template>
  </UTable>

  <UModal :show="created" @cancel="sucessCallback" title="Оплата успешно совершена!">
    <template #buttons>
      <div class="flex justify-between">
        <UButton class="!w-full" label="Продолжить" @click="sucessCallback" />
      </div>
    </template>
  </UModal>

  <UModal :show="showErrorPopup" title="Внимание!">
    <template #content>
      <p v-if="errorMessage">{{ errorMessage }}</p>
      <p v-else>Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей</p>
    </template>
    <template #buttons>
      <UButton label="Продолжить" @click=";(errorMessage = ''), (showErrorPopup = false)" />
    </template>
  </UModal>

  <MultiplePayment v-if="checkedBills.length > 1" :bills="checkedBills" @clear="checkedBills = []" @pay="getSMStoMultiPay" />
</template>

<style lang="postcss" scoped>
.bill-cancelled {
  td {
    @apply !text-cancelled;

    p,
    a,
    span {
      @apply !text-cancelled;
    }
  }
}

.checkLink {
  @apply text-grey;

  &:hover {
    @apply text-black cursor-pointer;
  }

  &.isDisabled {
    @apply text-cancelled;

    &:hover {
      @apply text-cancelled cursor-not-allowed;
    }
  }
}

:deep(.modal) {
  @apply !gap-2;
}

.close-icon {
  @apply left-full bottom-full;
  transform: translate(0, 50%);
  &:hover {
    @apply cursor-pointer;
  }
}

.pay-icon {
  @apply h-3 mr-[6px];
}

:deep(table) {
  @apply table-fixed;
  & th {
    @apply whitespace-nowrap;
  }
}
</style>
