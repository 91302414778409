<template>
  <main-page-header :title="title">
    <span class="text-grey not-italic font-normal text-base flex-grow text-left ml-[1em]">Шаг {{ step }} из 5</span>
  </main-page-header>

  <ConfirmSignModal task :show="showConfirmSignModal" @closeModal="showConfirmSignModal = false" @successModal="confirmSign" />

  <WarningMessage
    message="Превышен свободный остаток бюджета объекта"
    class="mt-3 self-start inline-block"
    v-if="step === 2 && specialErrors.overdraft"
    messageType="info"
  />

  <p v-if="isMaxSuborders && step === 4" class="text-error mt-2">Максимальное возможное количество заказов в реестре заказов 100 шт.</p>

  <div v-if="ready" class="mt-[50px] overflow-hidden flex flex-col flex-grow" :class="{ '!overflow-visible': step === 2 }">
    <template v-if="step === 1">
      <AddOrder1 v-model:order="order" :v$="v$" />
    </template>
    <template v-else-if="step === 2">
      <AddOrderExtended
        v-model:order="order"
        v-model:budget="budget"
        v-model:companyObject="companyObject"
        :specialErrors="specialErrors"
      />
    </template>
    <template v-else-if="step === 3">
      <AddOrderWorkers v-model:order="order" :v$="v$" :budget="budget" :specialErrors="specialErrors" />
    </template>
    <template v-else-if="step === 4">
      <AddOrder2 v-model:order="order" :v$="v$" :budget="budget" :specialErrors="specialErrors" />
    </template>
    <template v-else>
      <AddOrder3 v-model:order="order" :budget="budget" />
    </template>
    <div class="pt-3.5 flex gap-3">
      <UButton class="mr-auto w-40" color="secondary" label="Отмена" @click="$router.push('/orders')" />
      <UButton v-if="step > 1" label="Назад" class="w-40" color="secondary" @click="prevStep" />
      <UButton
        class="w-40"
        :class="{ 'w-44': step === 4 }"
        id="nextStepBtn"
        :label="getNextBtnLabel"
        :disabled="
          disableBtnNext ||
          (step === 2 && specialErrors.isOverDraftCompany) ||
          (step === 3 && disableBtnSuborders) ||
          (step === 4 && specialErrors.limitPrice) ||
          (step === 4 && disableBtnNextOutOffLimit) ||
          (step === 4 && isMaxSuborders) ||
          (step === 4 && !isDraftDataReadyStep4)
        "
        @click="nextStep"
      />
    </div>
  </div>
  <Loader v-if="creating" />

  <UModal
    :show="created"
    @cancel=";(created = false), $router.push('/orders')"
    @success="$router.push('/orders')"
    title="Реестр заказов"
    cancelLabel=""
    successLabel="Перейти к списку Заказов"
  >
    <template #content>
      <p class="break-words" id="contentCreateOrder">Реестр заказов &laquo;{{ order.name || '' }}&raquo; успешно создан!</p>
    </template>
  </UModal>

  <UModal
    :show="showModalNotVerifiedWorker"
    @cancel=";(showModalNotVerifiedWorker = false), (failedcreate = false)"
    @success=";(showModalNotVerifiedWorker = false), (failedcreate = false)"
    title="Ошибка при создании заказа"
  >
    <template #content>
      <p class="text-error">{{ errorResponseMsg }}</p>
    </template>
    <template #buttons>
      <UButton label="Продолжить" @click=";(showModalNotVerifiedWorker = false), (failedcreate = false)" />
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import Loader from '@/components/Loader/Loader.vue'
import * as Sentry from '@sentry/browser'
import { useOrderForm } from '@/views/Orders/order-form'

import AddOrder1 from './AddOrder1.vue'
import AddOrder2 from './AddOrder2.vue'
import AddOrder3 from './AddOrder3.vue'
import AddOrderExtended from './AddOrderExtended.vue'
import AddOrderWorkers from './AddOrderWorkers.vue'
import WarningMessage from '@/components/Messages/WarningMessage.vue'
import ConfirmSignModal from '@/components/Modals/ConfirmSignModal.vue'

import { useOrderStore } from '@/stores/order'

import debounce from '@/utils/debounce'

import axios from 'axios'
import { API } from '@/utils/API'

import { UButton, UModal } from 'unit-uikit'

function formatSuborderDate(value: any) {
  if (value) {
    const date = value.split('.')
    return `${date[2]}-${date[1]}-${date[0]}`
  }
  return null
}

function formatToSuborderDate(value: any) {
  if (value) {
    const date = value.split('-')
    return `${date[2]}.${date[1]}.${date[0]}`
  }
  return null
}

async function getWorkersInfo(suborders: any) {
  const asyncRes = await Promise.all(
    suborders.map(async (item: any) => {
      const _id = item.worker?.worker_id
      if (_id) {
        const worker = await axios.get(API.PROFILE_WORKER(_id))
        const data = worker && worker.data
        return {
          id: item.worker.worker_id,
          personaldata: data.personaldata,
          last_name: data.last_name,
          first_name: data.first_name,
          middle_name: data.middle_name || '',
          name: item.initial_name || item.name,
          unit_types: item.initial_unit_types?.id || item.unit_types?.id,
          start_date: formatToSuborderDate(item.initial_start_date || item.start_date),
          finish_date: formatToSuborderDate(item.initial_finish_date || item.finish_date)
        }
      }
    })
  )
  return asyncRes
}

function formatOrderData(order: any, budget: any) {
  const data: any = {}
  const entries = Object.entries(order)

  entries.forEach((e: any) => {
    const key = e[0]
    let value = e[1]
    if (key === 'start_date' || key === 'finish_date') {
      const date = new Date(value)
      value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }
    if (key === 'suborder') {
      data.suborder = []
      value?.forEach((suborder: any) => {
        const price = (suborder.price + '').replaceAll(',', '.').replaceAll(' ', '')
        const res = {
          initial_name: suborder.name,
          description: suborder.description || order.description || order.name,
          initial_price: price,
          budget_total: price,
          initial_quantity: suborder.quantity,
          unit_types: suborder.unit_types,
          initial_unit_types: suborder.unit_types,
          worker: suborder.id,
          initial_start_date: formatSuborderDate(suborder.start_date),
          initial_finish_date: formatSuborderDate(suborder.finish_date)
        }
        data.suborder.push(res)
      })
      data.budget = (budget.workerWithTax * budget.workersAmount).toFixed(2)
      data.budget_total = budget.budgetPlusTaxes
      return
    }
    if (key === 'job_certs_requirements' || key === 'equipment') {
      data[key] = value
        .map((job: any) => {
          if (job.id) {
            return +job.id
          } else {
            return +job.value
          }
        })
        .filter((value: any) => value)
      return
    }
    data[key] = value
  })
  data.is_registry = false
  data.order_type = 'private'
  data.control_over_workers = ''
  data.category = ''
  data.technical_documentation = []
  if (data.photo_after_completion === false) {
    data.photo_comments = ''
  }
  return data
}

export default defineComponent({
  data() {
    return {
      orderStore: useOrderStore(),
      v$: {},
      title: 'Создание нового реестра заказов',
      step: 1,
      showConfirmSignModal: false,
      isSignConfirmed: false,
      isDuplicateOrder: false as any,
      isDraftDataReadyStep4: false,
      draftID: '' as any,
      draftSuborder: '',
      debounceSaveDraft: null as any,
      debounceDelayTime: 2000,
      budget: {
        workersAmount: 1,
        budgetPlusTaxes: 0,
        oneWorkerSum: 0,
        allWorkersSum: 0,
        fullTaxes: 0,
        workerWithTax: 0
      } as any,
      specialErrors: {
        limitPrice: false,
        limitQuantity: false,
        suborders: false
      } as any,
      order: {} as any,
      companyObject: {} as any,
      creating: false,
      created: false,
      failedcreate: false,
      disableBtnNext: false,
      disableBtnSuborders: false,
      ready: false,
      isMaxSuborders: false,
      showModalNotVerifiedWorker: false,
      errorResponseMsg: ''
    }
  },
  methods: {
    nextStep() {
      if (this.disableBtnNext) {
        return
      }

      this.disableBtnNext = true

      if (this.step === 1) {
        const checkFields = [
          'name',
          'description',
          'project',
          'object',
          'address',
          'quantity',
          'unit_type',
          'agreement_template',
          'order_template',
          'acceptance_certificate_template',
          'start_date',
          'finish_date',
          'special_requirements',
          'job_certs_requirements',
          'equipment',
          'omvd'
        ] as any

        let isInvalid = false

        checkFields.forEach((field: any) => {
          // @ts-ignore
          this.v$[field].$touch()
          // @ts-ignore
          isInvalid = isInvalid || this.v$[field].$invalid
        })

        const data: any = formatOrderData(this.order, this.budget)

        if (!isInvalid) {
          data.suborder = []
          data.confirmed_data = false

          axios
            .post(API.CHECK_GENERAL_ORDER, data)
            .then((response: any) => {
              this.disableBtnNext = false
              if (response.status === 200) {
                this.step = Math.min(5, this.step + 1)
              }
            })
            .catch((error: any) => {
              console.error('error 1 step', error)
              this.disableBtnNext = false
            })
        } else {
          this.disableBtnNext = false
        }

        return
      }

      if (this.step === 2) {
        const preData = {
          workers_amount: this.budget.workersAmount,
          budget_per_worker: this.budget.workerWithTax,
          budget_netto: this.budget.allWorkersSum,
          budget_brutto: this.budget.budgetPlusTaxes,
          object_id: this.order.object
        }

        if (this.companyObject.info) {
          let budgetPerWorker = (preData.budget_netto / preData.workers_amount).toFixed(2)
          budgetPerWorker =
            +budgetPerWorker +
            (+budgetPerWorker / (1 - this.companyObject.info['self-employed_tax'])) * this.companyObject.info['self-employed_tax'] +
            ''
          budgetPerWorker = (+budgetPerWorker).toFixed(2)
          const budgetBrutto = (
            preData.budget_netto /
            (1 - this.companyObject.info['self-employed_tax']) /
            (1 - this.companyObject.info.unitpay_commission)
          ).toFixed(2)
          if (Math.abs(+budgetPerWorker - preData.budget_per_worker) <= 0.011) {
            preData.budget_per_worker = budgetPerWorker
            // this.budget.workerWithTax = budgetPerWorker
          }
          if (Math.abs(+budgetBrutto - preData.budget_brutto) <= 0.011) {
            preData.budget_brutto = budgetBrutto
            // this.budget.budgetPlusTaxes = budgetBrutto
          }
        }

        const allDataisFull = !!(preData.budget_per_worker && preData.budget_netto && preData.budget_brutto)

        if (!allDataisFull) {
          this.disableBtnNext = false
          return
        }

        axios
          .post(API.CHECK_BUDGET, preData)
          .then(() => {
            this.disableBtnNext = false
            if (this.order.suborder?.length) {
              this.order.suborder = this.order.suborder.map((item: any) => {
                item.initial_price = null
                // из-за этого не сохранялись данные на 4 шаге (для черновиков)
                // item.price = null
                return item
              })
            }
            this.step = Math.min(5, this.step + 1)
          })
          .catch((error) => {
            this.disableBtnNext = false
            const errors = error?.response?.data?.non_field_errors
            if (errors && errors.length === 1 && errors[0] === 'not_enough_OBJECT_balance') {
              if (this.order.suborder?.length) {
                this.order.suborder = this.order.suborder.map((item: any) => {
                  item.initial_price = null
                  // из-за этого не сохранялись данные на 4 шаге (для черновиков)
                  // item.price = null
                  return item
                })
              }
              this.step = Math.min(4, this.step + 1)
            } else {
              Sentry.captureException(error)
              console.error('error balance calculation', error)
            }
          })

        return
      }

      if (this.step === 3) {
        if (this.order.suborder?.length + '' === this.budget.workersAmount + '') {
          this.disableBtnNext = false
          this.step = Math.min(5, this.step + 1)
          this.draftSuborder = ''
        } else {
          this.disableBtnNext = false
        }
        return
      }

      if (this.step === 4) {
        if (this.specialErrors.limitQuantity) {
          this.disableBtnNext = false
          return
        }
        if (this.specialErrors.limitPrice) {
          this.disableBtnNext = false
          return
        }
        if (!this.order?.suborder?.length) {
          // @ts-ignore
          this.v$.suborder.$touch()
          // @ts-ignore
          if (this.v$.suborder.$invalid) {
            this.disableBtnNext = false
            return
          }
        }
        this.order.unit_types = this.order.unit_type

        const data: any = formatOrderData(this.order, this.budget)
        data.confirmed_data = false

        if (this.order.suborder.length + '' === this.budget.workersAmount + '') {
          axios
            .post(API.ADD_ORDER, data)
            .then((response: any) => {
              this.showModalNotVerifiedWorker = false
              this.disableBtnNext = false
              if (response.status === 200) {
                this.step = Math.min(5, this.step + 1)
              }
            })
            .catch((error: any) => {
              if (error.response?.data?.suborder) {
                this.showModalNotVerifiedWorker = true
                this.errorResponseMsg =
                  error.response?.data?.suborder[0] + '. ' + 'Невозможно создать заказ с неверифицированным исполнителем'
              } else if (error.response?.data[0]) {
                this.showModalNotVerifiedWorker = true
                this.errorResponseMsg = error.response?.data[0]
              } else {
                this.failedcreate = true
              }
              this.disableBtnNext = false
              console.error('error 3 step', error)
            })
        } else {
          this.disableBtnNext = false
        }

        return
      }

      if (this.step === 5) {
        this.disableBtnNext = false
        this.showConfirmSignModal = true

        return
      }

      this.disableBtnNext = false
      this.step = Math.min(5, this.step + 1)
    },
    prevStep() {
      if (this.step === 1) {
        this.$router.push('/orders')
      }

      const step = Math.max(1, this.step - 1)
      this.step = step
    },
    confirmSign() {
      this.isSignConfirmed = true

      if (this.isSignConfirmed) {
        this.creating = true
        const data: any = formatOrderData(this.order, this.budget)
        data.confirmed_data = true

        axios
          .post(API.ADD_ORDER, data)
          .then((response: any) => {
            this.showModalNotVerifiedWorker = false
            this.disableBtnNext = false
            this.creating = false
            if (response.status === 200 || response.status === 201) {
              this.created = true
            }
            axios.delete(API.CRUD_DRAFT(this.draftID ? this.draftID : this.$route.query.draftId)).catch((e) => {
              console.error(e)
            })
            this.showConfirmSignModal = false
          })
          .catch((error: any) => {
            if (error.response?.data?.suborder && error.response?.data?.suborder[0]?.includes('not verified')) {
              this.showModalNotVerifiedWorker = true
            }
            this.disableBtnNext = false
            this.creating = false
            this.failedcreate = true
            this.showConfirmSignModal = false
            console.error('error 4st step', error)
          })
      }
    }
  },
  components: {
    MainPageHeader,
    AddOrder1,
    AddOrder2,
    AddOrder3,
    AddOrderExtended,
    AddOrderWorkers,
    Loader,
    ConfirmSignModal,
    WarningMessage,
    UButton,
    UModal
  },
  computed: {
    disableBtnNextOutOffLimit() {
      if (this.order && this.order.suborder?.length) {
        const _worker = this.order.suborder.find((w: any) => {
          const price = parseFloat((w.price + '').replaceAll(' ', '').replace(',', '.'))
          return this.order.withholding_tax ? w.month_limit - price < 0 : w.month_limit_without_tax - price < 0
        })
        return !!_worker
      }
      return false
    },
    getNextBtnLabel() {
      switch (this.step) {
        case 1:
        case 2:
        case 3:
          return 'Далее'
        case 4:
          return 'Пригласить на заказ'
        case 5:
          return 'Подтверждаю'
        default:
          return 'Далее'
      }
    }
  },
  created() {
    const { order, v$ } = useOrderForm(this.budget.workersAmount)
    this.order = order
    this.v$ = v$
    const duplicateId = this.orderStore.duplicateId
    if (!duplicateId) {
      this.order.project = this.orderStore.targetProject
      this.order.object = this.orderStore.targetObject

      if (!this.$route?.query?.draftId) {
        axios
          .post(API.CREATE_DRAFT, {
            body: {}
          })
          .then((res) => {
            this.draftID = res.data.id
            this.ready = true
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        axios
          // @ts-ignore
          .get(API.CRUD_DRAFT(this.$route.query.draftId))
          .then((res) => {
            this.order = res.data.body

            if (res.data.body.finish_date) {
              this.order.finish_date = new Date(res.data.body.finish_date)
            }
            if (res.data.body.start_date) {
              this.order.start_date = new Date(res.data.body.start_date)
            }

            this.budget = res.data.body?.draft_budget
            this.draftSuborder = this.order.suborder
            this.ready = true
          })
          .catch((error) => {
            console.error(error)
          })
      }
    } else {
      this.isDuplicateOrder = this.orderStore.duplicateId
      this.isDraftDataReadyStep4 = true
      this.orderStore.duplicateId = null
      axios.get(API.GET_SUBORDER_BY_ORDER(duplicateId)).then(async (res: any) => {
        const suborders = res.data
        if (suborders && suborders.length) {
          this.budget.workersAmount = suborders?.length || 1
          const asyncRes = await getWorkersInfo(suborders)
          this.order.suborder = asyncRes
        }
      })

      this.orderStore
        .fetchOrder(duplicateId)
        .then((response: any) => {
          const order = response
          // step 1

          if (order.acceptance_certificate_template?.id) {
            this.order.acceptance_certificate_template = order.acceptance_certificate_template?.id
          }
          if (order.agreement_template?.id) {
            this.order.agreement_template = order.agreement_template?.id
          }
          if (order.order_template?.id) {
            this.order.order_template = order.order_template?.id
          }

          if (order.description) {
            this.order.description = order.description
          }
          if (order.equipment && order.equipment.length) {
            this.order.equipment = order.equipment
          }
          if (order.job_certs_requirements && order.job_certs_requirements.length) {
            this.order.job_certs_requirements = order.job_certs_requirements
          }
          if (order.finish_date) {
            this.order.finish_date = new Date(order.finish_date)
          }
          if (order.start_date) {
            this.order.start_date = new Date(order.start_date)
          }
          if (order.name) {
            this.order.name = order.name
          }
          if (order.object?.project?.id) {
            this.order.project = order.object?.project?.id
          }
          if (order.object?.id) {
            this.order.object = order.object?.id
          }
          if (order.address) {
            this.order.address = order.address
          }
          if (order.photo_after_completion) {
            this.order.photo_after_completion = order.photo_after_completion
          }
          if (order.photo_comments) {
            this.order.photo_comments = order.photo_comments
          }
          if (order.quantity) {
            this.order.quantity = order.quantity
          }
          if (order.selectedCertificates) {
            this.order.selectedCertificates = order.selectedCertificates
          }
          if (order.special_requirements) {
            this.order.special_requirements = order.special_requirements
          }
          if (order.unit_types?.id) {
            this.order.unit_type = order.unit_types?.id
          }
          if (order.omvd) {
            this.order.omvd = order.omvd
          }
          if (order.withholding_tax) {
            this.order.withholding_tax = order.withholding_tax
          }
          // end step 1
          // data.budget = (budget.workerWithTax * budget.workersAmount).toFixed(2)
          this.budget.budgetPlusTaxes = order.budget_total
          this.ready = true
        })
        .catch((e: any) => {
          console.error(e)
          this.ready = true
        })
    }
  },
  beforeUpdate() {
    if (this.step === 4) {
      this.order.suborder.length > 100 ? (this.isMaxSuborders = true) : (this.isMaxSuborders = false)
    }
  },
  watch: {
    'specialErrors.suborders'() {
      this.disableBtnSuborders = this.specialErrors.suborders
    },
    order: {
      handler(orderData) {
        if (this.ready && !this.isDuplicateOrder) {
          if (this.debounceSaveDraft) {
            this.debounceSaveDraft()
          } else {
            this.debounceSaveDraft = debounce(() => {
              this.order.draft_budget = this.budget
              if (this.step === 4 && !!this.draftSuborder === true) {
                this.order.suborder = this.draftSuborder
              }
              axios
                .patch(API.CRUD_DRAFT(this.draftID ? this.draftID : this.$route.query.draftId), {
                  body: this.draftID ? orderData : this.order
                })
                .then((res) => {
                  if (res && this.step === 4) {
                    return (this.isDraftDataReadyStep4 = true)
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            }, this.debounceDelayTime)
            this.debounceSaveDraft()
          }
        }
      },
      deep: true
    }
  }
})
</script>

<style lang="postcss" scoped>
@import './_orders.postcss';
</style>
