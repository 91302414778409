<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { API } from '@/utils/API'
import { USidebar, UMultiselect } from 'unit-uikit'
import { EAccountTypes } from '@/types/api-values'
import { useAuthStore } from '@/stores/auth'
import { useCompanyStore } from '@/stores/company'
import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
const { logout } = authStore
const companyStore = useCompanyStore()
companyStore.fetcCopmanies(true)

const route = useRoute()
const router = useRouter()
const badge = ref(false)
const documentsBadge = ref(false)

watch(
  () => [badge.value, route?.name],
  () => {
    const name = route?.name as string
    const showRoute = name.includes('documents.') || name.includes('omvd.')

    axios
      .get(
        API.GET_DOCUMENTS_NOTIFICATIONS +
          '?count=1&page=1&app_7=filed&app_8=exists,signed,not_signed,active,finished,terminated,converting_error,null&order=exp_date'
      )
      .then((res: any) => {
        badge.value = !!res.data.expiring
      })
      .catch((e: any) => {
        console.error(e)
      })

    documentsBadge.value = !!badge.value && !showRoute
  }
)

const navigations = ref([
  {
    icon: 'icon-projects',
    title: 'Проекты',
    route: 'projects.list'
  },
  {
    icon: 'icon-orders',
    title: 'Заказы',
    route: 'orders.list'
  },
  {
    icon: 'icon-performerce',
    title: 'Исполнители',
    route: 'workers.list'
  },
  {
    icon: 'icon-docs',
    title: 'Документы',
    route: 'documents.all',
    showBadge: documentsBadge,
    subroute: [
      {
        title: 'Уведомления МВД',
        route: 'omvd.all',
        showBadge: badge
      },
      {
        title: 'Патенты',
        route: 'patents.current'
      }
    ]
  },
  {
    icon: 'icon-company',
    title: 'Компания',
    route: 'company.info',
    subroute: [
      {
        title: 'Сотрудники',
        route: 'personal.list'
      }
    ]
  },
  {
    icon: 'icon-finance',
    title: 'Финансы',
    route: 'finance.orders'
  },
  {
    icon: 'icon-book',
    title: 'Инструкция',
    route: 'guide'
  }
])

const { companies, activeCompany } = storeToRefs(companyStore)
const selectedCompany = ref(activeCompany.value || null) as any
const switchMenu = ref(false)
const processing = ref(false)

const openChat = () => {
  if (processing.value) {
    return
  }

  processing.value = true

  axios
    .get(API.GET_USER_INFO)
    .then((res: any) => {
      const data = res.data
      if (data) {
        const { username, first_name: firstName, last_name: lastName, middle_name: middleName, company } = data
        const fio = `${lastName || ''} ${firstName || ''} ${middleName || ''}`

        if ('_teletypeWidget' in window) {
          ;(window._teletypeWidget as any).setPersonData({
            name: fio,
            phone: username,
            payload: {
              inn: company?.inn,
              name: company?.name
            }
          })

          if ('_teletypeWidget' in window) {
            ;(window._teletypeWidget as any)?.show()
          }
        }
      }
      processing.value = false
    })
    .catch(() => {
      processing.value = false
      if ('_teletypeWidget' in window) {
        ;(window._teletypeWidget as any)?.show()
      }
    })
}

const showSubroute = (item: any) => {
  const name = route?.name as string
  switch (item.route) {
    case 'documents.all':
      return name.includes('documents.') || name.includes('omvd.') || name.includes('patents.')
    case 'company.info':
      return name.includes('company') || name.includes('personal')
    default:
      return false
  }
}

const toggleSwitchMenu = () => {
  switchMenu.value = !switchMenu.value
}

watch(activeCompany, () => {
  selectedCompany.value = activeCompany.value
})

watch(selectedCompany, () => {
  const _id = selectedCompany.value?.id
  const prevId = activeCompany.value?.id

  if (_id && _id + '' !== prevId + '') {
    axios
      .post(API.AUTH_CHOOSECOMPANY, {
        company_id: _id
      })
      .then(async (response: any) => {
        const status = response?.status
        if (status === 201 || status === 200) {
          const newAccessToken = response?.data?.updated_access_token
          const jwtString = localStorage.getItem('JWT')
          const jwt = jwtString && JSON.parse(jwtString)
          const newJWT = {
            access_token: newAccessToken,
            refresh_token: jwt?.refresh_token
          }

          if (newJWT && newJWT.access_token) {
            authStore.saveJWT(newJWT)
          }

          const payload = authStore.payload
          const type = payload?.user_type
          const verified = payload?.user_verified
          const companyVerified = payload?.company_is_verified
          // const companyId = payload.company_id
          // const edoEnabled = payload.company_staff_status_to_sign

          if (type === EAccountTypes.staff) {
            if (verified === false) {
              router.push({
                name: 'staff.notready'
              })
              return
            }
            // redirect to edo app
            window.location.pathname = '/edo'
            return
          }

          if (type === EAccountTypes.customer && companyVerified !== true) {
            router.push({
              name: 'patents.current'
            })
            return
          }

          // type of account in not allowed to use app
          if (type !== EAccountTypes.customer && type !== EAccountTypes.staff) {
            logout()
            return
          }

          // Select successful
          if (route.name === 'orders.list') {
            // force page reload
            window.location.reload()
          } else {
            router.push('/')
          }
        }
      })
      .catch()
  }
})

// @todo
//
// watch
//
// '$route.name'(val) {
//   if (val === 'addOrder') {
//     this.toggled = true
//   }
// },
// '$route.fullPath'(val) {
//   if (val === '/orders/register/edit') {
//     this.toggled = true
//   }
// }

const multiselect = ref(null)

const selectCompany = () => {
  if (multiselect.value && companies.value?.length) {
    // @ts-expect-error no method
    multiselect.value.activate()
  }
}
</script>

<template name="navbar">
  <USidebar :navigations="navigations" :show-subroute="showSubroute" @logout="logout">
    <template #menu-logo>
      <div class="menu-switcher" @click="toggleSwitchMenu">
        <img src="/icons/logo.svg" alt="Logo UNIT" class="app-logo" />
        <i class="icon-down ml-3 text-[6px]"></i>
        <ul class="menu-switcher-options" v-if="switchMenu">
          <li class="mb-2">
            <img src="/icons/logo-new.svg" alt="Logo UNIT" class="h-6" />
          </li>
          <li>
            <a href="/edo">
              <img src="/icons/logo-edo.svg" alt="Logo EDO" class="h-6" />
            </a>
          </li>
        </ul>
      </div>
    </template>

    <template #menu-content>
      <div class="flex flex-col w-full">
        <button class="support-btn" type="button" @click="openChat">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.4131 18.5449C15.125 18.5449 15.5469 18.0439 15.5469 17.2617V15.1084H15.8984C18.4385 15.1084 19.9678 13.5879 19.9678 10.9688V4.13965C19.9678 1.5293 18.4824 0 15.8369 0H4.63086C1.99414 0 0.5 1.5293 0.5 4.13965V10.9688C0.5 13.5791 1.99414 15.1084 4.63086 15.1084H9.92188L12.9365 17.7627C13.543 18.2988 13.9121 18.5449 14.4131 18.5449ZM13.9824 16.5762L11.1963 13.8252C10.8184 13.4385 10.5635 13.3506 10.0273 13.3506H4.69238C3.04883 13.3506 2.24902 12.5156 2.24902 10.916V4.19238C2.24902 2.59277 3.04883 1.75781 4.69238 1.75781H15.7842C17.4365 1.75781 18.2188 2.59277 18.2188 4.19238V10.916C18.2188 12.5156 17.4365 13.3506 15.7842 13.3506H14.7031C14.2021 13.3506 13.9824 13.5527 13.9824 14.0713V16.5762Z"
              fill="#8491A5"
            />
          </svg>
          <span>Поддержка</span>
        </button>
        <div class="mt-auto company-zone">
          <UMultiselect
            ref="multiselect"
            v-model="selectedCompany"
            :options="companies"
            :multiple="false"
            :searchable="false"
            :close-on-select="true"
            :allow-empty="false"
            label="name"
            track-by="id"
            :showLabels="false"
            class="small-text mt-auto mb-3"
          ></UMultiselect>
          <p @click="selectCompany" class="company-title mb-3">{{ selectedCompany?.name }}</p>
        </div>
      </div>
    </template>
  </USidebar>
</template>

<style lang="postcss" scoped>
.menu-switcher {
  @apply flex items-center flex-grow relative mr-5 pl-6 hover:cursor-pointer h-[30px];

  &:hover i {
    @apply opacity-70;
  }
}

.menu-switcher-options {
  @apply absolute bg-white left-6 rounded right-0 z-[1] p-2.5 shadow-main;
  top: calc(100% + 10px);

  & li {
    @apply hover:opacity-70;
  }
}

.app-logo {
  @apply h-[30px] align-top;
}

.main-navigation.toggled .menu-switcher {
  @apply h-10 p-0 m-0 overflow-hidden;

  .app-logo {
    @apply h-[38px] max-w-none;
  }
}

.company-zone {
  @apply bg-[#f7fafd] -mx-5 w-full box-content flex flex-col px-5;
  border-bottom: 1px solid #eaeced;
}
:deep(.multiselect) {
  @apply h-0 min-h-0;
}
:deep(.multiselect__select) {
  @apply hidden;
}
:deep(.multiselect__tags) {
  @apply !hidden;
}

.support-btn {
  @apply h-10 font-medium text-sm flex items-center whitespace-nowrap px-6;

  i {
    @apply text-lg w-5;
  }

  span {
    @apply ml-3;
  }
}

.company-title {
  @apply text-sm pl-6;
}
</style>
