import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext, RouteRecordNormalized } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useStaffStore } from '@/stores/staff'
import { useOrderStore } from '@/stores/order'
import { useUiStore } from '@/stores/ui'

import Landing from '@/views/Landing/Landing.vue'

import Registration from '@/views/Registration/Registration.vue'
import StaffRegistration from '@/views/Registration/Staffer/StaffRegistration.vue'
import staffController from './staff'
import Login from '@/views/Registration/Login.vue'
import SelectCompany from '@/views/Registration/SelectCompany.vue'

import MainPage from '@/views/Main/Main.vue'

import Projects from '@/views/Projects/Projects.vue'
import ProjectList from '@/views/Projects/Project/ProjectList.vue'
import AddEditProject from '@/components/Projects/AddEditProject.vue'

import Objects from '@/views/Projects/Objects/index.vue'
import ProjectObjectsList from '@/views/Projects/Objects/ProjectObjectsList.vue'
import AddObject from '@/views/Projects/Objects/AddObject.vue'
import EditObject from '@/views/Projects/Objects/EditObject.vue'

import Order from '@/views/Orders/Order.vue'
import OrderInfo from '@/views/Orders/OrderInfo.vue'
import OrderDocs from '@/views/Orders/OrderDocs.vue'
import OrderEditRegister from '@/views/Orders/OrderEditRegister.vue'

import Orders from '@/views/Orders/Orders.vue'
import AddOrder from '@/views/Orders/AddOrder.vue'

import workersController from './workers'

import Docs from '@/views/Docs/index.vue'
import Documents from '@/views/Docs/Documents.vue'
import DocHistory from '@/views/Docs/DocHistory.vue'

import Omvd from '@/views/Omvd/index.vue'
import OmvdDocs from '@/views/Omvd/Omvd.vue'
import OmvdHistory from '@/views/Omvd/OmvdHistory.vue'
import OmvdNotifications from '@/views/Omvd/OmvdNotifications.vue'
import OmvdTrustedPersons from '@/views/Omvd/OmvdTrustedPersons.vue'
import OmvdTrustedPersonsAdd from '@/views/Omvd/OmvdTrustedPersonsAdd.vue'

import Personal from '@/views/Company/Personal/PersonalList.vue'
import PersonalAdd from '@/views/Company/Personal/Add.vue'

import Finance from '@/views/Finance/Finance.vue'
import FinanceOrders from '@/views/Finance/FinanceOrders.vue'
import FinanceHistory from '@/views/Finance/FinanceHistory.vue'
import FinanceOrderInfo from '@/views/Finance/FinanceOrderInfo.vue'
import FinanceArchive from '@/views/Finance/FinanceArchive.vue'
import FinanceAccounts from '@/views/Finance/FinanceAccounts.vue'
import FinanceDDS from '@/views/Finance/FinanceDDS.vue'

import Page404 from '@/views/404.vue'

import { EAccountTypes } from '@/types/api-values'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/landing',
    name: 'landing',
    component: Landing
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/registration',
    name: 'Registration Page',
    component: Registration
  },
  {
    path: '/reg/staff',
    name: 'Staffer Registration',
    component: StaffRegistration,
    children: staffController
  },
  {
    path: '/select',
    name: 'SelectCompany',
    component: SelectCompany
  },
  {
    path: '/',
    name: 'main',
    component: MainPage,
    redirect: {
      name: 'orders.list'
    },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'projects',
        name: 'projects',
        component: Projects,
        children: [
          {
            path: '',
            name: 'projects.list',
            component: ProjectList,
            beforeEnter() {
              const uiStore = useUiStore()
              uiStore.filters.project = null
              const orderStore = useOrderStore()
              orderStore.targetProject = null
              orderStore.targetObject = null
            }
          },
          {
            path: 'add',
            name: 'projects.add',
            component: AddEditProject
          },
          {
            path: 'edit/:id',
            name: 'projects.edit',
            component: AddEditProject
          },
          {
            path: 'objects',
            name: 'objects',
            component: Objects,
            children: [
              {
                path: 'list',
                name: 'objects.list',
                component: ProjectObjectsList
              },
              {
                path: 'add',
                name: 'objects.add',
                component: AddObject
              },
              {
                path: 'edit/:id',
                name: 'objects.edit',
                component: EditObject
              }
            ]
          }
        ]
      },
      {
        path: 'orders',
        name: 'orders',
        component: Orders,
        children: [
          {
            path: '',
            name: 'orders.list',
            component: () => import('@/views/Orders/OrdersComponents/OrdersList.vue'),
            beforeEnter() {
              const uiStore = useUiStore()
              uiStore.filters.project = null
              uiStore.filters.object = null
              const orderStore = useOrderStore()
              orderStore.targetProject = null
              orderStore.targetObject = null
            }
          },
          {
            path: 'finished',
            name: 'orders.finished',
            component: () => import('@/views/Orders/OrdersComponents/OrdersList.vue')
          },
          {
            path: 'drafts',
            name: 'orders.drafts',
            component: () => import('@/views/Orders/OrdersComponents/OrdersDrafts.vue')
          }
        ]
      },
      {
        path: 'orders/add',
        name: 'addOrder',
        component: AddOrder
      },
      {
        path: 'orders/register/edit',
        name: 'orders.edit.register',
        component: OrderEditRegister
      },
      {
        path: 'order/:id',
        name: 'order',
        component: Order,
        children: [
          {
            // Info page for ordes
            path: '',
            name: 'order.info',
            component: OrderInfo
          },
          {
            path: 'docs',
            name: 'order.docs',
            component: OrderDocs
          }
        ]
      },
      {
        path: 'workers',
        name: 'workers',
        component: () => import('@/views/Workers/Workers.vue'),
        children: workersController
      },
      {
        // main documents page
        path: 'documents',
        name: 'documents',
        component: Docs,
        children: [
          {
            path: '',
            name: 'documents.all',
            component: Documents,
            beforeEnter() {
              const orderStore = useOrderStore()
              orderStore.targetProject = null
              orderStore.targetObject = null
            }
          },
          {
            path: 'history',
            name: 'documents.history',
            component: DocHistory
          }
        ]
      },
      {
        path: 'omvd',
        name: 'omvd',
        component: Omvd,
        children: [
          {
            path: '',
            name: 'omvd.all',
            component: OmvdDocs
          },
          {
            path: 'history',
            name: 'omvd.history',
            component: OmvdHistory
          },
          {
            path: 'notifications',
            name: 'omvd.notifications',
            component: OmvdNotifications
          },
          {
            path: 'trustedpersons',
            name: 'omvd.trustedpersons',
            component: OmvdTrustedPersons
          },
          {
            path: 'trustedpersons/add',
            name: 'omvd.trustedpersons.add',
            component: OmvdTrustedPersonsAdd
          },
          {
            path: 'trustedpersons/edit/:id',
            name: 'omvd.trustedpersons.edit',
            component: OmvdTrustedPersonsAdd
          }
        ]
      },
      {
        path: '/patents',
        name: 'patents.index',
        component: () => import('@/views/Patents/index.vue'),
        children: [
          {
            path: '',
            name: 'patents.current',
            component: () => import('@/views/Patents/PatentsCurrent.vue')
          },
          {
            path: 'new',
            name: 'patents.processing',
            component: () => import('@/views/Patents/PatentsProcessing.vue')
          },
          {
            path: 'payments',
            name: 'patents.forpayment',
            component: () => import('@/views/Patents/PatentsForPayment.vue')
          },
          {
            path: 'expires',
            name: 'patents.expires',
            component: () => import('@/views/Patents/PatentsExpires.vue')
          },
          {
            path: 'expired',
            name: 'patents.expired',
            component: () => import('@/views/Patents/PatentsExpired.vue')
          },
          {
            path: 'invalid',
            name: 'patents.invalid',
            component: () => import('@/views/Patents/PatentsInvalid.vue')
          }
        ]
      },
      {
        path: '/patent/add',
        name: 'patents.add',
        component: () => import('@/views/Patents/PatentAdd.vue')
      },
      {
        path: '/patent/:id',
        name: 'patents.edit',
        component: () => import('@/views/Patents/PatentEdit.vue')
      },
      {
        path: 'company',
        name: 'company.index',
        component: () => import('@/views/Company/index.vue'),
        children: [
          {
            path: '',
            name: 'company.info',
            component: () => import('@/views/Company/Company.vue'),
            beforeEnter() {
              const orderStore = useOrderStore()
              orderStore.targetProject = null
              orderStore.targetObject = null
            }
          },
          {
            path: 'procuration',
            name: 'company.procuration',
            component: () => import('@/views/Company/CompanyProcurations.vue')
          }
        ]
      },
      {
        path: 'personal',
        name: 'personal.index',
        component: Personal,
        children: [
          {
            path: '',
            name: 'personal.list',
            component: () => import('@/views/Workers/InvitedSMSList.vue')
          },
          {
            path: 'invite',
            name: 'personal.invite',
            component: () => import('@/views/Workers/Invite.vue')
          }
        ]
      },
      {
        path: 'personal/add',
        name: 'personal.add',
        component: PersonalAdd,
        children: [
          {
            path: '',
            name: 'personal.add.new',
            component: () => import('@/views/Company/Personal/Invite.vue')
          },
          {
            path: 'invite',
            name: 'personal.add.invited',
            component: () => import('@/views/Company/Personal/InvitedSMSList.vue')
          }
        ]
      },
      {
        path: 'finance',
        name: 'finance',
        component: Finance,
        children: [
          {
            path: '',
            name: 'finance.orders',
            component: FinanceOrders,
            beforeEnter() {
              const orderStore = useOrderStore()
              orderStore.targetProject = null
              orderStore.targetObject = null
            }
          },
          {
            path: 'history',
            name: 'finance.history',
            component: FinanceHistory
          },
          {
            path: 'order/:id',
            name: 'finance.order',
            component: FinanceOrderInfo
          },
          {
            path: 'archive',
            name: 'finance.archive',
            component: FinanceArchive
          },
          {
            path: 'accounts',
            name: 'finance.accounts',
            component: FinanceAccounts
          },
          {
            path: 'dds',
            name: 'finance.dds',
            component: FinanceDDS
          }
        ]
      },
      {
        path: 'guide',
        name: 'guide',
        component: () => import('@/views/Guide/guide-page.vue')
      }
    ]
  },
  {
    path: '/ref/:id',
    name: 'referral',
    redirect: (from: any) => {
      const id = from.params.id
      if (id) {
        const staffStore = useStaffStore()
        staffStore.setReferral(id)
      }
      return '/login'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: Page404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.matched.some((record: RouteRecordNormalized) => record.meta.requiresAuth)) {
    const authStore = useAuthStore()
    if (authStore.tokenExpired) {
      authStore.refreshToken()
        .then(() => {
          next()
        })
        .catch(() => {
          next({
            name: 'login'
          })
        })
      return
    } else {
      if (authStore.loggedIn) {
        const payload = authStore.payload

        const type = payload?.user_type
        const verified = payload?.user_verified
        const companyVerified = payload?.company_is_verified
        const companyId = payload?.company_id
        const edoEnabled = payload?.company_staff_status_to_sign

        // Staff with not verified user account
        if (type === EAccountTypes.staff && verified === false) {
          next({
            name: 'staff.notready'
          })
          authStore.refreshToken()
        }
        // Staff with access to edo
        if (type === EAccountTypes.staff && companyId && edoEnabled) {
          // redirect to eod app
          window.location.pathname = '/edo'
        }

        // Customer without verified company
        if (type === EAccountTypes.customer && companyVerified !== true) {
          const name = String(to.name)
          if (name.startsWith('patents.') || name.startsWith('patent.')) {
            next()
            return
          }
          next({
            name: 'patents.current'
          })
          return
        }

        // type of account in not allowed to use app
        if (type !== EAccountTypes.customer && type !== EAccountTypes.staff) {
          authStore.logout()
          return
        }

        if (!companyId) {
          next({
            name: 'SelectCompany'
          })
        }

        if (to.name === 'login') {
          next({
            path: '/'
          })
          return
        }
      }
      if (!authStore.loggedIn) {
        next({
          name: 'login'
        })
        return
      }
      next()
      return
    }
  }
  next()
})

export default router
