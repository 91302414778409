<template>
  <Loader v-if="loading" />
  <div class="flex flex-wrap xl:flex-nowrap gap-3 mb-5">
    <UInput
      id="searchLastName"
      class="basis-full lg:!basis-1/4"
      placeholder="Введите фамилию исполнителя"
      label="Поиск по фамилии"
      v-model="searchLastName"
      :value="searchLastName"
    />
    <UInput
      id="searchFirstName"
      class="basis-full lg:!basis-1/4"
      placeholder="Введите имя исполнителя"
      label="Поиск по имени"
      v-model="searchFirstName"
      :value="searchFirstName"
    />
    <UISelect
      id="financeTypeSelector"
      class="basis-full lg:basis-1/4"
      label="Тип операции"
      unselectedLabel="Выберите операцию"
      v-model="selectedStatus"
      :value="selectedStatus"
      :options="statusOptions"
    />
    <UDateRange
      class="basis-full lg:basis-1/4"
      :start="filterDates.start_date"
      :finish="filterDates.finish_date"
      @update-start-date="(date: string) => { filterDates.start_date = date }"
      @update-finish-date="(date: string) => { filterDates.finish_date = date }"
      title="Время создания"
      startPlaceHolder="От"
      endPlaceHolder="До"
    />
  </div>
  <EmptyPagePlaceholder pageType="finance" title="Финансы" v-if="!transactions.length && !loading" />
  <UTable v-else colspan="6" :callback="loadHistory">
    <template #thead>
      <tr>
        <th>№ Платежа</th>
        <th>Сумма операции и комиссия</th>
        <th>Исполнитель</th>
        <th>Реестр заказов и тип операции</th>
        <th>Статус операции</th>
        <th>Чек</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="transaction in transactions" :key="transaction.id">
        <td>
          {{ transaction.payment?.id }}
          <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatDate(transaction.created) }}</p>
        </td>
        <td>
          <span class="whitespace-nowrap">{{ formatPrice(transaction.payment?.amount) }}&nbsp;₽</span>
          <p class="font-normal text-xs1 left-3 text-grey mt-1 whitespace-nowrap">
            {{ formatPrice(transaction.payment?.commission) }}&nbsp;₽
          </p>
        </td>
        <td>{{ formatName(transaction.worker) }}</td>
        <td>
          <p>Заказ №{{ transaction.payment?.suborder?.id }} к договору №{{ transaction.payment?.suborder?.agreement }}</p>
          <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ transaction.payment?.suborder?.name }}</p>
        </td>
        <td
          :style="{
            color: getStatusColor(transaction.status)
          }"
        >
          <div class="flex items-center">
            <div
              class="mr-2"
              v-if="transaction.status === ETransactionsStatus.error || transaction.status === ETransactionsStatus.rejected"
            >
              <ErrorTooltip :tooltip="getQiwiErrCode(transaction.error_code) || 'Ошибка!'" />
            </div>
            <div :class="{ '!text-success': transaction.status === 'paid' }">
              {{ formatStatus(transaction.status) }}
              <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatDate(transaction.last_updated) }}</p>
            </div>
          </div>
        </td>
        <td>
          <a
            :href="transaction.payment?.check_image"
            target="_blank"
            :class="{
              checkLink: true,
              isDisabled: !transaction.payment?.check_image || transaction.status !== ETransactionsStatus.paid
            }"
          >
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.8357 5.55533C21.6394 5.32876 16.916 0 11 0C5.08399 0 0.360701 5.32876 0.164291 5.55533C-0.0547636 5.80858 -0.0547636 6.19142 0.164291 6.44467C0.360701 6.67124 5.08408 12 11 12C16.916 12 21.6393 6.67124 21.8357 6.44467C22.0548 6.19142 22.0548 5.80858 21.8357 5.55533ZM11 10.6667C8.51251 10.6667 6.4883 8.57293 6.4883 6C6.4883 3.42707 8.51251 1.33333 11 1.33333C13.4875 1.33333 15.5117 3.42707 15.5117 6C15.5117 8.57293 13.4875 10.6667 11 10.6667Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </td>
      </tr>
    </template>
  </UTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { formatPrice, getQiwiErrCode, formatToServerDate, formatName } from '@/utils/helpers'
import { API } from '@/utils/API'
import axios from 'axios'
import ErrorTooltip from '@/components/Tooltips/ErrorTooltip.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import Loader from '@/components/Loader/Loader.vue'
import { TRANSACTION_STATUS, TRANSACTION_COLORS } from '@/utils/consts'
import { ETransactionsStatus } from '@/types/api-values'
import debounce from '@/utils/debounce'
import { UTable, UInput, UISelect, UDateRange } from 'unit-uikit'

export default defineComponent({
  components: {
    UDateRange,
    EmptyPagePlaceholder,
    Loader,
    ErrorTooltip,
    UTable,
    UInput,
    UISelect
  },
  data() {
    return {
      transactions: [] as any,
      loading: true,
      selectedStatus: '',
      searchFirstName: '',
      searchLastName: '',
      statusOptions: [
        {
          id: '',
          name: 'Все'
        },
        {
          id: ETransactionsStatus.awaiting,
          name: TRANSACTION_STATUS[ETransactionsStatus.awaiting]
        },
        {
          id: ETransactionsStatus.not_signed,
          name: TRANSACTION_STATUS[ETransactionsStatus.not_signed]
        },
        {
          id: ETransactionsStatus.paid,
          name: TRANSACTION_STATUS[ETransactionsStatus.paid]
        },
        {
          id: ETransactionsStatus.sent,
          name: TRANSACTION_STATUS[ETransactionsStatus.sent]
        },
        {
          id: ETransactionsStatus.cancelled,
          name: TRANSACTION_STATUS[ETransactionsStatus.cancelled]
        },
        {
          id: ETransactionsStatus.error,
          name: TRANSACTION_STATUS[ETransactionsStatus.error]
        }
      ],
      filterDates: {
        start_date: null,
        finish_date: null
      } as any,
      ETransactionsStatus,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      debounceLoadOrdersWithParams: null as any
    }
  },
  methods: {
    formatToServerDate,
    getQiwiErrCode,
    loadOrdersWithParams() {
      this.scrollData.requestCount = 0
      this.scrollData.page = 0
      this.transactions = []
      this.loadHistory()
    },
    async loadHistory() {
      if (this.transactions.length < this.scrollData.count) {
        let queryParams = ''
        if (this.searchFirstName) {
          queryParams += `&first_name=${this.searchFirstName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (this.searchLastName) {
          queryParams += `&last_name=${this.searchLastName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (this.selectedStatus) {
          queryParams += `&status=${this.selectedStatus}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        const startDate = this.filterDates.start_date ? new Date(this.filterDates.start_date) : null
        const finishDate = this.filterDates.finish_date ? new Date(this.filterDates.finish_date) : null
        if (startDate) {
          queryParams += `&start_date=${formatToServerDate(startDate)}T00:00:00`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (finishDate) {
          queryParams += `&end_date=${formatToServerDate(finishDate)}T23:59:59.99`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + queryParams
        const response = await axios.get(API.GET_TRANSACTIONS_HISTORY + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.scrollData.requestCount = data.count
          const transactions = data.results
          this.transactions.push(...transactions)
        }
      }
    },
    formatName,
    formatDate(date: any) {
      return moment(new Date(date)).format('DD.MM.YY HH:mm')
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatStatus(status: ETransactionsStatus) {
      return status && TRANSACTION_STATUS[status] ? TRANSACTION_STATUS[status] : ''
    },
    getStatusColor(status: ETransactionsStatus) {
      return status && TRANSACTION_COLORS[status] ? TRANSACTION_COLORS[status] : '#27BBDC'
    }
  },
  created() {
    axios
      .get(API.GET_TRANSACTIONS_HISTORY + `?page=${this.scrollData.page}&page_size=${this.scrollData.size}`)
      .then((response: { data: any }) => {
        this.scrollData.count = response.data.count
        this.transactions = response.data.results || []
        this.loading = false
      })
      .catch((error: any) => {
        console.error(error)
        this.loading = false
      })
  },
  watch: {
    'filterDates.start_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    'filterDates.finish_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchFirstName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchLastName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    selectedStatus() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.checkLink {
  @apply text-grey;

  &:hover {
    @apply text-black cursor-pointer;
  }

  &.isDisabled {
    @apply text-cancelled;

    &:hover {
      @apply text-cancelled cursor-not-allowed;
    }
  }
}
</style>
