import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77bf0d60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-grey not-italic font-normal text-base flex-grow text-left ml-[1em]" }
const _hoisted_2 = {
  key: 1,
  class: "text-error mt-2"
}
const _hoisted_3 = { class: "pt-3.5 flex gap-3" }
const _hoisted_4 = {
  class: "break-words",
  id: "contentCreateOrder"
}
const _hoisted_5 = { class: "text-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_page_header = _resolveComponent("main-page-header")!
  const _component_ConfirmSignModal = _resolveComponent("ConfirmSignModal")!
  const _component_WarningMessage = _resolveComponent("WarningMessage")!
  const _component_AddOrder1 = _resolveComponent("AddOrder1")!
  const _component_AddOrderExtended = _resolveComponent("AddOrderExtended")!
  const _component_AddOrderWorkers = _resolveComponent("AddOrderWorkers")!
  const _component_AddOrder2 = _resolveComponent("AddOrder2")!
  const _component_AddOrder3 = _resolveComponent("AddOrder3")!
  const _component_UButton = _resolveComponent("UButton")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_UModal = _resolveComponent("UModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_main_page_header, { title: _ctx.title }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, "Шаг " + _toDisplayString(_ctx.step) + " из 5", 1)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_ConfirmSignModal, {
      task: "",
      show: _ctx.showConfirmSignModal,
      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showConfirmSignModal = false)),
      onSuccessModal: _ctx.confirmSign
    }, null, 8, ["show", "onSuccessModal"]),
    (_ctx.step === 2 && _ctx.specialErrors.overdraft)
      ? (_openBlock(), _createBlock(_component_WarningMessage, {
          key: 0,
          message: "Превышен свободный остаток бюджета объекта",
          class: "mt-3 self-start inline-block",
          messageType: "info"
        }))
      : _createCommentVNode("", true),
    (_ctx.isMaxSuborders && _ctx.step === 4)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Максимальное возможное количество заказов в реестре заказов 100 шт."))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["mt-[50px] overflow-hidden flex flex-col flex-grow", { '!overflow-visible': _ctx.step === 2 }])
        }, [
          (_ctx.step === 1)
            ? (_openBlock(), _createBlock(_component_AddOrder1, {
                key: 0,
                order: _ctx.order,
                "onUpdate:order": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.order) = $event)),
                v$: _ctx.v$
              }, null, 8, ["order", "v$"]))
            : (_ctx.step === 2)
              ? (_openBlock(), _createBlock(_component_AddOrderExtended, {
                  key: 1,
                  order: _ctx.order,
                  "onUpdate:order": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.order) = $event)),
                  budget: _ctx.budget,
                  "onUpdate:budget": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.budget) = $event)),
                  companyObject: _ctx.companyObject,
                  "onUpdate:companyObject": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.companyObject) = $event)),
                  specialErrors: _ctx.specialErrors
                }, null, 8, ["order", "budget", "companyObject", "specialErrors"]))
              : (_ctx.step === 3)
                ? (_openBlock(), _createBlock(_component_AddOrderWorkers, {
                    key: 2,
                    order: _ctx.order,
                    "onUpdate:order": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.order) = $event)),
                    v$: _ctx.v$,
                    budget: _ctx.budget,
                    specialErrors: _ctx.specialErrors
                  }, null, 8, ["order", "v$", "budget", "specialErrors"]))
                : (_ctx.step === 4)
                  ? (_openBlock(), _createBlock(_component_AddOrder2, {
                      key: 3,
                      order: _ctx.order,
                      "onUpdate:order": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.order) = $event)),
                      v$: _ctx.v$,
                      budget: _ctx.budget,
                      specialErrors: _ctx.specialErrors
                    }, null, 8, ["order", "v$", "budget", "specialErrors"]))
                  : (_openBlock(), _createBlock(_component_AddOrder3, {
                      key: 4,
                      order: _ctx.order,
                      "onUpdate:order": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.order) = $event)),
                      budget: _ctx.budget
                    }, null, 8, ["order", "budget"])),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_UButton, {
              class: "mr-auto w-40",
              color: "secondary",
              label: "Отмена",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$router.push('/orders')))
            }),
            (_ctx.step > 1)
              ? (_openBlock(), _createBlock(_component_UButton, {
                  key: 0,
                  label: "Назад",
                  class: "w-40",
                  color: "secondary",
                  onClick: _ctx.prevStep
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_UButton, {
              class: _normalizeClass(["w-40", { 'w-44': _ctx.step === 4 }]),
              id: "nextStepBtn",
              label: _ctx.getNextBtnLabel,
              disabled: 
          _ctx.disableBtnNext ||
          (_ctx.step === 2 && _ctx.specialErrors.isOverDraftCompany) ||
          (_ctx.step === 3 && _ctx.disableBtnSuborders) ||
          (_ctx.step === 4 && _ctx.specialErrors.limitPrice) ||
          (_ctx.step === 4 && _ctx.disableBtnNextOutOffLimit) ||
          (_ctx.step === 4 && _ctx.isMaxSuborders) ||
          (_ctx.step === 4 && !_ctx.isDraftDataReadyStep4)
        ,
              onClick: _ctx.nextStep
            }, null, 8, ["class", "label", "disabled", "onClick"])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.creating)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(_component_UModal, {
      show: _ctx.created,
      onCancel: _cache[9] || (_cache[9] = ($event: any) => {;(_ctx.created = false), _ctx.$router.push('/orders')}),
      onSuccess: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$router.push('/orders'))),
      title: "Реестр заказов",
      cancelLabel: "",
      successLabel: "Перейти к списку Заказов"
    }, {
      content: _withCtx(() => [
        _createElementVNode("p", _hoisted_4, "Реестр заказов «" + _toDisplayString(_ctx.order.name || '') + "» успешно создан!", 1)
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_UModal, {
      show: _ctx.showModalNotVerifiedWorker,
      onCancel: _cache[12] || (_cache[12] = ($event: any) => {;(_ctx.showModalNotVerifiedWorker = false), (_ctx.failedcreate = false)}),
      onSuccess: _cache[13] || (_cache[13] = ($event: any) => {;(_ctx.showModalNotVerifiedWorker = false), (_ctx.failedcreate = false)}),
      title: "Ошибка при создании заказа"
    }, {
      content: _withCtx(() => [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.errorResponseMsg), 1)
      ]),
      buttons: _withCtx(() => [
        _createVNode(_component_UButton, {
          label: "Продолжить",
          onClick: _cache[11] || (_cache[11] = ($event: any) => {;(_ctx.showModalNotVerifiedWorker = false), (_ctx.failedcreate = false)})
        })
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}