<script setup lang="ts">
import { computed } from 'vue'
import { formatPrice } from '@/utils/helpers'
import { UTooltip } from 'unit-uikit'
import d from '@/dictionary'

const props = defineProps({
  limitWithTax: {
    type: [Number, String],
    default: 0
  },
  limitWithoutTax: {
    type: [Number, String],
    default: 0
  },
  tax: {
    type: Boolean,
    default: false
  },
  price: {
    type: String,
    default: '0'
  },
  suborder: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['limitError'])

const limitError = computed(() => {
  const price = parseFloat(props.price?.replaceAll(' ', '').replace(',', '.'))
  if (props.tax) {
    return price > +props.suborder.month_limit
  }
  return price > +props.suborder.month_limit_without_tax
})

setTimeout(() => {
  emit('limitError', limitError.value)
}, 300)

const limitMsg = computed(() => {
  const lWithTax = +props.limitWithTax - +props.suborder.personaldata.to_pay_with_retention
  const lWithOutTax = +props.limitWithoutTax - +props.suborder.personaldata.to_pay_without_retention
  const price = parseFloat(props.price?.replaceAll(' ', '').replace(',', '.'))

  if (limitError.value) {
    return `Превышен лимит выплат в ${props.tax ? formatPrice(props.limitWithTax) : formatPrice(props.limitWithoutTax)} ₽`
  }
  return formatPrice(props.tax ? lWithTax - price : lWithOutTax - price) + ' ₽'
})
</script>

<template>
  <div class="flex items-center gap-1">
    <div class="flex flex-col gap-1">
      <span class="block">{{ formatPrice(price) }}&nbsp;₽</span>
      <span v-if="limitError" class="text-error">
        {{ limitMsg }}
      </span>
    </div>
    <UTooltip v-if="tax" class="!static" :color="limitError ? 'error' : 'primary'" percent :tooltip="d['payment-fee']" />
  </div>
</template>
