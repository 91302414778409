<template>
  <label :for="id" class="flex justify-center border border-black border-solid rounded items-center bg-white label-wrapper">
    <img src="/icons/download-photo.svg" class="mr-5 flex-shrink-0" />
    <h3 class="overflow-hidden">{{ label }}</h3>
    <input type="file" class="hidden" :name="id" :id="id" accept="image/png, image/jpeg, image/jpg" @change="updateInput" />
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {}
  },
  props: {
    id: { type: [String, Number] as any },
    label: { type: String }
  },
  methods: {
    updateInput(event: any) {
      // @ts-ignore
      const files = [...this.$attrs.modelValue, ...event.target.files]
      this.$emit('update:modelValue', files)
    }
  }
})
</script>

<style lang="postcss" scoped>
.label-wrapper {
  @apply h-[52px] px-2 hover:cursor-pointer;
}
</style>
