<template>
  <div class="flex flex-col">
    <h2 v-if="hideBackURL" class="text-lg mb-1 lg:text-2xl ml-1 font-medium">{{ label }}</h2>
    <a
      v-else
      href="#"
      @click.prevent="backAction"
      class="border border-gray-200 hover:opacity-75 rounded-lg pt-[11px] pl-3.5 w-9 h-9 lg:w-auto lg:h-auto lg:border-none font-medium flex flex-grow mb-10"
    >
      <span class="text-[22px] lg:text-2xl w-[6px] h-[10px]">
        <img src="/icons/arrow-back.svg" alt="back icon" />
      </span>
      <span class="hidden lg:block ml-2 text-xs1">Назад</span>
    </a>
    <h2 class="text-sm md:text-[24px] md:leading-[1.1] font-medium text-center md:text-left">{{ label }}</h2>

    <div v-if="showCloseBtn">
      <button type="button" v-if="showActionBtns" class="header-btn mr-1"><img src="/icons/copy.svg" alt="copy" /></button>
      <button type="button" v-if="showActionBtns" class="header-btn mr-1"><img src="/icons/share.svg" alt="share" /></button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: { type: String, default: 'Регистрация' },
    showCloseBtn: { type: Boolean, default: false },
    showActionBtns: { type: Boolean, default: false },
    backURL: { type: [String, Number], default: -1 },
    hideBackURL: { type: Boolean, default: false },
    backCustomAction: { type: Function }
  },
  methods: {
    backAction() {
      if (this.backCustomAction) {
        this.backCustomAction()
        return
      }
      if (this.backURL === -1) {
        this.$router.go(this.backURL)
        return
      }
      this.$router.push({
        // @ts-ignore
        name: this.backURL
      })
    }
  }
})
</script>

<style lang="postcss" scoped>
* {
  @apply font-main;
}

.header-btn {
  @apply h-7 w-7 overflow-hidden leading-7 font-light text-4xl text-center text-grey1;

  img {
    @apply h-5 object-contain object-center w-full;
  }

  &:active {
    @apply text-black;
  }
}
</style>
