import moment from 'moment'
import IMask from 'imask'

export function formatPhone(phone: number | string) {
  const _phone = (phone + '').replaceAll(' ', '') || ''
  const newPhone = IMask.pipe(_phone, {
    mask: '+{7}-000-000-00-00'
  })
  return newPhone
    .split('-')
    .map((item: any, index: number) => {
      if (index === 1 || index === 2) {
        return '***'
      }
      return item
    })
    .join('-')
}

export function formatPrice(price: number | string) {
  const _price = (price + '').replaceAll('.', ',').replaceAll(' ', '') || '0'
  const newPrice = IMask.pipe(_price, {
    mask: Number,
    scale: 2,
    thousandsSeparator: ' ',
    normalizeZeros: true,
    padFractionalZeros: true,
    radix: ',', // fractional delimiter
    mapToRadix: ['.']
  })
  if (price < 0) {
    return '-' + newPrice
  }
  return newPrice
}

export function formatName(worker?: any) {
  return worker ? `${worker.last_name || ''} ${worker.first_name || ''} ${worker.middle_name || ''}`.trim() : ''
}

export function formatDate(date: any, shortYear = false, withTime = false) {
  if (date && shortYear && !withTime) {
    return moment(new Date(date)).format('DD.MM.YY')
  } else if (date && !shortYear && !withTime) {
    return moment(new Date(date)).format('DD.MM.yy')
  } else if (date && shortYear && withTime) {
    return moment(new Date(date)).format('DD.MM.YY HH:mm')
  }
  return ''
}

export function formatTime(date: any) {
  if (date) {
    return moment(new Date(date)).format('HH:mm:ss')
  }
  return ''
}

export function formatToServerDate(date: any) {
  if (!date) {
    return ''
  }
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function formatToServerDateNotifications(date: string) {
  if (!date) {
    return ''
  }
  const value = date.split('.')
  return `${value[2]}-${value[1]}-${value[0]}`
}

export function formatDateForQueryString(date: any) {
  const _date = new Date(date)
  return `${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`
}

export function formatDateSuborderModal(date: string) {
  if (!date) {
    return null
  }

  const dateArr = date?.split('-')
  if (dateArr.length === 3) {
    const year = +dateArr[0]
    const month = +dateArr[1] - 1
    const day = +dateArr[2]
    return new Date(year, month, day)
  }

  const dateArrDots = date?.split('.')
  if (dateArrDots.length === 3) {
    const year = +dateArrDots[2]
    const month = +dateArrDots[1] - 1
    const day = +dateArrDots[0]
    return new Date(year, month, day)
  }

  return new Date(date)
}

export function getSplittedInn(inn: string | undefined) {
  if (!inn) {
    return
  }
  const inns = inn.split('')
  const result = []
  const part1 = inns[0] + inns[1] + inns[2] + inns[3]
  const part2 = inns[4] + inns[5] + inns[6] + inns[7]
  const part3 = inns[8] + inns[9] + inns[10] + inns[11]
  result.push(part1, part2, part3)
  return result.join(' ')
}

export function decimalCount(num: string | number) {
  // Convert to String
  const numberAsString = num.toString()
  // String Contains Decimal
  if (numberAsString.includes('.')) {
    return numberAsString.split('.')[1].length
  }
  if (numberAsString.includes(',')) {
    return numberAsString.split(',')[1].length
  }
  // String Does Not Contain Decimal
  return 0
}

const qiwiErrCodes: any = {
  '0001': 'Не удалось провести платеж. Необходимо чтобы исполнитель подтвердил партнерство с Киви-Банк в приложении “Мой налог“',
  '0002': 'Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей.',
  '0003': 'Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей.',
  '0004': 'Неверный номер карты',
  '0005': 'Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей.',
  '0006': 'Не удалось провести платеж. Необходимо чтобы исполнитель подтвердил партнерство с Киви-Банк в приложении “Мой налог“',
  '0007':
    'Не удалось провести платеж. Возможные причины: Годовой доход самозанятого исполнителя достиг максимального лимита. Банк эмитент карты получателя отклонил платеж. Попросите самозанятого указать другие реквизиты банковской карты, на которую возможен перевод.',
  '0008':
    'Платеж отклонен из-за лимита на удержание налога. Чтобы провести платеж, создайте заказ заново без удержания налога. Самозанятому необходимо оплатить налог самостоятельно.',
  '0009': 'Налогоплательщик не найден/не зарегистрирован',
  '0010': 'Налогоплательщик не привязан к КИВИ Банк',
  '0011': 'Неверный номер карты',
  '0012': 'Карта отклонена. Обратитесь в банк эмитента'
}

export function getQiwiErrCode(errCode: string | number): string {
  return qiwiErrCodes[errCode] || 'Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей.'
}

export function transliterate(word: string): string {
  const alphabet: [string, string][] = [
    ['А', 'A'],
    ['а', 'a'],
    ['В', 'B'],
    ['К', 'K'],
    ['к', 'k'],
    ['Е', 'E'],
    ['е', 'e'],
    ['Н', 'H'],
    ['н', 'H'],
    ['Х', 'X'],
    ['х', 'x'],
    ['у', 'y'],
    ['Р', 'P'],
    ['р', 'p'],
    ['о', 'o'],
    ['О', 'O'],
    ['м', 'm'],
    ['М', 'M'],
    ['Т', 'T'],
    ['т', 't'],
    ['С', 'C'],
    ['с', 'c']
  ]
  return Array.from(word)
    .map((char: string): string => {
      const found: [string, string] | undefined = alphabet.find((item: [string, string]): boolean => item[0] === char)
      return found ? found[1] : char
    })
    .join('')
}

export function checkFileSize(file: File, limit: number) {
  // get file size in megabytes
  return file?.size / (1024 * 1024) > limit
}
