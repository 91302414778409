<script setup lang="ts">
import { computed, ref } from 'vue'
import { API } from '@/utils/API'
import { UInput, UButton, UTextarea } from 'unit-uikit'
import { useAuthStore } from '@/stores/auth'
import { useOrderStore } from '@/stores/order'
import axios from 'axios'
import { useRouter, useRoute } from 'vue-router'
import { useProjectForm } from '@/views/Projects/project-form'

const router = useRouter()
const route = useRoute()

const title = computed(() => {
  if (route.name === 'projects.add') {
    return 'Добавление нового проекта'
  }
  return 'Изменение проекта'
})

const type = computed(() => {
  if (route.name === 'projects.add') {
    return 'add'
  }
  return 'edit'
})

const orderStore = useOrderStore()

const errors = ref([])

const { project, v$ } = useProjectForm()

const getBudgetVal = computed(() => {
  if (project.value.budget.length <= 1) {
    return project.value.budget.replace(/[\s₽,]/g, '').replace(/(\.00|,)/g, '')
  } else {
    return project.value.budget.replace(/[\s₽,]/g, '').replace(/(\.00|,)/g, '') + ' ₽'
  }
})

const addProject = () => {
  v$.value?.$validate()
  if (!v$.value.$error) {
    project.value.budget = project.value.budget.replace(/[\s₽,]/g, '')

    axios
      .post(API.ADD_PROJECT, project.value)
      .then((res: { data: any }) => {
        orderStore.targetProject = res.data.id
        router.push({
          name: 'objects.add'
        })
      })
      .catch((err: any) => {
        errors.value = err.response.data
      })
  }
}

const edit = () => {
  v$.value?.$validate()
  if (!v$.value?.$error) {
    project.value.budget = project.value.budget.replace(/[\s₽,]/g, '')

    axios
      .put(API.EDIT_SINGLE_PROJECT(route.params.id as string), project.value)
      .then((res: { data: any }) => {
        orderStore.targetProject = res.data.id
        router.push({
          name: 'projects.list'
        })
      })
      .catch((err: any) => {
        errors.value = err.response.data
      })
  } else {
    console.error('error!')
  }
}

const authStore = useAuthStore()
project.value.company = authStore.payload?.company_id || ''

if (type.value === 'edit') {
  axios.get(API.GET_SINGLE_PROJECT(route.params.id as string)).then((res: { data: any }) => {
    project.value.name = res.data.name
    project.value.budget = res.data.budget
    project.value.description = res.data.description
  })
}
</script>

<template>
  <h2 class="add-title">{{ title }}</h2>
  <div class="form">
    <div class="flex mb-3 w-full">
      <div class="validate-wrap mr-4 relative">
        <UInput
          id="projectName"
          label="Название проекта"
          maxLength="200"
          placeholder="Название проекта"
          v-model="project.name"
          :value="project.name"
        />
        <div class="validate-error" v-if="v$.name.$dirty && v$.name.required.$invalid">Обязательное поле</div>
        <div class="validate-error" v-if="v$.name.$dirty && v$.name.minLength.$invalid">Минимальная длина имени 2 символа</div>
        <div class="symbols-left top-11" :class="{ error: project.name?.length > 200 }">{{ project.name?.length || 0 }} / 200</div>
      </div>
      <div class="validate-wrap">
        <UInput id="projectBudget" label="Бюджет проекта" placeholder="Бюджет проекта" v-model="project.budget" :value="getBudgetVal" />
        <div class="validate-error" v-if="v$.budget.$dirty && v$.budget.required.$invalid">Обязательное поле</div>
      </div>
    </div>
    <div class="validate-wrap relative w-full mb-4">
      <UTextarea
        maxLength="1000"
        name="project_description"
        id="project_description"
        v-model="project.description"
        :value="project.description"
        placeholder="Описание проекта"
        class="text-sm"
      />
      <div class="validate-error" v-if="v$.description.$dirty && v$.description.required.$invalid">Обязательное поле</div>
      <div class="symbols-left !-bottom-4" :class="{ error: project.description?.length > 1000 }">
        {{ project.description?.length || 0 }} / 1000
      </div>
    </div>
    <h4 class="validation-errors text-error mb-3" v-for="(error, index) in errors" :key="index">
      <p><b>Ошибка: </b>{{ error }}</p>
    </h4>
    <p class="validation-errors text-error mb-3" v-if="v$.$error">
      Пожалуйста заполните все необходимые поля перед добавлением нового проекта
    </p>
    <div class="form-controls mt-4">
      <UButton label="Отмена" color="secondary" class="mr-5" @click="$router.push('/projects')" />
      <UButton label="Добавить" @click="addProject" v-if="type === 'add'" />
      <UButton label="Изменить" @click="edit" v-if="type === 'edit'" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.add-title {
  @apply text-left mb-5 not-italic font-medium text-2xl leading-7 text-black;
}

.form {
  @apply flex flex-wrap;
}

.form-controls {
  @apply flex ml-auto;
}

.validate-wrap {
  @apply flex-grow;

  & .input-container {
    @apply w-full max-w-full;
  }
  .add-input {
    @apply w-full max-w-none;
  }
}

#object_description,
#project_description {
  @apply py-[17px] px-3.5;

  &:placeholder {
    @apply text-grey1;
  }
}

.symbols-left {
  @apply absolute -bottom-1.5 right-0 text-xs text-grey;
  &.error {
    @apply text-error;
  }
}
</style>
