<template>
  <Loader v-if="loading" />
  <form v-show="!loading" class="form !overflow-x-hidden space-y-6">
    <h2 class="order-title">Основная информация</h2>
    <div class="flex flex-wrap md:flex-nowrap gap-3">
      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px]">
        <UISelect
          id="addOrderProject"
          v-if="!loading"
          class="!w-full !max-w-none"
          label="Проект"
          unselectedLabel="Выберите проект"
          v-model="order.project"
          :value="order.project || ''"
          :options="projects"
        />
        <div class="error-block-message" v-if="v$?.project.$dirty && v$?.project.required.$invalid">Обязательное поле</div>
      </div>

      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px">
        <UISelect
          id="addOrderObject"
          v-if="!loading"
          class="!w-full !max-w-none"
          label="Объект"
          unselectedLabel="Выберите объект"
          v-model="order.object"
          :value="order.object || ''"
          :options="objects"
        />
        <div class="error-block-message" v-if="v$?.object.$dirty && v$?.object.required.$invalid">Обязательное поле</div>
      </div>

      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px]">
        <UDateRange
          title="Период работ"
          :start="order?.start_date"
          :finish="order?.finish_date"
          @update-start-date="(date: string) => { order.start_date = date }"
          @update-finish-date="(date: string) => { order.finish_date = date }"
        />

        <div
          class="error-block-message"
          v-if="
            (v$?.start_date.$dirty && v$?.start_date.required.$invalid) || (v$?.finish_date.$dirty && v$?.finish_date.required.$invalid)
          "
        >
          Обязательное поле
        </div>
      </div>
    </div>

    <div class="flex flex-shrink-0">
      <div class="error-block-wrapper">
        <div class="!h-auto input-wrapper">
          <h3 class="input-wrapper__title">Название заказа</h3>
          <UTextarea
            v-if="order"
            name="name"
            id="name"
            class="text-sm leading-snug !pt-[18px] min-h-[60px]"
            v-model="order.name"
            :value="order.name"
            resizeY
            maxLength="200"
          />
          <UTooltip tooltip="Данная формулировка отобразится в заказе и акте выполненных работ" />
        </div>
        <div class="error-block-message" v-if="v$?.name.$dirty && v$?.name.required.$invalid">Обязательное поле</div>
        <div class="error-block-message" v-if="v$?.name.$dirty && v$?.name.maxLength.$invalid">
          Пожалуйста, введите не более 200 символов
        </div>
        <div class="symbols-left" :class="{ error: order?.name?.length > 200 }">{{ order?.name?.length || 0 }} / 200</div>
      </div>
    </div>

    <div class="flex flex-shrink-0">
      <div class="error-block-wrapper">
        <div class="!h-auto input-wrapper">
          <h3 class="input-wrapper__title">Описание реестра заказа</h3>
          <UTextarea
            v-if="order"
            name="description"
            id="description"
            class="text-sm leading-snug !pt-[18px] min-h-[60px]"
            :value="order.description"
            v-model="order.description"
            resizeY
            maxLength="1000"
          />
        </div>
        <div class="error-block-message" v-if="v$?.description.$dirty && v$?.description.required.$invalid">Обязательное поле</div>
        <div
          class="error-block-message"
          v-if="
            (v$?.description.$dirty && v$?.description.maxLength.$invalid) ||
            (order?.description?.length && order?.description?.length > 1000)
          "
        >
          Пожалуйста, введите не более 1000 символов
        </div>
        <span class="symbols-left" :class="{ error: order?.description?.length > 1000 }">{{ order?.description?.length || 0 }}/1000</span>
      </div>
    </div>

    <div class="flex gap-3">
      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px]">
        <UInput
          v-if="order"
          class="!max-w-none !w-auto"
          name="quantity"
          id="quantity"
          v-model="order.quantity"
          :value="order.quantity ? order.quantity : ''"
          placeholder="0"
          label="Объем работ"
          autocomplete="off"
          mask="numbers"
          :numberType="true"
        />
        <div class="error-block-message" v-if="v$?.quantity.$dirty && v$?.quantity.required.$invalid">Обязательное поле</div>
        <div class="error-block-message" v-if="v$?.quantity.between.$invalid">Превышено максимальное значение</div>
      </div>

      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px]">
        <UISelect
          v-if="order"
          id="unitTypeOrder"
          class="!max-w-none !w-auto"
          label="Тип единицы"
          unselectedLabel="Выберите единицы объёма работ"
          v-model="order.unit_type"
          :value="order.unit_type || ''"
          :options="unitTypesList"
        />
        <div class="error-block-message" v-if="v$?.unit_type.$dirty && v$?.unit_type.required.$invalid">Обязательное поле</div>
      </div>

      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px]">
        <UISelect
          id="photoRequiredSelector"
          class="!max-w-none !w-auto"
          label="Фото по завершении работ"
          unselectedLabel="Да или нет"
          v-model="photoRequired"
          :value="photoRequired"
          :options="photoAfterWorksOptions"
        />
      </div>
    </div>

    <div class="flex gap-3 flex-wrap xl:flex-nowrap">
      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px]">
        <div>
          <UInput
            v-if="order"
            class="!max-w-none !w-auto"
            type="text"
            name="address"
            id="address"
            v-model="order.address"
            :value="order.address"
            placeholder="Адрес"
            label="Адрес выполнения услуг"
            autocomplete="off"
          />
          <div class="error-block-message !top-11" v-if="v$?.address.$dirty && v$?.address.required.$invalid">Обязательное поле</div>
        </div>
        <div class="relative mt-6">
          <span class="input-wrapper__title multiselect-label">Применяемая техника, материалы, требования к допуску</span>
          <UMultiselect
            v-if="order"
            id="equipment"
            v-model="order.equipment"
            :options="equipment"
            placeholder="Применяемая техника, материалы, требования к допуску"
            :multiple="true"
            :searchable="false"
            label="name"
            track-by="id"
            :showLabels="false"
            @select="selectActionEquipment"
          />
          <div class="error-block-message" v-if="v$?.equipment.$dirty && v$?.equipment.required.$invalid">Пожалуйста, выберите шаблон</div>
        </div>
      </div>
      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 min-w-[200px]">
        <div class="relative">
          <div class="relative">
            <UInput
              v-if="order"
              class="!max-w-none !w-auto"
              type="text"
              name="requirments"
              id="requirments"
              v-model="order.special_requirements"
              :value="order.special_requirements"
              placeholder="Спец. требования к заказу"
              label="Специализированные требования к заказу"
              autocomplete="off"
              maxlength="100"
            />
            <div class="symbols-left" :class="{ error: order?.special_requirements?.length > 100 }">
              {{ order?.special_requirements?.length }} / 100
            </div>
          </div>
          <UTooltip tooltip="Опишите требования к квалификациии опыту исполнителя, если необходимо" class="right-[11px]" />
          <div class="error-block-message" v-if="v$?.special_requirements.$dirty && v$?.special_requirements.required.$invalid">
            Пожалуйста, заполните поле
          </div>
          <div class="error-block-message" v-if="v$?.special_requirements.$dirty && v$?.special_requirements.maxLength.$invalid">
            Пожалуйста, введите не более 100 символов
          </div>
        </div>
        <div class="relative mt-6">
          <span class="input-wrapper__title multiselect-label">Требования к сертификатам</span>
          <UMultiselect
            v-if="order"
            v-model="order.job_certs_requirements"
            :options="certificateOptions"
            placeholder="Требования к сертификатам"
            :multiple="true"
            :searchable="false"
            label="name"
            track-by="id"
            :showLabels="false"
            @select="selectAction"
          />
          <div class="error-block-message" v-if="v$?.job_certs_requirements.$dirty && v$?.job_certs_requirements.required.$invalid">
            Обязательное поле
          </div>
        </div>
      </div>
      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3 flex flex-col gap-3">
        <template v-if="photoRequired + '' === '1'">
          <label class="!h-auto input-wrapper">
            <h3 class="input-wrapper__title">Требования для фотографий</h3>
            <UTextarea
              name="photoAfterWorkComment"
              id="photoAfterWorkComment"
              class="text-sm leading-snug !pt-[18px] min-h-[60px]"
              placeholder="Опишите свои требования для фотографий, какие фото и в каком ракурсе нужно добавлять при завершении заказа"
              :value="order.photo_comments"
              v-model="order.photo_comments"
              resizeY
              maxLength="1000"
            />
            <span class="symbols-left" :class="{ error: order.photo_comments?.length > 1000 }"
              >{{ order.photo_comments?.length || 0 }}/1000</span
            >
          </label>
        </template>
        <USwitch
          v-if="order"
          class="bank-fee-tooltip"
          v-model="order.withholding_tax"
          id="withholding_tax"
          :labelText="d['bank-fee-tooltip']"
          labelON="Да"
          labelOFF="Нет"
        />
      </div>
    </div>

    <div class="flex relative max-w-fit">
      <h2 class="order-title mr-1 whitespace-nowrap">Выбор шаблона документации к заказу</h2>
      <UTooltip class="!-right-4" tooltip="Согласно договору между Заказчиком и Оператором представлены данные шаблоны для документации" />
    </div>

    <div class="flex flex-wrap md:flex-nowrap gap-3">
      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3">
        <UISelect
          v-if="order"
          id="order_agreement_template"
          class="!max-w-none !w-auto"
          label="Выбор шаблона рамочного договора"
          unselectedLabel="Выберите шаблон"
          v-model="order.agreement_template"
          :value="order.agreement_template || ''"
          :options="templates.agreement_template"
        />
        <div class="error-block-message" v-if="v$?.agreement_template.$dirty && v$?.agreement_template.required.$invalid">
          Пожалуйста, выберите шаблон
        </div>
      </div>

      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3">
        <UISelect
          v-if="order"
          id="order_order_template"
          class="!max-w-none !w-auto"
          label="Выбор шаблона договора задания"
          unselectedLabel="Выберите шаблон"
          v-model="order.order_template"
          :value="order.order_template || ''"
          :options="templates.order_template"
        />
        <div class="error-block-message" v-if="v$?.order_template.$dirty && v$?.order_template.required.$invalid">
          Пожалуйста, выберите шаблон
        </div>
      </div>

      <div class="error-block-wrapper flex-grow flex-shrink basis-1/3">
        <UISelect
          v-if="order"
          id="acceptance_certificate_template"
          class="!max-w-none !w-auto"
          label="Выбор шаблона акта"
          unselectedLabel="Выберите шаблон"
          v-model="order.acceptance_certificate_template"
          :value="order.acceptance_certificate_template || ''"
          :options="templates.acceptance_certificate_template"
        />
        <div
          class="error-block-message"
          v-if="v$?.acceptance_certificate_template.$dirty && v$?.acceptance_certificate_template.required.$invalid"
        >
          Пожалуйста, выберите шаблон
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="error-block-wrapper">
        <UInput
          v-if="order"
          class="!max-w-none !w-auto"
          type="text"
          name="omvd"
          id="omvd"
          v-model="order.omvd"
          :value="order.omvd"
          placeholder="Введите название отделения"
          label="Выбор отделения МВД"
          autocomplete="off"
        />
        <div class="error-block-message" v-if="v$?.omvd.$dirty && v$?.omvd.maxLength.$invalid">
          Пожалуйста, введите не более 102 символов
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import { APIAddWorkersOrder } from '@/interfaces/main'
import { UTooltip, UDateRange, UISelect, UInput, UTextarea, UMultiselect, USwitch } from 'unit-uikit'
import Loader from '@/components/Loader/Loader.vue'
import d from '@/dictionary'

export default defineComponent({
  name: 'AddOrder1',
  components: {
    UTooltip,
    Loader,
    UDateRange,
    UISelect,
    UInput,
    UTextarea,
    UMultiselect,
    USwitch
  },
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    v$: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      projects: [],
      allObjects: [],
      objects: [] as any[],
      projectsList: [],
      unitTypesList: [],
      photoRequired: 0,
      photoAfterWorksOptions: [
        {
          id: 1,
          name: 'Да'
        },
        {
          id: 0,
          name: 'Нет'
        }
      ],
      templates: {
        agreement_template: [],
        order_template: [],
        acceptance_certificate_template: []
      },
      certificateOptions: [
        {
          id: '',
          name: 'Нет'
        }
      ],
      equipment: [
        {
          id: '',
          name: 'Нет'
        }
      ],
      booleans: [
        {
          id: 'да',
          name: 'Да'
        },
        {
          id: 'нет',
          name: 'Нет'
        }
      ],
      units: [
        {
          id: 1,
          name: 'кв.м'
        },
        {
          id: 2,
          name: 'м'
        },
        {
          id: 3,
          name: 'кг'
        },
        {
          id: 4,
          name: 'куб.м'
        }
      ],
      d
    }
  },
  methods: {
    selectAction(action: any) {
      if (action.id === '') {
        setTimeout(() => {
          this.order.job_certs_requirements = [{ id: '', name: 'Нет' }]
        }, 0)
        return
      }
      if (
        action.id !== '' &&
        this.order.job_certs_requirements &&
        this.order.job_certs_requirements?.length === 1 &&
        this.order.job_certs_requirements[0].id === ''
      ) {
        this.order.job_certs_requirements = [action]
        setTimeout(() => {
          this.order.job_certs_requirements = [action]
        }, 0)
      }
    },
    selectActionEquipment(action: any) {
      if (action.id === '') {
        setTimeout(() => {
          this.order.equipment = [{ id: '', name: 'Нет' }]
        }, 0)
        return
      }
      if (action.id !== '' && this.order.equipment && this.order.equipment?.length === 1 && this.order.equipment[0].id === '') {
        this.order.equipment = [action]
        setTimeout(() => {
          this.order.equipment = [action]
        }, 0)
      }
    },
    getWorkTypes() {
      axios.get(API.GET_WORK_TYPES).then((res: { data: any }) => {
        this.projectsList = res.data.results
      })
    }
  },
  created() {
    this.order.special_requirements = this.order.special_requirements ? this.order.special_requirements : 'Нет'
    this.order.withholding_tax = !this.order.withholding_tax ? false : this.order.withholding_tax
    this.photoRequired = this.order.photo_after_completion ? 1 : 0
    this.getWorkTypes()
    const getOrderData = axios.get(API.GET_ORDER_PREPARATION)
    const getObjects = axios.get(API.GET_OBJECT)

    axios.get(API.GET_UNIT_TYPES).then((res: any) => {
      this.unitTypesList = res.data
    })

    Promise.all([getOrderData, getObjects])
      .then((results) => {
        results.forEach((result: any) => {
          const responseURL = result?.request?.responseURL
          if (responseURL.indexOf('object') !== -1) {
            this.allObjects = result.data

            const projectId = this.order.project
            if (projectId) {
              this.objects = result.data.filter((o: any) => {
                return o?.project?.id + '' === projectId + ''
              })
            }
            const objectId = this.order.object
            if (objectId) {
              result.data.forEach((obj: any) => {
                if (obj.id + '' === objectId + '') {
                  this.order.address = obj?.address
                }
              })
            }
          } else {
            this.projects = result.data?.main || []
            const projectId = this.order.project

            this.projects = this.projects.filter((project: any) => {
              const objectIsNotArchive = project.object_set?.find((o: any) => {
                return o?.status !== 'archive'
              })
              return !!project.objects && objectIsNotArchive
            })

            const projectIsNotArchive = this.projects.find((p: any) => {
              return p.id + '' === projectId + ''
            })

            if (!projectIsNotArchive) {
              this.order.project = null
            }

            const agreementTemplateArr = result.data?.agreement_templates
            this.templates.agreement_template = agreementTemplateArr
            if (agreementTemplateArr?.length === 1 && !this.order.agreement_template) {
              this.order.agreement_template = agreementTemplateArr[0]?.id
            }

            const orderTemplateArr = result.data?.order_templates
            this.templates.order_template = orderTemplateArr
            if (orderTemplateArr?.length === 1 && !this.order.order_template) {
              this.order.order_template = orderTemplateArr[0]?.id
            }

            const certificateTemplateArr = result.data?.accpetance_certificate_templates
            if (certificateTemplateArr) {
              this.templates.acceptance_certificate_template = certificateTemplateArr
              if (certificateTemplateArr?.length === 1 && !this.order.acceptance_certificate_template) {
                this.order.acceptance_certificate_template = certificateTemplateArr[0]?.id
              }
            }

            const certificateRequirments = result.data?.job_certs_requirements
            if (certificateRequirments) {
              this.certificateOptions = this.certificateOptions.concat(certificateRequirments)
              if (certificateRequirments?.length === 1 && !this.order.job_certs_requirements) {
                this.order.job_certs_requirements = certificateRequirments[0]
              } else if (!this.order.job_certs_requirements) {
                this.order.job_certs_requirements = [{ id: '', name: 'Нет' }]
              }
            }

            const technicsReqs = result.data?.equipment
            if (technicsReqs) {
              this.equipment = this.equipment.concat(technicsReqs)
              if (technicsReqs?.length === 1 && !this.order.equipment?.length) {
                this.order.equipment = technicsReqs[0]
              } else if (!this.order.equipment?.length) {
                this.order.equipment = [{ id: '', name: 'Нет' }]
              }
            } else {
              this.order.equipment = [{ id: '', name: 'Нет' }]
            }

            // this.order.monthly_limit_without_tax = result.data.monthly_limit_without_tax
            // this.order.monthly_limit_with_tax = result.data.monthly_limit_with_tax
            this.order.monthly_limit_without_tax = 600000
            this.order.monthly_limit_with_tax = 250000
          }
          this.loading = false
        })
      })
      .catch((e) => {
        this.loading = false
        console.error(e)
      })
  },
  watch: {
    photoRequired() {
      this.order.photo_after_completion = !!+this.photoRequired
    },
    'order.object'() {
      const id = this.order.object
      if (id) {
        const filteredOjbect = this.allObjects.filter((o: any) => {
          return o?.id + '' === id + ''
        })
        const obj: any = filteredOjbect && filteredOjbect[0]
        this.order.address = obj?.address
      }
    },
    'order.project'() {
      const project = this.order.project

      this.order.object = null
      if (project) {
        this.objects = this.allObjects.filter((o: any) => {
          return o?.project?.id + '' === project + ''
        })
      } else {
        this.objects = []
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.symbols-left {
  @apply absolute bottom-[3px] right-3 text-xs text-gray-300;
  &.error {
    @apply text-error;
  }
}
#name,
#description,
#photoAfterWorkComment {
  @apply h-[120px] !border;
}

.bank-fee-tooltip {
  @apply border-gray-200 pl-2 py-3 w-full md:w-auto lg:w-full;
  :deep(& > div) {
    @apply gap-y-1;
  }

  :deep(& > div span) {
    @apply text-xs1;
  }

  :deep(& > div span:nth-child(2)) {
    @apply text-black text-sm leading-snug;
  }
}

.multiselect-label {
  @apply absolute text-gray-300 z-[10] whitespace-nowrap overflow-hidden;
  width: calc(100% - 8px);
}
</style>
