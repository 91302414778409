<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

defineProps({
  daysLeft: { type: Number },
  procurationDeclined: { type: Boolean },
  procurationNumber: { type: String },
  procurationDate: { type: String },
  procurationDeclineReason: { type: String }
})

const route = useRoute()

const showLink = computed(() => {
  const name = route.name
  return name !== 'company.procuration'
})
</script>

<template>
  <div class="mt-5 w-full order-1">
    <div class="warning-procuration">
      <img class="w-6 h-6 mr-3" src="/icons/error.svg" alt="Ошибка" />
      <div>
        <slot>
          <p class="font-medium text-base text-error mb-2">Внимание!</p>
          <p class="text-sm leading-5">
            <span v-if="!procurationDeclined && daysLeft !== 0">
              Доверенность на Представителя компании заканчивается в ближайшие {{ daysLeft }} дней.
            </span>
            <span v-else-if="!procurationDeclined && daysLeft === 0"
              >Доверенность на Представителя компании заканчивается <b>сегодня. </b>
            </span>
            <span v-else>
              Доводим до вашего сведения. что доверенность
              <span class="font-medium"> {{ procurationNumber }}</span
              >, загруженная от <span class="font-medium">{{ procurationDate }}</span> была отклонена по причине
              <span class="font-medium">{{ procurationDeclineReason }}</span
              >. Вы можете повторно загрузить доверенность.
            </span>
            <router-link
              v-if="showLink"
              class="underline hover:no-underline text-accent"
              :to="{
                name: 'company.procuration'
              }"
            >
              Загрузите новую доверенность
            </router-link>
          </p>
        </slot>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.warning-procuration {
  @apply bg-white max-w-fit rounded-lg flex py-3 px-3 shadow-main;
}
</style>
