<script setup lang="ts">
import { ref, watch } from 'vue'
import { API } from '@/utils/API'
import { formatPrice } from '@/utils/helpers'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import Loader from '@/components/Loader/Loader.vue'
import { UInput, UISelect, UButton, UTable, USwitch } from 'unit-uikit'
import { useRouter } from 'vue-router'
import { useUiStore } from '@/stores/ui'
import { useProjectsStore } from '@/stores/projects'
import { storeToRefs } from 'pinia'
import axios from 'axios'

const projectsStore = useProjectsStore()
const { projectOptions, projects } = storeToRefs(projectsStore)

const uiStore = useUiStore()
const loading = ref(true)
const showArchive = ref(false)
const selectedProject = ref('allProjects')
const filterField = ref('')
const router = useRouter()

const getProjects = async () => {
  loading.value = true
  const queryString = showArchive.value ? '?archive=true' : ''
  await projectsStore.fetchProjects(true, queryString)
  loading.value = false
}

getProjects()

watch(showArchive, () => {
  getProjects()
})

const closeEditMenu = () => {
  projects.value = projects.value.map((project: any) => {
    project.showEditMenu = false
    return project
  })
}

const openEditMenu = (id: number | string) => {
  projects.value = projects.value.map((prj: any) => {
    if (id + '' === prj.id + '') {
      prj.showEditMenu = !prj.showEditMenu
    } else {
      prj.showEditMenu = false
    }
    return prj
  })
}

const filterObjects = (id: any) => {
  uiStore.filters.project = id
  router.push('/projects/objects/list')
}

const addProject = () => {
  router.push('/projects/add')
}

const getStatus = (project: any) => {
  const status = project?.status
  if (status === 'archive') {
    return 'В архиве'
  }
  if (!project?.object_set?.length) {
    return 'Неактивен'
  }
  const activeObject = project?.object_set.find((item: any) => {
    return item?.status === 'active'
  })

  if (activeObject) {
    return 'Активен'
  }

  return 'Неактивен'
}

const getStatusClass = (project: any) => {
  const status = project?.status
  if (status === 'archive') {
    return 'status__inactive'
  }
  if (!project?.object_set?.length) {
    return 'status__inactive'
  }
  const activeObject = project?.object_set.find((item: any) => {
    return item?.status === 'active'
  })

  if (activeObject) {
    return 'status__active'
  }

  return 'status__inactive'
}

const setStatus = (projectStatus: string, id: string | number) => {
  axios
    .patch(API.CHANGE_PROJECT_STATUS(id), { status: projectStatus === 'active' ? 'archive' : 'active' })
    .then(() => {
      getProjects()
    })
    .catch((e: any) => {
      console.error(e)
    })
}

const getFilteredProjects = (projects: any) => {
  if (selectedProject.value && selectedProject.value !== 'allProjects') {
    return projects
      .filter((project: { id: [string, number] }) => {
        return project.id + '' === selectedProject.value + ''
      })
      .filter((i: { name: string }) => i.name.toLowerCase().includes(filterField.value.toLowerCase()))
  }
  return projects.filter((i: { name: string }) => i.name.toLowerCase().includes(filterField.value.toLowerCase()))
}
</script>

<template>
  <Loader v-if="loading" />
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>
  <div class="flex flex-wrap gap-3 md:flex-nowrap my-5">
    <UInput
      class="!w-full md:!w-auto"
      searchInput
      placeholder="Поиск"
      :value="filterField"
      v-model="filterField"
      :disabled="!projects.length"
    />
    <UISelect
      class="!w-full md:!w-auto"
      id="projectSelector"
      label="Проект"
      unselectedLabel="Все проекты"
      v-model="selectedProject"
      :value="selectedProject"
      :options="projectOptions"
      :disabled="!projects.length"
    />
    <USwitch
      class="!w-full md:!w-auto !border-gray-200"
      v-model="showArchive"
      id="archiveViewSwitcher"
      labelText="Архив"
      labelON="Скрыть"
      labelOFF="Показать"
    />
  </div>
  <EmptyPagePlaceholder pageType="projects" title="Проекты" v-if="!projects.length && !loading" />
  <template v-else>
    <UTable v-if="getFilteredProjects(projects).length && projects.length">
      <template #thead>
        <tr>
          <th class="w-[120px]">Название</th>
          <th class="w-[180px]">Бюджет</th>
          <th class="w-[100px]">Объектов</th>
          <th class="w-[130px]">Статус</th>
          <th class="w-[60px]"></th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="project in getFilteredProjects(projects)" :key="project.id" class="whitespace-nowrap">
          <td class="link-ceil" @click="filterObjects(project.id)">
            <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ project.name }}</span>
          </td>
          <td class="whitespace-nowrap">{{ formatPrice(project.budget) }}&nbsp;₽</td>
          <td>{{ project?.object_set?.length }}</td>
          <td :class="getStatusClass(project)">
            {{ getStatus(project) }}
          </td>
          <td class="!overflow-visible">
            <button type="button" class="edit-btn" @click="openEditMenu(project.id)">
              <img class="" src="/icons/extra.svg" alt="Редактировать" />
              <div v-if="project.showEditMenu" class="edit-menu">
                <router-link :to="`/projects/edit/${project.id}`">
                  <button class="btn-transparent">Редактировать</button>
                </router-link>
                <button v-if="project.status !== 'archive'" @click="setStatus(project.status, project.id)" class="btn-transparent">
                  В архив
                </button>
                <button v-if="project.status !== 'active'" @click="setStatus(project.status, project.id)" class="btn-transparent">
                  В активные
                </button>
              </div>
            </button>
          </td>
        </tr>
      </template>
    </UTable>
    <span v-else class="bg-white block p-4">Нет данных</span>

    <div class="w-full flex justify-end mt-3">
      <UButton label="Добавить" @click.prevent="addProject" />
    </div>
    <router-view />
  </template>
</template>

<style lang="postcss" scoped>
.link-ceil:hover {
  @apply underline text-accent cursor-pointer;
}

.status {
  &__active {
    @apply !text-success;
  }

  &__inactive {
    @apply !text-error;
  }
}

.edit-menu {
  @apply flex flex-col items-start top-full right-0 translate-x-0 translate-y-0;
}

td,
th {
  @apply overflow-hidden text-ellipsis px-[6px];
}

:deep(table) {
  @apply table-fixed;
}
</style>
