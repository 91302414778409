import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import OrdersService from '@/services/orders.service'

export const useOrderStore = defineStore('order', () => {
  const orderRegisterName = ref('')
  const ordersRegister = ref([])

  const targetProject = ref(null) as Ref<null | string>
  const targetObject = ref(null) as Ref<null | string>
  const duplicateId = ref(null) as Ref<null | string>

  function $reset() {
    orderRegisterName.value = ''
    ordersRegister.value = []
    targetProject.value = null
    targetObject.value = null
    duplicateId.value = null
  }

  async function fetchOrder(id: string) {
    try {
      if (!id) {
        throw new Error('Не указан id заказа')
      }
      const result = await OrdersService.getOrder(id)
      return result || 'error'
    } catch (error) {
      return 'error'
    }
  }

  return {
    ordersRegister,
    orderRegisterName,
    targetProject,
    targetObject,
    duplicateId,
    fetchOrder,
    $reset
  }
})
