<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useOrderStore } from '@/stores/order'
import { useCompanyStore } from '@/stores/company'
import { storeToRefs } from 'pinia'

import Loader from '@/components/Loader/Loader.vue'
import { UCard, UTabs, UButton, UModal, UInput, UTextarea } from 'unit-uikit'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import moment from 'moment'

import { API } from '@/utils/API'
import axios from 'axios'

import { formatPrice } from '@/utils/helpers'

const authStore = useAuthStore()
const orderStore = useOrderStore()
const companyStore = useCompanyStore()
companyStore.fetcCompanyBalance()

const { depositMoney, reservedMoney, freeMoney } = storeToRefs(companyStore)

const showModal = ref(false)
const failedcreate = ref(false)

const toggleModal = () => {
  showModal.value = !showModal.value
}

const cancelModal = () => {
  showModal.value = false
  price.value = ''
}

function formatDateForQueryString(date: any) {
  if (date) {
    const dateArr = date.split('.')
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
  return ''
}

const _date = moment(new Date()).format('DD.MM.YYYY')

const price = ref('')
const accountDate = ref(_date)
const accountPurpose = ref(
  'Пополнение баланса Площадки "UNIT" согласно Правилам электронной Площадки "UNIT" редакции 2.0 от 01.05.2023 (https://theunit.ru/)'
)

const route = useRoute()

const getRouterPathForTitle = computed(() => {
  return route?.fullPath.includes('/finance/order/')
})

const isAccountsPage = computed(() => {
  const name = route?.name
  return name === 'finance.accounts'
})

const isDDSPage = computed(() => {
  const name = route?.name
  return name === 'finance.dds'
})

const tabs = [
  {
    routeName: 'finance.orders',
    label: 'Оплаты'
  },
  {
    routeName: 'finance.history',
    label: 'История операций'
  },
  {
    routeName: 'finance.archive',
    label: 'Архив выплат'
  },
  {
    routeName: 'finance.accounts',
    label: 'Пополнить баланс'
  },
  {
    routeName: 'finance.dds',
    label: 'ДДС'
  }
]

const routerKey = ref(Date.now())
const accountCreating = ref(false)

const createBillingAccount = () => {
  if (accountCreating.value) {
    return
  }

  accountCreating.value = true

  const amount = price.value?.trim().replaceAll(' ', '').replaceAll(',', '.') || ''
  if (amount && accountPurpose.value) {
    const detailsId = accountDetails.value[0] && accountDetails.value[0].id

    const payload = {
      amount: amount,
      status: 'not_paid',
      objects_list: [],
      date: formatDateForQueryString(accountDate.value),
      purpose_of_payment: accountPurpose.value,
      basis_of_payment: accountPurpose.value,
      account_details: detailsId || null,
      type: 'payment_order'
    }
    axios
      .post(API.CREATE_BILLING_ACCOUNT, payload)
      .then(() => {
        accountCreating.value = false
        showModal.value = false
        routerKey.value = Date.now()
        price.value = ''
      })
      .catch(() => {
        routerKey.value = Date.now()
        failedcreate.value = true
        accountCreating.value = false
      })
    showModal.value = false
  } else {
    accountCreating.value = false
  }
}

const accountDetails = ref([]) as any
axios
  .get(API.GET_BILLING_ACCOUNT_DETAILS)
  .then((res: any) => {
    const data = res.data
    accountDetails.value = data
  })
  .catch()

const companyName = ref('')
const orderName = ref('')

const updateOrderName = async (id: string) => {
  const _order = await orderStore.fetchOrder(id)
  orderName.value = _order.name || ''
}

const _companyId = authStore.payload?.company_id
if (_companyId) {
  axios
    .get(API.GET_COMPANY_DETAILS(_companyId))
    .then((response) => {
      const { name } = response.data
      companyName.value = name || ''
    })
    .catch((e) => {
      console.error(e)
    })
}

const isNotFullAccountData = computed(() => {
  const _price = parseFloat(price.value?.trim().replaceAll(' ', '').replaceAll(',', '.') || '')
  const formIsNotComplete = !_price || !accountPurpose.value || accountDate.value?.length !== 10
  return formIsNotComplete || accountCreating.value
})

watch(
  () => route?.params?.id,
  () => {
    const id = route?.params?.id as string
    if (id) {
      updateOrderName(id)
    }
  }
)
</script>

<template>
  <Loader v-if="accountCreating" />

  <div class="order-header flex mb-8" v-if="getRouterPathForTitle">
    <img class="mr-2 cursor-pointer" src="/icons/arrow_left_gray.svg" @click="$router.push('/finance')" />
    <h1 class="order-header__title">Заказ № {{ route.params.id }} / {{ orderName }}</h1>
  </div>

  <main-page-header v-else title="Финансы" class="finance-title">
    <div id="finance-app-header" class="ml-auto"></div>
    <u-button v-if="isAccountsPage" label="Пополнить баланс" size="sm" @click="toggleModal" />
    <p class="order-1 basis-full mt-1 mb-2" v-if="isDDSPage">
      Для подробной информации по расходам обратитесь к выгрузке Финансового отчета.
    </p>
  </main-page-header>

  <ul class="flex gap-2 mb-3">
    <UCard
      :value="`${formatPrice(depositMoney)}&nbsp;₽`"
      label="Депозит компании"
      tooltip="Сумма на счету компании в данный момент времени"
    />
    <UCard
      :value="`${formatPrice(reservedMoney)}&nbsp;₽`"
      label="Резерв всех заказов (в т.ч.налог + %)"
      tooltip="Сумма, на которую создан реестр и распределены неоплаченные заказы"
    />
    <UCard
      :value="`${formatPrice(freeMoney)}&nbsp;₽`"
      label="Свободный остаток"
      tooltip="Сумма, в рамках которой возможно создать реестр и распределить заказы"
    />
  </ul>

  <UTabs :tabs="tabs" class="finance-tabs" />

  <router-view :key="routerKey"></router-view>

  <u-modal :title="`Пополнить баланс ${companyName}`" :show="showModal" @cancel="toggleModal" @success="createBillingAccount">
    <template #content>
      <u-input
        label="Введите сумму пополнения"
        placeholder="000 000,00 ₽"
        mask="valueCurrency"
        :value="price"
        v-model="price"
        :showCurrency="true"
      />
      <u-textarea
        class="max-h-[130px]"
        label="Введите назначение платежа"
        placeholder="Назначение платежа"
        :value="accountPurpose"
        :readonly="true"
      />
      <UInput placeholder="Впишите дату" label="Дата" mask="date" v-model="accountDate" :value="accountDate" />
    </template>
    <template #buttons>
      <div class="flex gap-3 justify-end">
        <UButton label="Отмена" color="secondary" @click="cancelModal" />
        <UButton label="Создать" :disabled="isNotFullAccountData" @click="createBillingAccount" />
      </div>
    </template>
  </u-modal>

  <UModal
    :show="failedcreate"
    title="Извините, что-то пошло не так!"
    cancelLabel="Отмена"
    successLabel="Продолжить работу"
    @cancel="failedcreate = false"
  >
    <template #content>
      <span> Попробуйте повторить попытку позже или свяжитесь со службой поддержки </span>
    </template>
    <template #buttons>
      <UButton label="Продолжить" @click="failedcreate = false" />
    </template>
  </UModal>
</template>

<style lang="postcss" scoped>
.finance-title {
  @apply mb-3;
  :deep(h1) {
    @apply leading-11;
  }
}

.order-header {
  &__title {
    @apply not-italic font-semibold text-2xl text-black;
  }
}

.finance-tabs {
  @apply !flex-shrink-0 overflow-y-hidden overflow-x-auto whitespace-nowrap;
}

.u-card {
  flex-basis: 30%;
}
</style>
