export enum EOrderStatus {
  active = 'active',
  finished = 'finished',
  cancelled = 'cancelled'
}

export enum ESuborderStatus {
  created = 'awaiting_signing',
  active = 'active',
  work = 'in_work',
  check = 'on_check',
  act = 'act_signing',
  payment = 'pending_payment',
  finished = 'finished',
  dispute = 'open_dispute',
  cancelled = 'cancelled'
}

export enum ETransactionsStatus {
  not_signed = 'act_not_signed',
  awaiting = 'awaiting_payment',
  paid = 'paid',
  cancelled = 'cancelled',
  sent = 'sent',
  error = 'error',
  rejected = 'rejected',
  processing = 'procesisng'
}

export enum EDocsStatus {
  converting_error = 'converting_error',
  exists = 'exists',
  signed = 'signed',
  not_signed = 'not_signed',
  active = 'active',
  finished = 'finished',
  terminated = 'terminated',
  declined_by_self_employee = 'declined_by_self_employee',
  filed = 'filed',
  rejected = 'rejected',
  revoked = 'revoked'
}

export enum EAccountTypes {
  ip = 'ip',
  ooo = '000',
  customer = 'customer',
  worker = 'worker',
  staff = 'company_staff'
}

export enum EProcurationStatus {
  active = 'active',
  expired = 'expired',
  check = 'on_check',
  declined = 'declined',
  expiring = 'expiring' // calculated status if doc will be expired in 30 days
}

export const PROCURATION_STATUS_LABELS = {
  [EProcurationStatus.active]: 'Утверждено',
  [EProcurationStatus.expired]: 'Истекла',
  [EProcurationStatus.check]: 'На рассмотрении',
  [EProcurationStatus.declined]: 'Отклонено',
  [EProcurationStatus.expiring]: 'Истекает'
}

export enum EReplenishmentAccount {
  not_paid = 'not_paid',
  bank_transfered = 'paid_by_bank_transfer',
  deleted = 'deleted'
}

export enum EPatentStatus {
  valid = 'valid',
  payment = 'on_payment',
  expires = 'expires',
  expired = 'expired',
  invalid = 'invalid',
  procesisng = 'processing'
}

export const REPLENISHMENT_ACCOUNT_LABELS = {
  [EReplenishmentAccount.not_paid]: 'Не оплачено',
  [EReplenishmentAccount.bank_transfered]: 'Оплачено по безналу',
  [EReplenishmentAccount.deleted]: 'Удалён'
}

export type TProcuration = {
  id: string
  procuration_number: string // eslint-disable-line
  procuration_release_date: string // eslint-disable-line
  procuration_exp_date: string // eslint-disable-line
  representative: any // eslint-disable-line
  status: EProcurationStatus
  showEditMenu?: boolean
  procuration: string
  decline_reason: string // eslint-disable-line
}

export type TDrafts = {
  id: number
  company: number
  created: Date
  last_updated: Date
  body: {
    draft_registry?: boolean
    draft_reestrName?: string
    acceptance_certificate_template: string | number
    address: string
    agreement_template: string | number
    description: string
    draft_budget: {
      allWorkersSum: number
      budgetPlusTaxes: string
      fullTaxes: string
      oneWorkerSum: string
      workerWithTax: string
      workersAmount: number
    }
    equipment: []
    finish_date: Date
    start_date: Date
    job_certs_requirements: []
    monthly_limit_with_tax: number
    monthly_limit_without_tax: number
    name: string
    object: string
    omvd: string
    order_template: string | number
    photo_after_completion: boolean
    photo_comments: string
    project: string
    quantity: string
    selectedCertificates: []
    special_requirements: string
    suborder: []
    unit_type: string
    withholding_tax: boolean
  }
}
