const URL_CLEAR = process.env.VUE_APP_URL
const URL = URL_CLEAR + '/api/v1'
export class API {
  // Notifications
  /** @method get */
  static readonly NOTIFICATIONS = URL_CLEAR + '/api/notifications'

  /** @method get */
  static readonly VIEWED_NOTIFICATIONS = URL_CLEAR + '/api/notifications/viewed/'

  /** @method patch */
  static READ_NOTIFICATION(id: number | string) {
    return `${URL_CLEAR}/api/notifications/${id}/`
  }

  /** @method post */
  static READ_ALL_NOTIFICATIONS() {
    return `${URL_CLEAR}/api/notifications/view_all/`
  }

  /** @method get */ /** @method post */
  static readonly GET_DOCUMENTS_NOTIFICATIONS = `${URL}/documents/notifications/`

  /** @method patch */
  static PATCH_DOCUMENTS_NOTIFICATIONS(id: number | string) {
    return `${URL}/documents/notifications/${id}/patch_application/`
  }

  /** @method patch */
  static readonly PATCH_DOCUMENTS = `${URL}/documents/notifications/patch_notifications/`

  /** @method get */
  static readonly SET_DOCS_EXPORT_ALL = `${URL}/documents/notifications/unload_notifications/`

  /** @method post */
  static readonly DOC_NOTIFICATIONS_AGREEMENT_EXIST = `${URL}/documents/notifications/check_agreement_exist/`

  // Auth
  /** @method get */
  static readonly REFRESH_TOKEN = URL + '/users/refresh/'

  /** @method post @method put */
  static readonly SIGNUP = URL + '/users/signup/'

  /** @method post */
  static readonly SIGNUP_CHECK_CODE = URL + '/users/signup/check_code'

  /** @method post @method put */
  static readonly SIGNUP_OTP = URL + '/users/signup/otp'

  /** @method post */
  static readonly SIGNUP_ENTITY = URL + '/users/signup/signup_entity/'

  /** @method post */
  static readonly CHECK_INN_OGRN = URL + '/users/signup/check_entity/'

  /** @method post */
  static readonly AUTH_USERS = URL + '/users/auth/'

  /** @method post */
  static readonly AUTH_USERS_OTP = URL + '/users/auth/otp/'

  /** @method get @method post */
  static readonly AUTH_CHOOSECOMPANY = URL + '/users/auth/choosecompany/'

  // Project
  /** @method post @method put */
  static readonly ADD_PROJECT = URL + '/project/'

  /** @method get */
  static readonly GET_PROJECT = URL + '/project/'

  /** @method get */
  static GET_SINGLE_PROJECT(id: number | string) {
    return `${URL}/project/${id}`
  }

  /** @method put */ /** @method patch */
  static EDIT_SINGLE_PROJECT(id: number | string) {
    return `${URL}/project/${id}/`
  }

  /** @method patch */
  static CHANGE_PROJECT_STATUS(id: number | string) {
    return `${URL}/project/${id}/change_status/`
  }

  // Object
  /** @method patch */
  static CHANGE_OBJECT_STATUS(id: number | string) {
    return `${URL}/object/${id}/change_status/`
  }

  /** @method get */ /** @method post */
  static readonly GET_OBJECT = URL + '/object/'

  /** @method get */ /** @method patch */ /** @method put */ /** @method delete */
  static SINGLE_OBJECT(id: number | string) {
    return `${URL}/object/${id}/`
  }

  // Finance
  /** @method get */
  static readonly GET_BILLS_LIST = URL + '/finance/bill/'

  /** @method get */
  static GET_BILL_INFO(id: number | string) {
    return `${URL}/finance/bill/${id}/`
  }

  /** @method get */
  static readonly GET_BILLS_BALANCE = URL + '/finance/bill/get_balance/'

  /** @method get */
  static readonly GET_TRANSACTIONS_ARCHIVE = URL + '/finance/archive/'

  /** @method get */
  static readonly GET_TRANSACTIONS_HISTORY = URL + '/finance/transactions/'

  /** @method get */ /** @method post */
  static readonly GET_DDS_LIST = URL + '/finance/dds/'

  /** @method get */
  static readonly SENT_BILL_SMS = `${URL}/finance/bill/send_sms/`

  /** @method post */
  static readonly PAY_BILL = `${URL}/finance/bill/pay/`

  /** @method post */
  static PAY_BILL_REPLY(id: number | string) {
    return `${URL}/finance/bill/${id}/recharge_bill/`
  }

  static CANCEL_BILL(id: number | string) {
    return `${URL}/finance/bill/${id}/cancel/`
  }

  /**  @method post */
  static CREATE_BILLING_ACCOUNT = `${URL}/finance/replenishment_account/`

  /**  @method get */
  static GET_BILLING_ACCOUNTS = `${URL}/finance/replenishment_account/`

  /**  @method get */
  static GET_BILLING_ACCOUNT_DETAILS = `${URL}/finance/replenishment_account_details/`

  /** @method patch */
  static UPDATE_BILLING_ACCOUNT(id: number | string) {
    return `${URL}/finance/replenishment_account/${id}/`
  }

  /** @method GET */
  static GET_BILLING_ACCOUNT_FILE(id: number | string) {
    return `${URL}/finance/replenishment_account/${id}/account_file/`
  }

  // Order
  /** @method get */
  static readonly GET_ORDER = URL + '/order/'

  /** @method post */
  static readonly ADD_ORDER = URL + '/order/'

  /** @method post */
  static readonly CHECK_SUBORDER_REGISTRY = URL + '/order/check_suborder_registry/'

  /** @method get */
  static readonly GET_ORDER_PREPARATION = URL + '/order/preparation_data/'

  /** @method get */
  static GET_SINGLE_ORDER(id: number | string) {
    return `${URL}/order/${id}`
  }

  /** @method get */
  static GET_SINGLE_ORDER_BILLS(id: number | string) {
    return `${URL}/order/${id}/order_bills`
  }

  /** @method patch */
  static DELETE_SINGLE_ORDER(id: number | string) {
    return `${URL}/order/${id}/`
  }

  /** @method get */
  static GET_ORDER_DOCUMENTS(orderId: number | string) {
    return `${URL}/order/${orderId}/order_documents/`
  }

  /** @method post */
  static POST_SIGN_DOCS(orderId: number | string) {
    return `${URL}/order/${orderId}/sign_acts/`
  }

  /** @method post */
  static REVOKE_DOC(id: number | string) {
    return `${URL}/documents/upload_to_sign/${id}/revoke/`
  }

  /** @method post */
  static readonly SIGN_DOCS = URL + '/documents/upload_to_sign/'

  static GET_FINANCIAL_INFO(id: number | string) {
    return `${URL}/order/${id}/get_financial_info/`
  }

  // 1ый степ биллинга
  /** @method get */ /** @method post */
  static readonly CHECK_GENERAL_ORDER = URL + '/order/check_general_order_info/'

  // 1ый степ биллинга
  /** @method post */
  static readonly CHECK_BUDGET = `${URL}/order/check_budget/`

  /** @method get */ /** @method post */
  static readonly ORDER_PREINVITED_WORKERS = URL + '/users/worker/'

  /** @method POST */ /** @method post */
  static readonly ADD_COMPANY_STAFF = URL + '/users/signup/signup_company_staff/'

  /** @method POST */ /** @method post */
  static readonly GET_COMPANY_STAFF = URL + '/customer/company_staff/'

  /** @method POST */ /** @method post */
  static readonly GET_USER_INFO = URL + '/users/me/'

  /** @method POST */ /** @method post */
  static readonly GET_COMPANY_STAFF_INVITES = URL + '/customer/company_staff/list_of_invites/'

  /** @method get */
  static COMPANY_STAFF_RESEND_SMS(id: number | string) {
    return `${URL}/customer/company_staff/${id}/resend_sms/`
  }

  /** @method delete */
  static CANCEL_STAFF_INVITATION(id: number | string) {
    return `${URL}/customer/company_staff/${id}/cancel_invite/`
  }

  /** @method patch */
  static DISABLED_COMPANY_STAFF(id: number | string) {
    return `${URL}/customer/company_staff/${id}/disable_company_staff/`
  }

  /** @method get */ /** @method post */
  static readonly AVAILABLE_WORKERS = URL + '/users/worker/available_workers/'

  /** @method get patch */ /** @method post */
  static readonly FAVORITE_WORKERS = URL + '/users/worker/favorite_workers'

  /** @method post */
  static FAVORITE_WORKERS_ADD(id: number | string) {
    return `${URL}/users/worker/favorite_workers/${id}/add`
  }

  /** @method delete */
  static FAVORITE_WORKERS_DELETE(id: number | string) {
    return `${URL}/users/worker/favorite_workers/${id}/delete`
  }

  /** @method get */ /** @method post */
  static readonly WORKED_BEFORE = URL + '/users/worker/worked_before/'

  /** @method get */ /** @method post */
  static readonly WORKER_REESTR = URL + '/users/worker/registry/workers/'

  /** @method get post */ /** @method post */
  static readonly WORKER_INVITE = URL + '/users/worker/invite'

  /** @method delete */
  static WORKER_INVITE_DELETE(id: number | string) {
    return `${URL}/users/worker/invite/${id}`
  }

  static readonly PERSONAL_INVITE = URL + '/customer/company_staff/create_invites/'

  /** @method get */ /** @method post */
  static readonly BLACK_LIST = URL + '/users/worker/black_list'

  /** @method post */
  static BLACK_LIST_ADD(id: number | string) {
    return `${URL}/users/worker/black_list/${id}/add`
  }

  /** @method delete */
  static BLACK_LIST_DELETE(id: number | string) {
    return `${URL}/users/worker/black_list/${id}/delete`
  }

  /** @method get */ /** @method put */ /** @method patch */
  static SINGLE_WORKER(id: number | string) {
    return `${URL}/users/worker/${id}/`
  }

  // Suborder
  /** @method get */
  static readonly GET_SUBORDER_LIST = URL + '/suborder/'

  /** @method get */
  static GET_SUBORDER_BY_ORDER(id: number | string) {
    return `${URL}/suborder/?order=${id}`
  }

  /** @method POST */
  static BACK_TO_WORK_ORDER(id: number | string) {
    return `${URL}/suborder/?order=${id}`
  }

  /** @method patch */
  static CANCEL_SUBORDER(subOrderId: number | string) {
    return `${URL}/suborder/${subOrderId}/cancel/`
  }

  /** @method patch */
  static CANCEL_SUBORDER_ACT(subOrderId: number | string) {
    return `${URL}/suborder/${subOrderId}/cancel_act/`
  }

  /** @method post */
  static UPDATE_SUBORDER(subOrderId: number | string) {
    return `${URL}/suborder/${subOrderId}/return_change_suborder/`
  }

  /** @method get */
  static GET_FILE(file: string) {
    return `${URL_CLEAR}${file}`
  }

  // Documents
  // /** @method get */
  // static GENERATE_NEW_DOCUMENTS(docId: number | string) {
  //   return `${URL}/documents/document/${docId}/regenerate/`
  // }

  /** @method post */
  static REMOVE_DOCUMENT(docId: number | string) {
    return `${URL}/documents/document/${docId}/change_status/`
  }

  /** @method get */
  static RECREATE_DOCS_LINK() {
    return `${URL}/documents/document/recreate_unload/`
  }

  /** @method get */
  static readonly GET_ALL_DOCS = `${URL}/documents/document/`

  /** @method get */
  static readonly GET_DOCS_EXPORT = `${URL}/documents/documentunloading/`

  /** @method get */
  static readonly SET_DOCS_EXPORT = `${URL}/documents/document/create_unload/`

  /** @method get */
  static readonly FINANCIAL_INFO = `${URL}/users/questionary/financial_info`

  /** @method post */
  static readonly FINANCIAL_UNLOADING = `${URL}/documents/documentunloading/by_bills/`

  /** @method get @method put */
  static PROFILE_WORKER_DOCUMENTS(id: number | string) {
    return `${URL}/worker/${id}/documents/`
  }

  /** @method get */
  static GET_COMPANY_DETAILS(id: number | string) {
    return `${URL}/users/questionary/company_details/${id}/`
  }

  /** @method get */ /** @method post */
  static readonly GET_WORK_TYPES = URL + '/work_types'

  /** @method get */
  static readonly GET_UNIT_TYPES = URL + '/unit_types'

  /** @method get */
  static readonly SPECIALIZATIONS = URL + '/specializations/'

  /** @method get @method put */
  static CHECK_INN_STATUS(id: number | string) {
    return `${URL}/users/worker/${id}/check_self_employee_status/`
  }

  /** @method get @method put */
  static PROFILE_WORKER(id: number | string) {
    return `${URL}/worker/${id}/`
  }

  /** @method get @method put */
  static PROFILE_WORKER_TASKS(id: number | string) {
    return `${URL}/worker/${id}/suborders/`
  }

  /** @method get @method put */
  static PROFILE_WORKER_TRANSACTIONS(id: number | string) {
    return `${URL}/worker/${id}/transaction_history/`
  }

  /** @method get @method put */
  static PROFILE_WORKER_PAID(id: number | string) {
    return `${URL}/worker/${id}/paid/`
  }

  /** @method get */
  static readonly SET_WORKER_DOCS_EXPORT = `${URL}/worker/create_unload/`

  /** @method post */
  static readonly UPLOAD_WORKERS = `${URL}/upload_workers/`

  /** @method get @method post */
  static readonly GET_TRUSTED_PERSONS = `${URL}/users/trusted_persons/`

  /** @method get */
  static GET_TRUSTED_PERSON(id: number | string) {
    return `${URL}/users/trusted_persons/${id}`
  }

  /**  @method put @method patch @method delete */
  static TRUSTED_PERSON(id: number | string) {
    return `${URL}/users/trusted_persons/${id}/`
  }

  /** @method get */
  static readonly GET_COMPANY_PROCURATIONS = `${URL}/users/procurations/`

  /** @method get */
  static readonly GET_ACTIVE_PROCURATIONS = `${URL}/users/procurations/active/`

  /** @method get */
  static readonly GET_EXPIRING_PROCURATIONS = `${URL}/users/procurations/expiring/`

  /** @method get */
  static GET_PROCURATION(id: number | string) {
    return `${URL}/users/procurations/${id}/`
  }

  // Drafts
  /** @method get */
  static readonly GET_ALL_DRAFTS = `${URL}/order_drafts/`

  /** @method get @method patch @method delete */
  static CRUD_DRAFT(id: number | string) {
    return `${URL}/order_drafts/${id}/`
  }

  /** @method post */
  static readonly MASS_DELETE_DRAFTS = `${URL}/order_drafts/mass_delete/`

  /** @method post */
  static readonly CREATE_DRAFT = `${URL}/order_drafts/`

  /** @method get, post */
  static readonly PATENTS = `${URL}/patents/`

  /** @method get */
  static PATENT(id: string) {
    return `${URL}/patents/${id}/`
  }

  /** @method patch */
  static PATCH_PATENT(id: number) {
    return `${URL}/patents/${id}/`
  }

  /** @method get, oost, patch */
  static PATENT_RECEIPTS(id: number) {
    return `${URL}/patents/${id}/checks/`
  }

  /** @method get, patch, delete */
  static PATENT_RECEIPT(pId: number, rId: number) {
    return `${URL}/patents/${pId}/checks/${rId}/`
  }

  /** @method post */
  static readonly UPLOAD_PATENTS_FILE = URL + '/patents/upload_xlsx/'
}
