<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useCompanyStore } from '@/stores/company'
import { storeToRefs } from 'pinia'

import { UButton } from 'unit-uikit'

const router = useRouter()
const companyStore = useCompanyStore()
const { isProcurationEnded } = storeToRefs(companyStore)

const props = defineProps({
  pageType: {
    type: String,
    default: '',
    required: true
  },
  title: {
    type: String,
    default: ''
  }
})

const goToRequiredPage = () => {
  if (props.pageType === 'projects') {
    router.push('/projects/add')
  }
  if (props.pageType === 'objects') {
    router.push('/projects/objects/add')
  }
  if (props.pageType === 'workers') {
    router.push('/workers/add')
  }
  if (props.pageType === 'staff') {
    router.push('/personal/add')
  }
  if (props.pageType === 'orders') {
    router.push('/orders/add')
  }
}

const imageSrc = computed(() => {
  switch (props.pageType) {
    case 'orders':
      return '/img/placeholders/order-placeholder.svg'
    case 'workers':
      return '/img/placeholders/workers-placeholder.svg'
    case 'documents':
      return '/img/placeholders/documents-placeholder.svg'
    case 'finance':
      return '/img/placeholders/finance-placeholder.svg'
    case 'objects':
      return '/img/placeholders/objects-placeholder.svg'
    case 'projects':
      return '/img/placeholders/projects-placeholder.svg'
    case 'omvd':
      return '/img/placeholders/omvd-placeholder.svg'
    case 'staff':
      return '/img/placeholders/staff-placeholder.svg'
  }
  return '/img/placeholders/documents-placeholder.png'
})

const description = computed(() => {
  switch (props.pageType) {
    case 'orders':
      return 'Это страница активных заказов. Но пока у вас их нет. Чтобы создать заказ нажмите на кнопку ниже.'
    case 'workers':
      return 'В этом разделе можно управлять своими исполнителями, но пока у вас их нет. Чтобы начать работы с исполнителями нажмите кнопку ниже'
    case 'documents':
      return 'В этом разделе отображаются все подписанные вами и исполнителями документы, но пока у вас их нет. Документы появятся сразу после их подписания в разделе “Заказы”'
    case 'finance':
      return 'В этом разделе отображаются актуальные финансовые операции по каждому заказу и вся история денежных операций, но пока их у вас нет. Счета на оплату появятся сразу после генерации актов о выполненных работах'
    case 'objects':
      return 'Для начала работы и размещения заказа Вам необходимо создать ваш первый проект и объект'
    case 'projects':
      return 'Для начала работы и размещения заказа Вам необходимо создать ваш первый проект и объект'
    case 'omvd':
      return 'В разделе отображаются все уведомления МВД, но пока у вас их нет'
    case 'staff':
      return 'В разделе можно управлять своими сотрудниками, но пока у вас их нет. Чтобы начать работу с исполнителями, нажмите кнопку ниже'
    default:
      return 'Нет данных'
  }
})

const buttonLabel = computed(() => {
  switch (props.pageType) {
    case 'orders':
      return 'Создать заказ'
    case 'workers':
      return 'Добавить исполнителя'
    case 'objects':
      return 'Создать объект'
    case 'projects':
      return 'Создать проект'
    case 'omvd':
      return 'Добавить исполнителя'
    case 'staff':
      return 'Пригласить сотрудника'
    case 'documents':
    case 'finance':
    default:
      return 'нет'
  }
})

const showBtns = computed(() => {
  switch (props.pageType) {
    case 'orders':
    case 'workers':
    case 'objects':
    case 'projects':
    case 'staff':
      return true
    default:
      return false
  }
})
</script>

<template>
  <div v-if="pageType !== 'noData'" class="empty-placeholder__wrapper gap-4">
    <div class="flex-shrink overflow-hidden">
      <img :src="imageSrc" alt="empty placeholder" />
    </div>
    <h1>{{ title }}</h1>
    <p class="mb-6">{{ description }}</p>
    <div class="flex" v-if="showBtns">
      <UButton
        data-add-order="true"
        :disabled="isProcurationEnded && pageType === 'orders'"
        :label="buttonLabel"
        @click="goToRequiredPage"
      />
      <UButton
        data-add-reg-order="true"
        :disabled="isProcurationEnded"
        v-if="pageType === 'orders'"
        label="Загрузка реестра заказов"
        class="ml-3"
        @click="$emit('onClickReg')"
      />
    </div>
  </div>
  <div v-else-if="pageType === 'noData'">
    <p class="bg-white w-full p-4">Нет данных</p>
  </div>
</template>

<style lang="postcss" scoped>
.empty-placeholder__wrapper {
  @apply flex flex-col justify-center items-center h-full;

  img {
    @apply h-full;
    max-width: 420px;
  }

  h1 {
    @apply not-italic font-medium text-2xl leading-snug text-black;
  }

  p {
    @apply text-black max-w-[650px] not-italic font-medium text-base leading-snug text-center tracking-title;
  }
}
</style>
