<template class="relative">
  <main-page-header :title="title" class="page-title">
    <UButton label="Подписать и отправить" class="ml-auto mr-4" @click="uploadDocs" />
    <UButton label="Выгрузить" class="mr-4" @click="createUploads" v-if="isShowUploadBtn" />
  </main-page-header>
  <UTabs :tabs="tabs" />
  <router-view></router-view>
</template>

<script setup lang="ts">
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useUiStore } from '@/stores/ui'
import { UButton, UTabs } from 'unit-uikit'

const uiStore = useUiStore()
const route = useRoute()

const title = 'Документы'

const tabs = [
  {
    routeName: 'documents.all',
    label: 'Документы'
  },
  {
    routeName: 'documents.history',
    label: 'Истории экспорта'
  }
]

const uploadDocs = () => {
  uiStore.showSignUploadDocsPopup = true
}
const createUploads = () => {
  uiStore.showUploadsPopup = true
}

const isShowUploadBtn = computed(() => {
  const name = route.name
  return name === 'documents.all'
})
</script>
