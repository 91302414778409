<script setup lang="ts">
import { ref, computed } from 'vue'

import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs } from 'unit-uikit'

import axios from 'axios'
import { API } from '@/utils/API'
import { useRoute } from 'vue-router'

import { useCompanyStore } from '@/stores/company'
import { storeToRefs } from 'pinia'

import { EOrderStatus } from '@/types/api-values'

const route = useRoute()
const ready = ref(false)
const finishedRequests = ref(false)

const companyStore = useCompanyStore()
const { isProcurationEnded } = storeToRefs(companyStore)

axios
  .get(API.GET_ORDER + `?page_size=1&status=${EOrderStatus.finished},${EOrderStatus.cancelled}`)
  .then((response) => {
    finishedRequests.value = !!response.data.count
    ready.value = true
  })
  .catch((e) => {
    ready.value = true
  })

const tabs = computed(() => {
  return [
    {
      routeName: 'orders.list',
      label: 'Активные'
    },
    {
      routeName: finishedRequests.value && ready.value ? 'orders.finished' : null,
      label: 'Архив'
    },
    {
      routeName: !isProcurationEnded.value ? 'orders.drafts' : null,
      label: 'Черновики'
    }
  ]
})
</script>

<template>
  <div>
    <main-page-header title="Реестр заказов" class="page-title"></main-page-header>
  </div>

  <UTabs :tabs="tabs" />

  <router-view :key="route.name"></router-view>
</template>
