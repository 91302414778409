<script setup lang="ts">
import { computed, onMounted, ref, Ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import moment from 'moment'
import debounce from '@/utils/debounce'
import { UButton, UTable, UICheckbox, UISelect, UInput, USwitch, UMultiselect, UTooltip, UModal } from 'unit-uikit'
import ErrorTooltip from '@/components/Tooltips/ErrorTooltip.vue'
import { API } from '@/utils/API'
import axios from 'axios'
import d from '@/dictionary'

import { decimalCount, formatDateForQueryString } from '@/utils/helpers'
import ConfirmSignModal from '@/components/Modals/ConfirmSignModal.vue'
import ChangeReestrSuborderModal from '@/components/Modals/ChangeReestrSuborderModal.vue'
import ReestrBudgetItem from './OrderComponents/ReestrBudgetItem.vue'
import ReestrFio from './OrderComponents/ReestrFio.vue'
import WorkerLimitsTableItem from './OrderComponents/WorkerLimitsTableItem.vue'
import WorkerSumTableItem from './OrderComponents/WorkerSumTableItem.vue'
import TOrder from './types'

function formatPrice(price: any) {
  return (price + '').replaceAll(',', '.').replaceAll(' ', '')
}

const router = useRouter()
const route: any = useRoute()
const orderStore = useOrderStore()
const { ordersRegister, orderRegisterName } = storeToRefs(orderStore)

const ready = ref(false)
const infoToggled = ref(true)
const editableOrder = ref(null) as Ref<TOrder | null>
const createInProcess = ref(false)
const failedcreate = ref(false)
const showConfirmSignModal = ref(false)
const errorMessage = ref('')
const reestrName = ref(orderRegisterName.value || '')
orderRegisterName.value = ''

const ordersList = ref(ordersRegister.value || []) as Ref<TOrder[]>
const checkedOrders = ref([])
const isMaxSuborders = ref(false)

const selectedProject = ref('all')
const projectOptions = ref([
  {
    id: 'all',
    name: 'Показать все'
  }
])

const objectsData = ref([]) as any
const selectedObject = ref('all')
const objectOptions = ref([
  {
    id: 'all',
    name: 'Показать все'
  }
])

const certificateOptions = ref([
  {
    id: '',
    name: 'Нет'
  }
])

const technicRequirments = ref([
  {
    id: '',
    name: 'Нет'
  }
])

const reestrObject = ref({
  draft_ordersList: [],
  draft_reestrName: '',
  draft_registry: true,
  special_requirements: 'Нет',
  photo_after_completion: false,
  withholding_tax: false,
  object: {
    address: ''
  },
  job_certs_requirements: [{ id: '', name: 'Нет' }],
  equipment: [{ id: '', name: 'Нет' }],
  acceptance_certificate_template: {
    id: '',
    file: ''
  },
  agreement_template: {
    id: '',
    file: ''
  },
  order_template: {
    id: '',
    file: ''
  },
  omvd: ''
}) as any

// Draft
const draftID = ref('')

onMounted(() => {
  if (!route?.query?.draftId) {
    axios
      .post(API.CREATE_DRAFT, {
        body: {}
      })
      .then((res) => {
        draftID.value = res.data.id
        ready.value = true
      })
      .catch((error) => {
        console.error(error)
      })
  } else {
    axios
      .get(API.CRUD_DRAFT(route?.query?.draftId))
      .then((res) => {
        reestrObject.value = res.data.body
        ordersList.value = res.data.body?.draft_ordersList
        reestrName.value = res.data.body?.draft_reestrName
        ready.value = true
      })
      .catch((error) => {
        console.error(error)
      })
  }
})

let dataForDraft = ref({})
let ordersListForDraft: any = ref([])

watch(
  reestrObject,
  (currentDataForDraft) => {
    if (ready.value) {
      dataForDraft.value = currentDataForDraft
      debounceSaveDraft()
    }
  },
  { deep: true }
)

watch(
  ordersList,
  (currentOrdersList) => {
    currentOrdersList.length > 100 ? (isMaxSuborders.value = true) : (isMaxSuborders.value = false)
    ordersListForDraft.value = currentOrdersList
    debounceSaveDraft()
  },
  { deep: true }
)

const debounceDelayTime = ref(2000)
const debounceSaveDraft = debounce(() => {
  reestrObject.value.draft_ordersList = ordersListForDraft.value
  reestrObject.value.draft_reestrName = reestrName.value
  axios
    .patch(API.CRUD_DRAFT(draftID.value ? draftID.value : route.query.draftId), {
      body: draftID.value ? dataForDraft.value : reestrObject.value
    })
    .catch((error) => {
      console.error(error)
    })
}, debounceDelayTime.value)

// Draft

const totalBalance = ref(0)
const reserve_balance = ref(0)
const financialInfo = ref({
  unitpay_commission: 0
})
const objectFreeBalance = ref(0)

const getMainInfo = async () => {
  try {
    const { data } = await axios.get(API.GET_BILLS_BALANCE)
    totalBalance.value = data.company_deposit || 0
    reserve_balance.value = data.total_reserve || 0

    const res = await axios.get(API.FINANCIAL_INFO)
    financialInfo.value = res.data
  } catch (e) {
    console.error('error here company details', e)
  }
}

const companyInfo = ref({
  object_budget: 0,
  object_reserve: 0
})

const preparationData = ref({
  monthly_limit_with_tax: 0,
  monthly_limit_without_tax: 0,
  unit_types: []
}) as any

const getPreparationData = () => {
  axios.get(API.GET_ORDER_PREPARATION).then((res) => {
    if (res?.data) {
      preparationData.value = res.data

      projectOptions.value = [
        {
          id: 'all',
          name: 'Показать все'
        },
        ...res.data.main
      ]

      const equipmentRequirments = res.data?.equipment
      if (equipmentRequirments) {
        technicRequirments.value.push(...equipmentRequirments)
      }

      const certificateRequirments = res.data?.job_certs_requirements
      if (certificateRequirments) {
        certificateOptions.value.push(...certificateRequirments)
        if (certificateRequirments.length === 1) {
          reestrObject.value.job_certs_requirements = certificateRequirments[0]
        }
      }

      if (res.data.accpetance_certificate_templates && res.data.accpetance_certificate_templates[0]?.id) {
        reestrObject.value.acceptance_certificate_template.id = res.data.accpetance_certificate_templates[0].id
      }
      if (res.data.accpetance_certificate_templates && res.data.accpetance_certificate_templates[0]?.file) {
        reestrObject.value.acceptance_certificate_template.file = res.data.accpetance_certificate_templates[0].file
      }
      if (res.data.order_templates && res.data.order_templates[0]?.id) {
        reestrObject.value.order_template.id = res.data.order_templates[0].id
      }
      if (res.data.order_templates && res.data.order_templates[0]?.file) {
        reestrObject.value.order_template.file = res.data.order_templates[0].file
      }
      if (res.data.agreement_templates && res.data.agreement_templates[0]?.id) {
        reestrObject.value.agreement_template.id = res.data.agreement_templates[0].id
      }
      if (res.data.agreement_templates && res.data.agreement_templates[0]?.file) {
        reestrObject.value.agreement_template.file = res.data.agreement_templates[0].file
      }
    }
  })
}

const getObjectsData = async () => {
  try {
    const { data } = await axios.get(API.GET_OBJECT)

    objectsData.value = data
    objectOptions.value = [
      {
        id: 'all',
        name: 'Показать все'
      },
      ...data
    ]
  } catch (error) {
    console.error('get object info error', error)
  }
}

const checkSuborderRegistry = () => {
  const suborders = ordersList.value.map((order: any) => {
    const phone = order.data?.phone?.toString().trim().replaceAll(' ', '')
    const inn = order.inn?.toString().trim().replaceAll(' ', '')
    const item = {
      phone: phone?.startsWith('+') ? phone : `+${phone}`,
      inn
    }
    return item
  })

  axios
    .post(API.CHECK_SUBORDER_REGISTRY, { suborders })
    .then((res) => {
      const orders = res?.data?.suborders || []
      const workersIds = new Set()
      orders.forEach((item: any, index: any) => {
        ordersList.value = ordersList.value.map((i: any) => {
          if (i.inn?.toString().trim() === item.inn?.toString().trim()) {
            i.worker_id = item.worker_id

            i.month_limit = item.monthly_limit
            i.month_limit_without_tax = item.monthly_limit_without_tax

            i.checkStatus = item.status
            i.checkErrors = item.errors

            i.activeAgreement = item.agreements_exist
            i.citizenship = item.citizenship

            if (item.worker_id) {
              workersIds.add(item.worker_id)
            }
          }
          return i
        })
      })

      if (workersIds.size) {
        axios
          .post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, { workers: Array.from(workersIds) })
          .then((res) => {
            if (res?.data) {
              const aggrements = res.data.map((item: any) => item.worker_id)
              ordersList.value = ordersList.value.map((item: any) => {
                if (aggrements.includes(item.worker_id)) {
                  item.notificationsAgreementExist = true
                  const docData = res.data.filter((d: any) => item.worker_id === d.worker_id)
                  item.notificationsAgreementData = docData
                }
                return item
              })
            }
            ready.value = true
          })
          .catch((error) => {
            ready.value = true
            console.error(error)
          })
      } else {
        ready.value = true
      }
    })
    .catch(() => {
      ready.value = true
    })
}

getMainInfo()
getObjectsData()
getPreparationData()
checkSuborderRegistry()

const cancelClick = () => {
  router.push({
    name: 'orders.list'
  })
}

const roundWorkAmount = (orderWorkAmount: number | string) => {
  return (+orderWorkAmount).toFixed(2)
}

const deleteLines = () => {
  // eslint-disable-next-line array-callback-return

  ordersList.value = ordersList.value.filter((i: { id: string }) => {
    const id = i.id
    return checkedOrders.value.indexOf(id) === -1
  })

  checkedOrders.value = []
}

const getUnitTypeIdByName = (name: string) => {
  const result = preparationData.value?.unit_types?.find((i: any) => {
    return i.name === name
  })
  return result?.id
}

// addOrderRegister
const confirmSign = () => {
  if (createInProcess.value) {
    return
  }

  createInProcess.value = true

  let allDates: any = []

  const suborder = ordersList.value.map((order: TOrder) => {
    const startDate = order.workPeriod.work_started.split('.').reverse().join('-')
    const finishDate = order.workPeriod.work_ends.split('.').reverse().join('-')
    allDates.push(startDate, finishDate)

    const result = {
      initial_name: order.task,
      description: order.taskDescription,
      initial_price: formatPrice(order.sum),
      initial_quantity: order.workAmount,
      unit_types: getUnitTypeIdByName(order.unitType),
      initial_start_date: formatDateForQueryString(startDate),
      initial_finish_date: formatDateForQueryString(finishDate),
      worker: order.worker_id,
      budget_total: formatPrice(order.sum)
    }
    return result
  })
  allDates = allDates.map((item: any) => {
    return new Date(item).valueOf()
  })
  const startDate = Math.min(...allDates)
  const finishDate = Math.max(...allDates)

  const budget = (+finalSum.value * (1 - financialInfo.value.unitpay_commission)).toFixed(2)

  const payload = {
    suborder,
    confirmed_data: true,
    object: reestrObject.value.object.id,
    address: reestrObject.value.object.address,
    quantity: suborder.length,
    budget,
    name: reestrName.value,
    description: reestrName.value,
    budget_total: finalSum.value,
    withholding_tax: reestrObject.value.withholding_tax,
    photo_after_completion: reestrObject.value.photo_after_completion,
    photo_comments: '',
    special_requirements: reestrObject.value.special_requirements,
    order_type: 'private',
    control_over_workers: true,
    is_registry: true,
    start_date: formatDateForQueryString(startDate),
    finish_date: formatDateForQueryString(finishDate),
    omvd: reestrObject.value.omvd,
    agreement_template: reestrObject.value.agreement_template.id,
    order_template: reestrObject.value.order_template.id,
    acceptance_certificate_template: reestrObject.value.acceptance_certificate_template.id,
    unit_types: 1, // @TODO why 1??
    category: '', //
    technical_documentation: [],
    job_certs_requirements: [] as number[],
    equipment: [] as number[]
  }
  if (reestrObject.value.job_certs_requirements && reestrObject.value.job_certs_requirements.length) {
    payload.job_certs_requirements = reestrObject.value.job_certs_requirements
      .map((job: any) => {
        if (job.id) {
          return +job.id
        } else {
          return +job.value
        }
      })
      .filter((value: any) => value)
  }
  if (reestrObject.value.equipment && reestrObject.value.equipment.length) {
    payload.equipment = reestrObject.value.equipment
      .map((job: any) => {
        if (job.id) {
          return +job.id
        } else {
          return +job.value
        }
      })
      .filter((value: any) => value)
  }
  axios
    .post(API.ADD_ORDER, payload)
    .then(() => {
      createInProcess.value = false
      showConfirmSignModal.value = false

      axios.delete(API.CRUD_DRAFT(draftID.value ? draftID.value : route.query.draftId)).catch((e) => {
        console.error(e)
      })

      router.push({
        name: 'orders.list'
      })
    })
    .catch((e) => {
      createInProcess.value = false
      failedcreate.value = true
      showConfirmSignModal.value = false
      const message = (e.response?.data?.suborder && e.response?.data?.suborder[0]) || ''
      errorMessage.value = message
    })
}

const createOrdersReestr = () => {
  showConfirmSignModal.value = true
}

const checkUnitTypeExist = (unitType: string) => {
  return !!preparationData.value?.unit_types?.find((i: { name: string }) => i.name === unitType)
}

/**
 * return array
 * [0] errors in order record
 * [1, ...] general errors in reestr
 */
const errors = computed(() => {
  if (!ready.value) {
    return []
  }
  let _errors: any = []
  let generalErrors = []
  if (!reestrName.value) {
    generalErrors.push('registerName')
  }

  const _orderErrors = {} as any
  let _orderErrorsFlag = false
  ordersList.value.forEach((item: TOrder) => {
    const _error = []
    if (item.checkStatus === 'incorrect') {
      _orderErrorsFlag = true
      _error.push('checkError')
    }

    if (item.unitType && !checkUnitTypeExist(item.unitType)) {
      _orderErrorsFlag = true
      _error.push('unitTypeDoesntExist')
    }
    if (item.task?.length >= 250 || item.task?.length === 0) {
      _orderErrorsFlag = true
      _error.push('taskLength')
    }
    if (item.taskDescription?.length >= 1000 || item.taskDescription?.length === 0) {
      _orderErrorsFlag = true
      _error.push('descriptionLength')
    }

    const sum = formatPrice(item.sum)

    if (!sum) {
      _orderErrorsFlag = true
      _error.push('emptySum')
    }
    if (sum && decimalCount(sum) > 2) {
      _orderErrorsFlag = true
      _error.push('wrongDecimal')
      // Допустимо значение содержащее не больше двух знаков после запятой
    }
    if (isNaN(+item.workAmount)) {
      _orderErrorsFlag = true
      _error.push('workAmountIncorrectType')
    }
    if (
      !item.workPeriod?.work_started ||
      !item.workPeriod?.work_ends ||
      item.workPeriod?.work_started?.length < 10 ||
      item.workPeriod?.work_ends?.length < 10
    ) {
      _orderErrorsFlag = true
      _error.push('incorrectDate')
    } else {
      const startMoment = item.workPeriod?.work_started.split('.').reverse().join('-')
      const endMoment = item.workPeriod?.work_ends.split('.').reverse().join('-') + 'T12:00'
      if (!moment(startMoment).isBefore(endMoment)) {
        _orderErrorsFlag = true
        _error.push('incorrectRangeDate')
      }
    }

    if (
      (reestrObject.value.withholding_tax && +item.month_limit && +sum > +item.month_limit) ||
      (!reestrObject.value.withholding_tax && +item.month_limit_without_tax && +sum > +item.month_limit_without_tax)
    ) {
      _orderErrorsFlag = true
      _error.push('wrongLimit')
    }

    if (_error.length) {
      const key = item.id + ''
      _orderErrors[key] = _error
    }
  })

  if (_orderErrorsFlag) {
    _errors.push(_orderErrors)
  }

  if (!reestrObject.value.object.address) {
    generalErrors.push('address required')
  }
  if (!reestrObject.value.agreement_template.id) {
    generalErrors.push('agreement_template required')
  }
  if (!reestrObject.value.acceptance_certificate_template.id) {
    generalErrors.push('acceptance_certificate_template required')
  }
  if (!reestrObject.value.order_template.id) {
    generalErrors.push('order_template required')
  }
  if (!reestrObject.value.special_requirements) {
    generalErrors.push('special_requirements required')
  }
  if (reestrObject.value.special_requirements.length > 100) {
    generalErrors.push('special_requirements maxlength')
  }

  if (isBudgetExceeded.value) {
    generalErrors.push('budgetExceeded')
  }

  if (generalErrors.length) {
    if (_errors.length) {
      _errors.push(...generalErrors)
    } else {
      _errors.push({}, ...generalErrors)
    }
  }

  return _errors
})

const isCreateDisabled = computed(() => {
  return !!errors.value.length || createInProcess.value || isMaxSuborders.value
})

watch(selectedObject, () => {
  const _value = selectedObject.value
  if (_value === 'all') {
    reestrObject.value.object = {
      address: ''
    }
    return
  }
  const filteredOjbect = objectsData.value.find((o: any) => {
    return o?.id + '' === _value + ''
  })
  if (!filteredOjbect) {
    return
  }
  reestrObject.value.object = filteredOjbect
  if (selectedProject.value === 'all') {
    selectedProject.value = filteredOjbect?.project?.id
  }

  axios
    .get(API.GET_FINANCIAL_INFO(_value))
    .then((response) => {
      if (response?.data) {
        const _info = response?.data
        companyInfo.value = _info
        // this.objectReserve = this.companyInfo?.object_reserve
        objectFreeBalance.value = Number(_info?.object_budget) - Number(_info?.object_reserve)
      }
    })
    .catch((e) => {
      console.error(e)
    })
})

watch(selectedProject, () => {
  const project = selectedProject.value
  const _object = selectedObject.value

  if (_object) {
    const filteredOjbect = objectsData.value.find((o: any) => {
      return o?.id + '' === _object + ''
    })
    if (project !== filteredOjbect?.project?.id) {
      selectedObject.value = 'all'
    }
  }

  if (project === 'all') {
    objectOptions.value = [
      {
        id: 'all',
        name: 'Показать все'
      },
      ...objectsData.value
    ]
    return
  }
  const filteredObjectData = objectsData.value.filter((o: any) => {
    return o?.project?.id + '' === project + ''
  })
  objectOptions.value = [
    {
      id: 'all',
      name: 'Показать все'
    },
    ...filteredObjectData
  ]
})

const isBudgetExceeded = computed(() => {
  const finalSum = ordersList.value.reduce(function (acc: any, currentValue: any) {
    return +acc + +formatPrice(currentValue.sum)
  }, 0)
  return finalSum > totalBalance.value - reserve_balance.value
})

const finalSum = computed(() => {
  const sum = ordersList.value.reduce(function (acc: any, currentValue: any) {
    return +acc + +formatPrice(currentValue.sum)
  }, 0)
  return (sum / (1 - financialInfo.value.unitpay_commission)).toFixed(2)
})

const selectAction = (action: any) => {
  if (action.id === '') {
    reestrObject.value.job_certs_requirements = [{ id: '', name: 'Нет' }]
    setTimeout(() => {
      reestrObject.value.job_certs_requirements = [{ id: '', name: 'Нет' }]
    }, 0)
    return
  }
  if (
    action.id !== '' &&
    reestrObject.value.job_certs_requirements &&
    reestrObject.value.job_certs_requirements?.length === 1 &&
    reestrObject.value.job_certs_requirements[0].id === ''
  ) {
    reestrObject.value.job_certs_requirements = [action]
    setTimeout(() => {
      reestrObject.value.job_certs_requirements = [action]
    }, 0)
  }
}

const selectActionEquipment = (action: any) => {
  if (action.id === '') {
    setTimeout(() => {
      reestrObject.value.equipment = [{ id: '', name: 'Нет' }]
    }, 0)
    return
  }
  if (
    action.id !== '' &&
    reestrObject.value.equipment &&
    reestrObject.value.equipment?.length === 1 &&
    reestrObject.value.equipment[0].id === ''
  ) {
    reestrObject.value.equipment = [action]
    setTimeout(() => {
      reestrObject.value.equipment = [action]
    }, 0)
  }
}

const openChangeModal = (order: TOrder) => {
  editableOrder.value = order
}

const closeChangeModal = () => {
  editableOrder.value = null
}

const changeOrder = (data: any) => {
  if (editableOrder.value) {
    editableOrder.value.taskDescription = data.value.description
    editableOrder.value.task = data.value.name
    editableOrder.value.workAmount = data.value.quantity
    editableOrder.value.sum = data.value.price
    editableOrder.value.unit_type = data.value.unit_type

    editableOrder.value.workPeriod.work_started = data.value.start_date
    editableOrder.value.workPeriod.work_ends = data.value.finish_date
    const _unitTypeId = data.value.unit_type

    const unitType = preparationData.value?.unit_types?.find((i: any) => {
      return i.id + '' === _unitTypeId + ''
    })

    if (unitType) {
      editableOrder.value.unitType = unitType.name
    }
  }
  closeChangeModal()
}

const getTemplateFile = (id: string | number, arrTemplates: []) => {
  let templateFile = ''
  if (arrTemplates) {
    arrTemplates.forEach((template: { id: string | number; name: string; file: string }) => {
      if (template.id + '' === id + '') {
        templateFile = template.file
      }
    })
  }
  return templateFile
}
</script>

<template>
  <div class="flex flex-col flex-grow overflow-hidden">
    <div class="mb-4">
      <input class="reestr-title" type="text" maxlength="250" v-model="reestrName" />
      <p class="error-str" v-if="!reestrName">Введите название реестра заказов</p>
    </div>

    <p v-if="isMaxSuborders" class="text-error mt-2">Максимальное возможное количество заказов в реестре заказов 100 шт.</p>

    <u-table v-if="ordersList.length" class="flex-grow px-[1px]">
      <template #thead>
        <tr>
          <th class="w-[40px] p-0 pl-3"></th>
          <th class="w-[220px]">Исполнитель</th>
          <th class="w-[200px]">Название заказа</th>
          <th class="w-[200px]">Описание работ</th>
          <th class="w-[170px]">Период работ</th>
          <th class="w-[70px]">Объем</th>
          <th class="w-[90px]">Тип (ед)</th>
          <th class="w-[100px]">Сумма</th>
          <th class="w-[140px]">
            <div class="relative whitespace-nowrap">
              <span class="whitespace-nowrap">Лимит на выплату</span><br />
              <span class="whitespace-nowrap">(включая налог)</span>
              <UTooltip
                class="!top-0 !right-[-24px]"
                v-if="reestrObject.withholding_tax"
                tooltip="Максимально возможный платеж исполнителю включая налог с автоматическим удержанием налога банком партнером на текущий момент"
              />
              <UTooltip
                class="!top-0 !right-[-24px]"
                v-else
                tooltip="Максимально возможный платеж исполнителю включая налог без автоматического удержания налога банком партнером"
              />
            </div>
          </th>
          <th class="w-[30px]"></th>
        </tr>
      </template>
      <template #tbody>
        <tr colspan="9" class="h-[1px]"></tr>
        <template v-for="order in ordersList" :key="order.id">
          <tr :class="errors && errors[0] && errors[0][order.id] ? 'error' : ''">
            <td>
              <UICheckbox v-model="checkedOrders" :value="order.id" :id="order.id" />
            </td>
            <td>
              <div class="flex justify-between items-center">
                <ErrorTooltip
                  v-if="!order.activeAgreement && !['РФ', 'РБ', 'Республика Беларусь'].includes(order.citizenship)"
                  class="err-tooltip-worker mr-1"
                  :tooltip="`С этим исполнителем будет заключен договор от ${order.workPeriod.work_started}, так как у него нет действующего договора. Пожалуйста, воспользуйтесь разделом Уведомления МВД для подачи уведомления 7 в контролирующий орган и отметьте статус подачи “Подано”. Без этого невозможно создать акт.`"
                />
                <reestr-fio
                  :class="{ 'text-error': !order.activeAgreement && !['РФ', 'РБ', 'Республика Беларусь'].includes(order.citizenship) }"
                  :order="order"
                />
              </div>
            </td>
            <td class="whitespace-nowrap">
              <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ order.task }}</span>
            </td>
            <td class="whitespace-nowrap">
              <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ order.taskDescription }}</span>
            </td>
            <td>{{ order.workPeriod.work_started }} - {{ order.workPeriod.work_ends }}</td>
            <td>{{ roundWorkAmount(order.workAmount) }}</td>
            <td>{{ order.unitType }}</td>
            <td class="text-left">
              <WorkerSumTableItem
                :tax="reestrObject.withholding_tax"
                :order="order"
                :limitWithTax="order.month_limit"
                :limitWithoutTax="order.month_limit_without_tax"
              />
            </td>
            <td>
              <WorkerLimitsTableItem
                :tax="reestrObject.withholding_tax"
                :order="order"
                :limitWithTax="order.month_limit"
                :limitWithoutTax="order.month_limit_without_tax"
              />
            </td>
            <td>
              <button type="button" class="" @click="openChangeModal(order)">
                <img src="/icons/pencil.svg" alt="Редактировать" />
              </button>
            </td>
          </tr>
          <tr :class="errors && errors[0] && errors[0][order.id] ? '' : 'h-[1px]'">
            <td class="p-0" colspan="10" v-if="errors && errors[0] && errors[0][order.id]">
              <p class="btn-link-wrap" @click="openChangeModal(order)">
                В реестре обнаружены ошибки.
                {{ errors?.[0]?.[order.id]?.includes('wrongDecimal') && 'Неверный формат. Значение суммы должно содержать максимум две цифры после запятой' }}
                <span class="btn-link" @click="openChangeModal(order)">Исправить</span>
              </p>
            </td>
          </tr>
        </template>
      </template>
    </u-table>
    <span v-else class="bg-white block p-4 mt-2">Нет данных</span>

    <div class="relative">
      <button type="button" class="toggle-info-bar" @click="infoToggled = !infoToggled">
        <i :class="infoToggled ? 'icon-up' : 'icon-down'"></i>
      </button>
    </div>
    <div class="flex flex-col gap-4 mb-4 relative pt-3" :class="infoToggled ? 'toggled' : ''">
      <div class="flex gap-3">
        <UISelect
          id="projectSelector"
          class="flex-grow !max-w-none basis-1/5"
          label="Проект"
          unselectedLabel="Показать всё"
          :options="projectOptions"
          :value="reestrObject.object?.address === '' ? selectedProject : reestrObject.object?.project?.id"
          v-model="selectedProject"
        />
        <UISelect
          id="objectSelector"
          class="flex-grow !max-w-none basis-1/5"
          label="Объект"
          unselectedLabel="Показать всё"
          :options="objectOptions"
          :value="reestrObject.object?.address === '' ? selectedObject : reestrObject.object?.id"
          v-model="selectedObject"
        />
        <UInput
          class="flex-grow basis-1/5"
          label="Адрес из объекта"
          v-model="reestrObject.object.address"
          :value="reestrObject.object?.address"
        />
        <USwitch
          v-model="reestrObject.photo_after_completion"
          class="flex-grow !max-w-none basis-1/5"
          id="photo_after_completion"
          labelText="Фото по завершению работ"
          labelON="Да"
          labelOFF="Нет"
        />
      </div>
      <div class="flex gap-3 relative">
        <UInput
          class="flex-grow basis-1/5"
          :class="{
            error: !reestrObject.special_requirements || reestrObject.special_requirements.length > 100
          }"
          label="Спец. требования к заказу"
          id="requirments"
          name="requirments"
          v-model="reestrObject.special_requirements"
          :value="reestrObject.special_requirements"
        />
        <div class="relative flex-grow basis-1/5">
          <span class="absolute multiselect-input-title">Требования к сертификатам</span>
          <UMultiselect
            id="job_certs_requirements"
            v-model="reestrObject.job_certs_requirements"
            :options="certificateOptions"
            placeholder="Требования к сертификатам"
            :multiple="true"
            :searchable="false"
            label="name"
            track-by="id"
            :showLabels="false"
            openDirection="bottom"
            @select="selectAction"
          />
        </div>
        <div class="relative flex-grow basis-1/5">
          <span class="absolute multiselect-input-title">Техника и материалы</span>
          <UMultiselect
            id="equipment"
            v-model="reestrObject.equipment"
            :options="technicRequirments"
            placeholder="Применяемая техника и материалы"
            :multiple="true"
            :searchable="false"
            label="name"
            track-by="id"
            :showLabels="false"
            openDirection="bottom"
            @select="selectActionEquipment"
          />
        </div>
        <USwitch
          v-model="reestrObject.withholding_tax"
          class="flex-grow !max-w-none basis-1/5"
          id="withholding_tax"
          :labelText="d['bank-fee-tooltip']"
          labelON="Да"
          labelOFF="Нет"
        />
        <p class="top-[100%] error-str" v-if="!reestrObject.special_requirements">Обязательное поле</p>
        <p class="top-[100%] error-str" v-if="reestrObject.special_requirements.length > 100">Максимальная длина 100 символов</p>
      </div>
      <div class="u-card flex flex-col gap-4">
        <div class="flex gap-3">
          <div class="flex-grow basis-1/5">
            <ReestrBudgetItem
              :class="{
                error: isBudgetExceeded
              }"
              label="Суммарный бюджет с налогом и комиссией"
              :value="finalSum"
            />
            <p class="error-str" v-if="isBudgetExceeded">Заказ превышает бюджет удалите часть заказов или пополните бюджет</p>
          </div>
          <ReestrBudgetItem class="flex-grow basis-1/5" label="Депозит компании" :value="totalBalance" />
          <ReestrBudgetItem class="flex-grow basis-1/5" label="Резерв всех заказов (в т.ч. налог + %)" :value="reserve_balance" />
          <ReestrBudgetItem class="flex-grow basis-1/5" label="Свободный остаток" :value="totalBalance - reserve_balance" />
        </div>
        <div class="flex gap-3">
          <ReestrBudgetItem class="flex-grow basis-1/5" label="Бюджет объекта" :value="companyInfo.object_budget" />
          <ReestrBudgetItem class="flex-grow basis-1/5" label="Резерв бюджета объекта" :value="companyInfo.object_reserve" />
          <ReestrBudgetItem class="flex-grow basis-1/5" label="Свободный остаток бюджета объекта" :value="objectFreeBalance" />
          <div class="flex-grow basis-1/5"></div>
        </div>
      </div>
      <div class="flex">
        <h2 class="order-title mr-1 whitespace-nowrap">Выбор шаблона документации к заказу</h2>
        <UTooltip class="!static" tooltip="Согласно договору между Заказчиком и Оператором представлены данные шаблоны для документации" />
      </div>
      <div class="flex gap-3">
        <div class="flex-grow !max-w-none basis-1/5">
          <UISelect
            id="order_agreement_template"
            label="Выбор шаблона рамочного договора"
            unselectedLabel="Выберите шаблон"
            v-model="reestrObject.agreement_template.id"
            :value="reestrObject.agreement_template.id || ''"
            :options="preparationData.agreement_templates || []"
          />
          <a target="_blank" :href="getTemplateFile(reestrObject.agreement_template?.id, preparationData?.agreement_templates)"
            >Посмотреть шаблон</a
          >
        </div>
        <div class="flex-grow !max-w-none basis-1/5">
          <UISelect
            id="order_order_template"
            label="Выбор шаблона договора задания"
            unselectedLabel="Выберите шаблон"
            v-model="reestrObject.order_template.id"
            :value="reestrObject.order_template.id || ''"
            :options="preparationData.order_templates || []"
          />
          <a target="_blank" :href="getTemplateFile(reestrObject.order_template?.id, preparationData?.order_templates)"
            >Посмотреть шаблон</a
          >
        </div>
        <div class="flex-grow !max-w-none basis-1/5">
          <UISelect
            id="acceptance_certificate_template"
            label="Выбор шаблона акта"
            unselectedLabel="Выберите шаблон"
            v-model="reestrObject.acceptance_certificate_template.id"
            :value="reestrObject.acceptance_certificate_template.id || ''"
            :options="preparationData.accpetance_certificate_templates || []"
          />
          <a
            target="_blank"
            :href="getTemplateFile(reestrObject.acceptance_certificate_template?.id, preparationData?.accpetance_certificate_templates)"
            >Посмотреть шаблон</a
          >
        </div>
      </div>
      <div>
        <UInput
          type="text"
          name="omvd"
          id="omvd"
          v-model="reestrObject.omvd"
          :value="reestrObject.omvd"
          placeholder="Введите название отделения"
          label="Выбор отделения МВД"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="flex justify-end gap-3">
      <u-button label="Удалить строку" class="mr-auto" :disabled="!checkedOrders.length" @click="deleteLines" />
      <u-button label="Отмена" color="secondary" @click="cancelClick" />
      <u-button label="Создать заказ" :disabled="isCreateDisabled" @click="createOrdersReestr" />
    </div>
  </div>

  <UModal
    :show="failedcreate"
    @cancel="
      () => {
        ;(failedcreate = false), (errorMessage = '')
      }
    "
    @success=";(failedcreate = false), (errorMessage = '')"
    title="Извините, что-то пошло не так!"
  >
    <template #content>
      <p class="leading-5">{{ errorMessage }}</p>
    </template>
  </UModal>

  <template v-if="editableOrder">
    <ChangeReestrSuborderModal
      :show="true"
      :suborder="editableOrder"
      :tax="reestrObject.withholding_tax"
      :limitWithTax="preparationData.monthly_limit_with_tax"
      :limitWithoutTax="preparationData.monthly_limit_without_tax"
      :errors="errors && errors[0] && errors[0][editableOrder.id]"
      @closeModal="closeChangeModal"
      @successModal="changeOrder"
    />
  </template>
  <ConfirmSignModal task :show="showConfirmSignModal" @closeModal="showConfirmSignModal = false" @successModal="confirmSign" />
</template>

<style lang="postcss" scoped>
.reestr-title {
  @apply text-black not-italic font-normal text-[28px] leading-snug w-full bg-transparent appearance-none;
  outline: none;
}

.error-str {
  @apply absolute not-italic font-normal text-xs1 leading-snug text-error;
}

:deep(table) {
  @apply table-fixed;
}
:deep(table thead) {
  height: 3rem !important;
}

td,
th {
  padding-left: 6px;
  padding-right: 6px;
}

.multiselect-input-title {
  @apply top-1 left-[7px] z-[300] text-xs1 leading-5  text-gray-300 whitespace-nowrap;
}

tr.error {
  @apply outline-error outline outline-1;
}

.toggled {
  max-height: 190px;
  overflow: auto;
}

.toggle-info-bar {
  @apply flex flex-none justify-center items-center absolute bottom-1.5 right-0 w-7 h-7 order-1 z-[1] rounded-full text-grey bg-white;
  @apply hover:bg-white/80 shadow-main;

  i {
    @apply text-[8px];
  }
}

.btn-link-wrap {
  @apply text-xs1 leading-snug my-2 text-error;
}
.btn-link {
  @apply font-medium underline;

  &:hover {
    @apply cursor-pointer text-error/80;
  }
}

.err-tooltip-worker.tooltip-icon:hover:after {
  @apply text-black border-0 font-normal leading-5 text-sm p-3 w-[200px] xl:w-[480px] left-48 shadow-main;
}
</style>
