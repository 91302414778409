import { API } from '@/utils/API'
import axios from 'axios'

export enum EOmvdNotificationStatus {
  none = 'exists',
  filed = 'filed',
  rejected = 'rejected',
  removed = 'deleted'
}

const OMVD_NOTIFICATION_STATUS_LABELS = {
  [EOmvdNotificationStatus.none]: 'Не выбрано',
  [EOmvdNotificationStatus.filed]: 'Подано',
  [EOmvdNotificationStatus.rejected]: 'Отклонено'
}

export const notificationsStatusOptions = [
  {
    value: EOmvdNotificationStatus.none,
    label: OMVD_NOTIFICATION_STATUS_LABELS[EOmvdNotificationStatus.none]
  },
  {
    value: EOmvdNotificationStatus.filed,
    label: OMVD_NOTIFICATION_STATUS_LABELS[EOmvdNotificationStatus.filed]
  },
  {
    value: EOmvdNotificationStatus.rejected,
    label: OMVD_NOTIFICATION_STATUS_LABELS[EOmvdNotificationStatus.rejected]
  }
]

export function getNotificationsIds(notification: any, tab: number) {
  const doc = notification.id
  const start = notification.start_work_notification?.id
  const end = notification.end_work_notification?.id
  const certificate = notification.certificate?.id
  switch (tab) {
    case 1:
      return certificate ? `${doc},${start},${certificate}` : `${doc},${start}`
    case 2:
      return `${end}`
    case 3:
      return certificate ? `${doc},${start},${end},${certificate}` : `${doc},${start},${end}`

    default:
      return `${doc},${start},${end}`
  }
}

function getItemByStatus(status: any) {
  switch (status) {
    case EOmvdNotificationStatus.filed:
      return {
        value: EOmvdNotificationStatus.filed,
        label: OMVD_NOTIFICATION_STATUS_LABELS[EOmvdNotificationStatus.filed]
      }

    case EOmvdNotificationStatus.rejected:
      return {
        value: EOmvdNotificationStatus.rejected,
        label: OMVD_NOTIFICATION_STATUS_LABELS[EOmvdNotificationStatus.rejected]
      }

    default:
      return {
        value: EOmvdNotificationStatus.none,
        label: OMVD_NOTIFICATION_STATUS_LABELS[EOmvdNotificationStatus.none]
      }
  }
}

export function normalizeNotifications(notifications: any) {
  return notifications.map((item: any) => {
    const startStatus = item.start_work_notification?.status
    if (item.start_work_notification) {
      item.start_work_notification.status = getItemByStatus(startStatus)
    } else {
      item.start_work_notification = {
        status: getItemByStatus(startStatus)
      }
    }

    const endStatus = item.end_work_notification?.status
    if (item.end_work_notification) {
      item.end_work_notification.status = getItemByStatus(endStatus)
    } else {
      item.end_work_notification = {
        status: getItemByStatus(endStatus)
      }
    }

    return item
  })
}

export function switchNotificationStatus(status: EOmvdNotificationStatus, ids: any) {
  return axios.patch(API.PATCH_DOCUMENTS, {
    status,
    ids
  })
}
