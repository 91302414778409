<template>
  <div class="registration-page">
    <div v-if="notVerifiedCompany" class="registration-page__half">
      <slot name="content" />
    </div>
    <div
      v-else
      class="registration-page__half -left px-4 py-5 md:m-auto md:max-w-[720px] md:pb-[80px]"
      :class="{
        'md:max-w-[720px]': hideitem,
        'overflow-auto': $route.path.includes('/reg/')
      }"
    >
      <slot name="content" />
    </div>
    <div
      class="registration-page__half -right"
      :class="{
        reg_login:
          $route.path.includes('login') ||
          $route.path.includes('registration') ||
          $route.path.includes('select') ||
          $route.path.includes('not_verified_company'),
        reg_staff: $route.path.includes('/reg/'),
        'reg_staff-sign': $route.path.includes('selfie'),
        hideitem: '!hidden'
      }"
    >
      <div class="w-full h-screen overflow-hidden relative">
        <div v-if="!$route.path.includes('/reg/')" class="flex flex-col h-full relative pl-[90px] pt-[175px]">
          <h2 class="auth-title mb-6">
            <svg width="36" height="5" viewBox="0 0 36 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5 2.5H34" stroke="white" stroke-width="4" stroke-linecap="round" />
            </svg>
            <span>Начните работать вместе с нами</span>
          </h2>
          <h3 class="auth-subtitle mb-6">
            Платформа для управления линейным и временным персоналом с электронным документооборотом и сервисом платежей
          </h3>
          <img class="worker-image mt-auto object-cover self-end relative mr-[35px] mb-[-41px]" src="img/w.svg" alt="login bg" />
          <img class="bg-image-login" src="img/bg_with_workers.svg" alt="login bg" />
          <img class="worker-image absolute left-[50px] bottom-[135px] object-cover" src="img/w1.svg" alt="login bg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      notVerifiedCompany: false
    }
  },
  created() {
    this.$route.path === '/not_verified_company' ? (this.notVerifiedCompany = true) : (this.notVerifiedCompany = false)
  },
  computed: {
    hideitem() {
      return this.$route.name === 'staff.check'
    }
  }
})
</script>

<style lang="postcss" scoped>
* {
  @apply font-main;
}

.registration-page {
  @apply h-screen overflow-hidden w-full flex;

  &__half {
    @apply w-full h-full flex flex-col lg:justify-center md:w-2/4;
    &.-left {
      @apply justify-start;
    }
    &.-right {
      @apply hidden md:flex;
    }
  }

  .reg_login {
    @apply bg-no-repeat bg-cover bg-center;
    background-image: url('/public/img/bg_lines.svg');
  }

  .reg_staff {
    @apply w-[520px] hidden lg:block bg-backgroundMainColor bg-no-repeat bg-right bg-cover;
    background-image: url('/public/img/reg_bg.jpg');
  }
  .reg_staff-sign {
    @apply w-[520px] hidden md:block bg-backgroundMainColor bg-no-repeat;
    background-image: url('/public/img/sign_bg.png');
    background-position: 0;
    background-size: 460px 360px;
  }

  .bg-image-login {
    @apply object-cover;
  }
}

.auth-title {
  @apply not-italic font-medium text-3xl leading-snug text-white flex items-center gap-2.5;
}

.auth-subtitle {
  @apply not-italic font-semibold leading-snug text-white;
  font-size: 18px;
}

.worker-image {
  width: 82px;
}
</style>
