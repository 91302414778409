<script setup lang="ts">
import { ref, computed } from 'vue'

import { API } from '@/utils/API'
import axios from 'axios'
import { ETransactionsStatus } from '@/types/api-values'

const props = defineProps({
  orderId: {
    type: [String, Number]
  }
})

const bills = ref([])
const billsReady = ref(false)

axios
  .get(API.GET_SINGLE_ORDER_BILLS(props.orderId as string))
  .then((response: { data: any }) => {
    bills.value = response.data?.results || []
    billsReady.value = true
  })
  .catch(() => {
    billsReady.value = true
  })

const allBills = computed(() => {
  const orderId = props.orderId
  return bills.value.filter((bill: any) => bill.suborder?.order + '' === orderId + '').length
})

const availableForPayment = computed(() => {
  const orderId = props.orderId
  return bills.value.filter((bill: any) => {
    if (bill.suborder?.order + '' === orderId + '') {
      return [ETransactionsStatus.awaiting, ETransactionsStatus.paid].includes(bill.status)
    }
    return false
  }).length
})
</script>

<template>
  <template v-if="billsReady">
    <span class="text-success">{{ availableForPayment }}</span> / {{ allBills }}
  </template>
  <img src="/icons/update.svg" alt="updating" v-else class="rotate-anime" />
</template>

<style lang="postcss" scoped>
.rotate-anime {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
