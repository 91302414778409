<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import useValidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { API } from '@/utils/API'
import { formatPrice } from '@/utils/helpers'
import { getSuggestions } from '@/utils/dadata'
import { UInput, UButton, UISelect, UTextarea, UCard } from 'unit-uikit'
import axios from 'axios'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const orderStore = useOrderStore()
const { targetProject, targetObject } = storeToRefs(orderStore)
const project = targetProject.value
const suggestions = ref([])
const newProjectMaxBudget = ref(null)
const serverError = ref([])
const hideByUser = ref(false)
const newObject = ref({
  name: '',
  description: '',
  budget: '',
  address: '',
  status: 'active',
  project,
  external_object_id: ''
}) as any
const projectsData = ref([])
const ready = ref(false) as any
const processing = ref(false) as any

const rules = {
  name: { required, minLength: minLength(1), maxLength: maxLength(200) },
  project: { required },
  description: { required },
  budget: { required },
  address: { required, minLength: minLength(1), maxLength: maxLength(300) },
  external_object_id: { maxLength: maxLength(1000) }
}
const v$ = useValidate(rules, newObject)

const props = defineProps({
  type: {
    type: String,
    default: 'add'
  }
})

const closeSuggestions = ($event: any) => {
  const srcElement = $event.srcElement
  if (srcElement && srcElement.classList.contains('add-objects__suggestions')) {
    return
  }
  suggestions.value = []
}

const budget = computed(() => {
  const id = newObject.value?.project
  if (id) {
    const projectArr = projectsData.value.filter((p: any) => {
      return p.id + '' === id + ''
    })

    const project: any = projectArr && projectArr[0]
    return project?.budget ? project.budget : 0
  }
  return 0
})

const getBudgetVal = computed(() => {
  if (newObject.value.budget.length <= 1) {
    return newObject.value.budget
      .replace(/[\s₽,]/g, '')
      .replace(/(\.00|,)/g, '')
      .replace(/-/g, '')
  } else {
    return (
      newObject.value.budget
        .replace(/[\s₽,]/g, '')
        .replace(/(\.00|,)/g, '')
        .replace(/-/g, '') + ' ₽'
    )
  }
})

const freeReserve = computed(() => {
  return budget.value - budgetObjects.value - +newObject.value.budget.replace(/[\s₽,]/g, '') >= 0
})

const budgetDifference = computed(() => {
  if (newProjectMaxBudget.value && newObject.value.budget) {
    return +newObject.value.budget > +newProjectMaxBudget.value
  }
  return false
})

const addObject = () => {
  serverError.value = []
  v$.value?.$validate()
  processing.value = true

  if (!v$.value?.$error) {
    if (budgetDifference.value || !freeReserve.value) {
      processing.value = false
      return
    }

    newObject.value.budget = newObject.value.budget.replace(/[\s₽,]/g, '').replace(/-/g, '')
    axios
      .post(API.GET_OBJECT, newObject.value)
      .then((res) => {
        targetProject.value = newObject.value.project
        targetObject.value = res.data.id
        router.push('/orders/add')
      })
      .catch((err) => {
        processing.value = false
        serverError.value = err.response.data
      })
  } else {
    processing.value = true
  }
}

const editObject = () => {
  serverError.value = []
  v$.value?.$validate()
  processing.value = true

  if (!v$.value?.$error) {
    if (budgetDifference.value || !freeReserve.value) {
      processing.value = false
      return
    }
    delete newObject.value.reserve
    newObject.value.budget = newObject.value.budget.replace(/[\s₽,]/g, '')
    axios
      .put(API.SINGLE_OBJECT(objectId.value), newObject.value)
      .then(() => {
        router.push('/projects/objects/list')
      })
      .catch((err) => {
        processing.value = false
        serverError.value = err.response.data
      })
  } else {
    processing.value = true
  }
}

const objectId: any = computed(() => {
  return route.params.id
})

const title = computed(() => {
  if (props.type === 'add') {
    return 'Добавление нового объекта'
  }
  if (props.type === 'edit') {
    return `Изменение объекта №${objectId.value}`
  }
  return ''
})

const budgetObjects = computed(() => {
  const id = newObject.value?.project
  if (id) {
    const projectArr: any = projectsData.value.filter((p: any) => {
      return p.id + '' === id + ''
    })

    const objects = projectArr && projectArr[0]?.object_set

    if (objects?.length) {
      return objects.reduce((prev: any, obj: any) => {
        if (obj.id + '' === objectId.value + '') {
          return prev + 0
        }
        return prev + +obj.budget
      }, 0)
    }
  }
  return 0
})

const selectAddress = (address: string) => {
  newObject.value.address = address
}

const getSingleObject = () => {
  axios
    .get(API.SINGLE_OBJECT(objectId.value))
    .then((response) => {
      newObject.value = response.data
      ready.value = 'upload'
    })
    .catch((e) => {
      console.error(e)
    })
}

const getProjectForAdd = () => {
  axios
    .get(API.GET_PROJECT)
    .then((response) => {
      projectsData.value = response.data

      const id = newObject.value.project
      if (id) {
        response.data.forEach((p: any) => {
          if (p.id + '' === id + '') {
            newProjectMaxBudget.value = p?.budget
          }
        })
      }
    })
    .catch((e) => {
      console.error(e)
    })
}

getProjectForAdd()

if (props.type === 'add') {
  ready.value = true
}
if (props.type === 'edit') {
  getSingleObject()
}

watch(
  () => newObject.value.address,
  () => {
    if (ready.value === 'upload') {
      ready.value = true
      suggestions.value = []
      return
    }
    if (ready.value && suggestions.value?.length) {
      let sameAddress = false
      const address = newObject.value.address
      suggestions.value.forEach((item) => {
        if (item === address) {
          sameAddress = true
        }
      })
      if (sameAddress) {
        suggestions.value = []
        return
      }
    }

    const query = newObject.value.address

    getSuggestions(query, 'RU', (response: any) => {
      if (response.data?.suggestions) {
        suggestions.value = response.data.suggestions
          .map((item: any) => {
            return item.value
          })
          .slice(0, 20)
      }
    })
  }
)

watch(
  () => newObject.value.project,
  () => {
    const id = newObject.value.project
    projectsData.value.forEach((p: any) => {
      if (p.id + '' === id + '') {
        newProjectMaxBudget.value = p?.budget
      }
    })
  }
)
</script>

<template>
  <div class="add-objects" @click="closeSuggestions">
    <div class="mb-5">
      <ul class="flex">
        <UCard :value="`${formatPrice(budget)}&nbsp;₽`" label="Бюджет проекта" class="mr-4" />
        <UCard :value="`${formatPrice(budgetObjects)}&nbsp;₽`" label="Резерв заказов" class="mr-4" />
        <UCard :value="`${formatPrice(budget - budgetObjects)}&nbsp;₽`" label="Доступный остаток" />
      </ul>
    </div>
    <h2 class="add-objects__title mb-5">{{ title }}</h2>
    <div class="flex gap-4 lg:gap-0 flex-wrap lg:flex-nowrap mb-5">
      <div class="validate-wrap relative lg:mr-5">
        <UInput placeholder="Название объекта" maxlength="200" v-model="newObject.name" :value="newObject.name" />
        <div class="validate-error" v-if="v$.name.$dirty && v$.name.required.$invalid">Обязательное поле</div>
        <div class="symbols-left top-12" :class="{ error: newObject.name?.length > 200 }">{{ newObject.name?.length || 0 }} / 200</div>
      </div>
      <div class="validate-wrap mr-5 lg:max-w-[240px]">
        <UISelect
          id="objectProjectSelector"
          label="Проект объекта"
          unselectedLabel="Выберите проект"
          v-model="newObject.project"
          :value="newObject.project || ''"
          :options="projectsData"
        />
        <div class="validate-error" v-if="v$.project.$dirty && v$.project.required.$invalid">Обязательное поле</div>
      </div>
      <div class="validate-wrap lg:mr-5">
        <UInput :class="{ error: budgetDifference }" placeholder="Бюджет объекта" v-model="newObject.budget" :value="getBudgetVal" />
        <div class="validate-error" v-if="v$.budget.$dirty && v$.budget.required.$invalid">Обязательное поле</div>
      </div>
      <div class="validate-wrap relative">
        <UInput
          placeholder="Внешний идентификатор"
          maxLength="1000"
          v-model="newObject.external_object_id"
          :value="newObject.external_object_id"
        />
        <div class="validate-error" v-if="v$.external_object_id.$dirty && v$.external_object_id.maxLength.$invalid">
          Максимальная длина 1000 символов
        </div>
        <div class="symbols-left top-12" :class="{ error: newObject.external_object_id?.length > 1000 }">
          {{ newObject.external_object_id?.length || 0 }} / 1000
        </div>
      </div>
    </div>
    <div class="validate-wrap relative w-full">
      <UTextarea
        maxLength="1000"
        name="object_description"
        id="object_description"
        :value="newObject.description"
        v-model="newObject.description"
        placeholder="Описание объекта"
        class="text-sm"
      />
      <div class="validate-error" v-if="v$.description.$dirty && v$.description.required.$invalid">Обязательное поле</div>
      <div class="symbols-left !-bottom-4" :class="{ error: newObject.description?.length > 1000 }">
        {{ newObject.description?.length || 0 }} / 1000
      </div>
    </div>
    <div class="flex justify-start mt-5 validate-wrap relative mb-5">
      <UInput placeholder="Адрес объекта" maxLength="300" v-model="newObject.address" :value="newObject.address" />
      <div class="validate-error" v-if="v$.address.$dirty && v$.address.required.$invalid">Обязательное поле</div>
      <div class="symbols-left top-12" :class="{ error: newObject.address?.length > 300 }">{{ newObject.address?.length || 0 }} / 300</div>
      <template v-if="suggestions.length && newObject.address && !hideByUser">
        <div class="relative">
          <button class="absolute right-1.5 top-1.5 z-[1000]" @click="hideByUser = true">✖</button>
          <ul class="add-objects__ul">
            <li class="add-objects__suggestions" v-for="(suggestion, idx) in suggestions" :key="idx" @click="selectAddress(suggestion)">
              {{ suggestion }}
            </li>
          </ul>
        </div>
      </template>
    </div>
    <p class="validation-errors text-error mb-3" v-if="v$.$error && type === 'add'">
      Пожалуйста заполните все необходимые поля перед добавлением нового объекта
    </p>
    <p class="validation-errors text-error mb-3" v-if="v$.$error && type === 'edit'">
      Пожалуйста заполните все необходимые поля перед изменением объекта
    </p>
    <h4 class="validation-errors text-error mb-3" v-if="budgetDifference">Бюджет объекта не может превышать бюджет всего проекта</h4>
    <h4 class="validation-errors text-error mb-3" v-if="newObject.project && !freeReserve">
      Бюджет объекта не может превышать свободные резервы проекта
    </h4>
    <h4 class="validation-errors text-error mb-3" v-for="(error, index) in serverError" :key="index">
      <p><b>Ошибка: </b>{{ error }}</p>
    </h4>
    <div class="add-objects__controls flex justify-end mt-4">
      <UButton class="mr-5" color="secondary" label="Отмена" @click.prevent="$router.push('/projects/objects/list')" />
      <UButton label="Добавить" @click.prevent="addObject" v-if="type === 'add'" />
      <UButton label="Изменить" @click.prevent="editObject" v-if="type === 'edit'" :disabled="processing || !freeReserve" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.add-objects {
  &__title {
    @apply text-left  font-medium text-2xl leading-7 text-black;
  }

  &__ul {
    @apply bg-white max-h-[500px] overflow-y-auto mt-1.5 border border-solid border-black absolute w-full;
  }

  &__suggestions {
    @apply px-2 text-left hover:cursor-pointer hover:bg-[lightblue];
  }
}

.validate-wrap {
  @apply flex-grow !w-full lg:!w-auto;
  & .input-container {
    @apply w-full max-w-full;
  }
}

.symbols-left {
  @apply absolute -bottom-1.5 right-0 text-xs text-grey;
  &.error {
    @apply text-error;
  }
}
</style>
