<template>
  <UModal :show="show" :title="title" @cancel="closeAction">
    <template #content>
      <ul class="tabs">
        <li class="tabs__item">
          <a href="#" :class="isActiveTab('comments')" @click="setActiveTab('comments')">Прошлые комментарии</a>
        </li>
        <li class="tabs__item">
          <a href="#" :class="isActiveTab('results')" @click="setActiveTab('results')">Результат работы</a>
        </li>
      </ul>
      <template v-if="isActiveTab('results')">
        <FilesResultsItem :results="workResults" :closeAction="closeAction" :returnToWorkAction="returnToWorkAction" />
      </template>
      <template v-else>
        <div v-if="commentHistory.length" class="overflow-auto h-full">
          <CommentResultItem v-for="(item, id) in commentHistory" :key="id" :comment="item" :worker="worker" />
        </div>
        <div v-else>
          <span>Комментариев нет</span>
        </div>
      </template>
    </template>
    <template #buttons>
      <div></div>
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CommentResultItem from './CommentResultItem.vue'
import FilesResultsItem from './FilesResultsItem.vue'
import { UModal } from 'unit-uikit'

export default defineComponent({
  components: {
    CommentResultItem,
    FilesResultsItem,
    UModal
  },
  props: {
    show: Boolean,
    closeAction: Function,
    returnToWorkAction: Function,
    results: Array as any,
    returns: Array as any,
    worker: String
  },
  data() {
    return {
      activeTab: 'results'
    }
  },
  methods: {
    isActiveTab(tab: string) {
      return tab === this.activeTab ? 'router-link-exact-active' : ''
    },
    setActiveTab(tab: string) {
      this.activeTab = tab
    }
  },
  computed: {
    workResults() {
      return this.results
        .map((item: any) => {
          item.isResult = true
          return item
        })
        .reverse()
    },
    commentHistory() {
      const results = this.workResults
      const history = [...results, ...this.returns].sort((a: any, b: any) => {
        return new Date(b.created).valueOf() - new Date(a.created).valueOf()
      })
      return history
    },
    title() {
      return this.activeTab === 'results' ? 'Результат работы' : 'Прошлые комментарии'
    }
  }
})
</script>

<style lang="postcss" scoped>
:deep(.modal) {
  @apply !max-w-[950px] !w-3/4;
}
</style>
