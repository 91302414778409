<template>
  <div
    class="p-4 rounded-lg text-sm font-medium info-message"
    :class="{
      '-error': messageType === 'error',
      '-info': messageType === 'info'
    }"
  >
    {{ message }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: String,
    messageType: {
      type: String,
      default: 'error'
    }
  }
})
</script>

<style lang="postcss" scoped>
.info-message {
  @apply tracking-title text-sm;

  &.-error {
    @apply text-error bg-[#FFB1B1];
  }

  &.-info {
    @apply text-[#FF9900] bg-[#FFEDD1];
  }
}
</style>
