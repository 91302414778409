<template class="relative">
  <main-page-header :title="getTitle" class="page-title">
    <UButton
      data-create-notifications="true"
      :disabled="isProcurationEnded"
      v-if="!isAddOrEditTrustedPerson"
      label="Создать уведомления"
      class="mr-4 ml-auto"
      @click="createNotifications"
    />
  </main-page-header>
  <UTabs v-if="!isAddOrEditTrustedPerson" :tabs="tabs" class="omvd-tabs" />
  <DownloadNotifications
    v-if="showDownloadNotificationsPopup"
    @close="showDownloadNotificationsPopup = false"
    @error="createNotificationUnloadError"
    @success="createNotificationUnloadSuccess"
  />

  <CreateNotifications
    v-if="showNotificationsPopup"
    @close="showNotificationsPopup = false"
    @error="createNotificationError"
    @success="createNotificationSuccess"
  />

  <div class="flex flex-col overflow-hidden relative flex-grow mb-[-32px]">
    <router-view v-slot="{ Component }">
      <component :is="Component" @updateCounter="updateCounter">
        <template #downloadBtn>
          <UButton size="sm" label="Скачать" @click="showNotificationsModal" :disabled="!checkedNotifications.length" />
        </template>
      </component>
    </router-view>
  </div>

  <UModal
    :show="successDocsCreated"
    @cancel="successDocsCreated = false"
    @success="
      () => {
        $router.push('/documents/history')
      }
    "
    title="Выборка успешно создана!"
    successLabel="Перейти к истории экспорта"
  >
  </UModal>

  <UModal
    :show="successCreated"
    @cancel="successCreated = false"
    @success="
      () => {
        successCreated = false
        $router.go()
      }
    "
    title="Договор и уведомления успешно созданы!"
    successLabel="Ок"
  >
  </UModal>

  <UModal
    :show="failedcreate"
    @cancel="
      () => {
        failedcreate = false
      }
    "
    @success="failedcreate = false"
    title="Извините, что-то пошло не так!"
  >
    <template #content>
      <p class="leading-5">Попробуйте повторить попытку позже или свяжитесь со службой поддержки</p>
    </template>
  </UModal>
</template>

<script lang="ts">
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { defineComponent } from 'vue'
import CreateNotifications from '@/components/Modals/CreateNotifications.vue'
import DownloadNotifications from '@/components/Modals/DownloadNotifications.vue'
import { UButton, UTabs, UModal } from 'unit-uikit'
import { API } from '@/utils/API'
import axios from 'axios'
import { useCompanyStore } from '@/stores/company'
import { useUiStore } from '@/stores/ui'

export default defineComponent({
  name: 'projects',
  data() {
    return {
      uiStore: useUiStore(),
      title: 'Документы',
      titleAddTrustedPerson: 'Создание нового доверенного лица',
      titleEditTrustedPerson: 'Изменение данных доверенного лица',
      showDownloadNotificationsPopup: false,
      showNotificationsPopup: false,
      successDocsCreated: false,
      successCreated: false,
      failedcreate: false,
      badge: 0,
      tabs: [
        {
          routeName: 'omvd.all',
          label: 'Уведомления МВД'
        },
        {
          routeName: 'omvd.notifications',
          label: 'Уведомления о расторжении',
          badge: 0
        },
        {
          routeName: 'omvd.history',
          label: 'Архив уведомлений'
        },
        {
          routeName: 'omvd.trustedpersons',
          label: 'Доверенные лица'
        }
      ]
    }
  },
  components: {
    MainPageHeader,
    UButton,
    CreateNotifications,
    UModal,
    DownloadNotifications,
    UTabs
  },
  methods: {
    showNotificationsModal() {
      this.showDownloadNotificationsPopup = true
    },
    createNotificationError() {
      this.showNotificationsPopup = false
      this.failedcreate = true
    },
    createNotificationSuccess() {
      this.showNotificationsPopup = false
      this.successCreated = true
    },
    createNotificationUnloadError() {
      this.showDownloadNotificationsPopup = false
      this.failedcreate = true
    },
    createNotificationUnloadSuccess() {
      this.showDownloadNotificationsPopup = false
      this.successDocsCreated = true
    },
    createNotifications() {
      this.showNotificationsPopup = true
    },
    updateCounter() {
      const queryString =
        '?count=1&page=1&app_7=filed&app_8=exists,signed,not_signed,active,finished,terminated,converting_error,null&order=exp_date'
      axios
        .get(API.GET_DOCUMENTS_NOTIFICATIONS + queryString)
        .then((res: any) => {
          this.badge = res.data.expiring
          this.tabs[1].badge = this.badge
        })
        .catch()
    }
  },
  computed: {
    isProcurationEnded() {
      const companyStore = useCompanyStore()
      return companyStore.isProcurationEnded
    },
    checkedNotifications() {
      return this.uiStore.checkedNotifications || []
    },
    isAddOrEditTrustedPerson() {
      // @ts-ignore
      if (this?.$route?.name?.includes('add') || this?.$route?.name?.includes('edit')) {
        return true
      } else {
        return false
      }
    },
    getTitle() {
      // @ts-ignore
      if (this?.$route?.name?.includes('add')) {
        return this.titleAddTrustedPerson
        // @ts-ignore
      } else if (this?.$route?.name?.includes('edit')) {
        return this.titleEditTrustedPerson
      } else {
        return this.title
      }
    }
  },
  created() {
    this.updateCounter()
  }
})
</script>

<style lang="postcss" scoped>
.badge {
  @apply inline-block font-medium text-xs h-5 leading-5 rounded-[20px] text-white bg-error text-center whitespace-nowrap align-baseline px-[6px];
}

:deep(.modal) {
  @apply !gap-3;
}
:deep(.modal .-buttons button:first-child) {
  @apply hidden;
}

.omvd-tabs {
  @apply !flex-shrink-0 overflow-y-hidden overflow-x-auto whitespace-nowrap;
}
</style>
