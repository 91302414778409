import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import jwtDecode from 'jwt-decode'
import router from '@/router'
import AuthService from '@/services/auth.service'

import { TToken, TTokenPayload, TUser } from '@/types'

const TOKEN_KEY = 'JWT'

export const useAuthStore = defineStore('auth', () => {
  const stringJWT = localStorage.getItem(TOKEN_KEY)
  const jwt = ref((stringJWT && JSON.parse(stringJWT)) || null)

  const loggedIn = computed(() => {
    return jwt.value !== null
  })

  const authHeader = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      return `Bearer ${accessToken}`
    }
    return null
  })

  const payload = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      const _payload = jwtDecode(jwt.value.access_token) as TTokenPayload
      return _payload
    }
    return null
  })

  const tokenExpired = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      const { exp } = jwtDecode(jwt.value.access_token) as TTokenPayload
      return !!(exp && exp * 1000 < Date.now())
    }
    return true
  })

  function $reset() {
    localStorage.removeItem(TOKEN_KEY)
    jwt.value = null
  }

  function saveJWT(token: TToken) {
    if (token?.access_token) {
      const _token = JSON.stringify(token)
      localStorage.setItem(TOKEN_KEY, _token)
      jwt.value = token
    }
  }

  function logout() {
    $reset()
    router.push({
      name: 'login'
    })
  }

  async function login(user: TUser) {
    const results = await AuthService.login(user)
    return results || 'error'
  }

  async function loginConfirm(user: TUser) {
    try {
      const results = await AuthService.loginConfirm(user)
      return results || 'error'
    } catch (error) {
      localStorage.removeItem(TOKEN_KEY)
      throw error
    }
  }

  async function register(user: TUser) {
    try {
      const results = await AuthService.register(user)
      return results || 'error'
    } catch (error) {
      return error
    }
  }

  async function registerConfirm(user: TUser) {
    try {
      const results = await AuthService.registerConfirm(user)
      return results || 'error'
    } catch (error) {
      return error
    }
  }

  async function refreshToken() {
    try {
      const results = await AuthService.refreshToken()
      return results || 'error'
    } catch (error) {
      logout()
      return error
    }
  }

  return {
    jwt,
    saveJWT,
    loggedIn,
    authHeader,
    payload,
    tokenExpired,
    logout,
    login,
    loginConfirm,
    register,
    registerConfirm,
    refreshToken,
    $reset
  }
})
