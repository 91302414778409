<script lang="ts" setup>
import { ref, watch, computed } from 'vue'

import { UInput, UISelect, UButton, UMultiselect, UDateRange } from 'unit-uikit'
import { docTypesOptions, docTypesStatuses } from '@/utils/doc-types'

import { useObjectsStore } from '@/stores/objects'
import { useProjectsStore } from '@/stores/projects'
import { storeToRefs } from 'pinia'

const objectsStore = useObjectsStore()
objectsStore.fetcObjects()
const projectsStore = useProjectsStore()
projectsStore.fetchProjects()

const { objects } = storeToRefs(objectsStore)
const { projects } = storeToRefs(projectsStore)

defineProps({
  title: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['clear', 'filter', 'find'])

const showAll = ref(false)
const filterData = ref({}) as any

const clearFilters = () => {
  const _start = document.getElementById('startDateFilter') as HTMLInputElement
  if (_start) {
    _start.value = ''
  }
  const _end = document.getElementById('finishDateFilter') as HTMLInputElement
  if (_end) {
    _end.value = ''
  }
  filterData.value = {}
  emits('clear', {})
}

watch(filterData.value, () => {
  emits('filter', filterData)
})

const filtredObjects = computed(() => {
  const id = filterData.value?.project_id
  if (id) {
    return objects.value.filter((item: any) => {
      return id + '' === item.project.id + ''
    })
  }
  return objects.value
})
</script>

<template>
  <div class="documents-filter">
    <div class="flex items-start">
      <button class="documents-filter__filter-button mr-4" :class="{ '!border-accent': showAll }" @click="showAll = !showAll">
        <img :src="!showAll ? '/icons/super_filter.svg' : '/icons/super_filter_colored.svg'" alt="filter icon" />
      </button>
    </div>
    <div class="w-full flex gap-3 flex-wrap mb-0 2xl:mb-2">
      <UInput placeholder="Имя" v-model="filterData.worker_first_name" :value="filterData.worker_first_name" @keyup.enter="emits('find')" />
      <UInput
        placeholder="Фамилия"
        v-model="filterData.worker_last_name"
        :value="filterData.worker_last_name"
        @keyup.enter="emits('find')"
      />
      <UInput
        placeholder="Название реестра заказов"
        v-model="filterData.suborder_or_order_name"
        :value="filterData.suborder_or_order_name"
        @keyup.enter="emits('find')"
      />
      <div class="relative">
        <span class="absolute text-xs1 text-gray-300 leading-5 top-[3px] left-2 z-10">Тип документов</span>
        <UMultiselect
          class="min-w-[200px] max-w-[200px]"
          v-model="filterData.selectedDocType"
          :options="docTypesOptions"
          placeholder="Показать все"
          :multiple="true"
          :searchable="false"
          :close-on-select="false"
          label="label"
          track-by="value"
          :showLabels="false"
        ></UMultiselect>
      </div>
      <UDateRange
        class="max-w-[200px]"
        title="Дата создания документа"
        startPlaceHolder="От"
        endPlaceHolder="До"
        startId="startDateFilter"
        endId="finishDateFilter"
        :start="filterData.start_date"
        :finish="filterData.finish_date"
        @update-start-date="(date: string) => { filterData.start_date = date }"
        @update-finish-date="(date: string) => { filterData.finish_date = date }"
      />
      <UInput
        v-if="showAll"
        placeholder="Номер реестра заказов"
        :value="filterData.order_id"
        v-model="filterData.order_id"
        mask="positiveNumber"
        @keyup.enter="emits('find')"
      />
      <UInput
        v-if="showAll"
        placeholder="Номер документа"
        v-model="filterData.id"
        :value="filterData.id"
        mask="positiveNumber"
        @keyup.enter="emits('find')"
      />
      <UInput
        v-if="showAll"
        placeholder="Номер телефона"
        v-model="filterData.worker_phone"
        :value="filterData.worker_phone"
        mask="phone"
        @keyup.enter="emits('find')"
      />
      <UInput
        v-if="showAll"
        mask="positiveNumber"
        placeholder="Номер заказа"
        v-model="filterData.suborder_id"
        :value="filterData.suborder_id"
        @keyup.enter="emits('find')"
      />
      <UISelect
        id="projectFilter"
        v-if="showAll"
        label="Проект"
        unselectedLabel="Выберите проект"
        v-model="filterData.project_id"
        :value="filterData.project_id || ''"
        :options="projects"
      />
      <UISelect
        id="objectFilter"
        v-if="showAll"
        label="Объект"
        unselectedLabel="Выберите объект"
        v-model="filterData.object_id"
        :value="filterData.object_id || ''"
        :options="filtredObjects"
      />
      <div class="relative">
        <span class="absolute text-xs1 text-gray-300 leading-5 top-[3px] left-2 z-10">Статус</span>
        <UMultiselect
          class="min-w-[200px] max-w-[200px]"
          v-model="filterData.selectedDocStatus"
          :options="docTypesStatuses"
          placeholder="Показать все"
          :multiple="true"
          :searchable="false"
          :close-on-select="false"
          label="label"
          track-by="value"
          :showLabels="false"
        ></UMultiselect>
      </div>
    </div>
    <div class="flex ml-auto">
      <UButton label="Поиск" class="mr-2 w-32 !h-11" @click="emits('find')" />
      <UButton title="Сброс фильтра" label="&#10006;" color="secondary" class="!w-11 !h-11 text-lg" @click="clearFilters" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.documents-filter {
  display: flex;
  width: 100%;
  max-height: 324px;
  overflow: auto;

  &__filter-button {
    @apply flex items-center justify-center min-h-[44px] min-w-[44px] w-11 h-11 border border-grey1 rounded-lg hover:opacity-60;
  }

  .input-container {
    @apply w-[200px] mr-0;
  }
}

:deep(.select) {
  @apply !w-[200px] !mr-0;
}

#endDate {
  @apply bg-transparent;
}
</style>
