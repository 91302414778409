import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_USwitch = _resolveComponent("USwitch")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), null, {
    "view-switcher": _withCtx(() => [
      _createVNode(_component_USwitch, {
        modelValue: _ctx.showSuborders,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSuborders) = $event)),
        id: "financeViewSwitcher",
        labelText: "Показать счета",
        labelON: "Скрыть",
        labelOFF: "Показать"
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}