<template>
  <UModal @cancel="$emit('close')" :show="true" title="Скачать уведомления">
    <template #content>
      <h4 class="modal-subtitle">Введите данные о доверенности и лице, ответственном за подачу уведомлений</h4>
      <div class="modal-content">
        <UICheckbox
          class="mb-6"
          v-model="onlyNotificationsUpload"
          id="onlyNotificationsUpload"
          value="onlyNotificationsUpload"
          label="Выгрузить без доверенности"
        />

        <div class="flex mb-2">
          <UInput
            class="!w-full !max-w-full text-grey"
            :class="disableInputs ? 'isDisabled' : ''"
            :disabled="disableInputs"
            placeholder="Дата подачи уведомления"
            v-model="application_sending_date"
            :value="application_sending_date"
            mask="date"
          />
        </div>
        <div class="mb-2">
          <UMultiselect
            v-model="selectedTrustedPerson"
            :options="trustedPersonsList"
            placeholder="ФИО доверенного лица"
            :close-on-select="true"
            :custom-label="getTrustedPersonsListSelectOptionsNames"
            track-by="id"
            :showNoOptions="false"
            :showLabels="false"
            open-direction="bottom"
            :class="disableInputs ? 'bg-gray isDisabled' : ''"
            :disabled="disableInputs"
          >
            <template #afterList>
              <li
                @click="$router.push('/omvd/trustedpersons/add'), $emit('close')"
                class="pl-2 py-3 w-full block text-sm text-accent font-medium hover:cursor-pointer hover:opacity-60"
              >
                + Создать нового
              </li>
            </template>
            <template #noResult>Ничего не найдено</template>
          </UMultiselect>
        </div>
      </div>
    </template>
    <template #buttons>
      <UButton class="w-full" label="Скачать" @click="createNotificationsBundle" :disabled="!isDataReady" />
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UInput, UButton, UMultiselect, UICheckbox, UModal } from 'unit-uikit'
import { formatDateForQueryString, formatToServerDateNotifications } from '@/utils/helpers'
import { API } from '@/utils/API'
import axios from 'axios'
import { useUiStore } from '@/stores/ui'

function checkValidDate(date: string) {
  return date.length === 10
}

function getDocCategories(route: string) {
  switch (route) {
    case 'omvd.notifications':
      return ['end_work_notification']
    case 'omvd.history':
      return ['start_work_notification', 'end_work_notification']
    case 'omvd.all':
      return ['start_work_notification']

    default:
      return []
  }
}

function getDocCertificates(route: string) {
  switch (route) {
    case 'omvd.notifications':
      return false
    case 'omvd.history':
    case 'omvd.all':
      return true

    default:
      return false
  }
}

function getDocAgreements(route: string) {
  switch (route) {
    case 'omvd.notifications':
      return false
    case 'omvd.history':
    case 'omvd.all':
      return true

    default:
      return true
  }
}

export default defineComponent({
  components: {
    UInput,
    UButton,
    UMultiselect,
    UICheckbox,
    UModal
  },
  data() {
    return {
      uiStore: useUiStore(),
      application_sending_date: '',
      onlyNotificationsUpload: [],
      trustedPersonsList: [],
      selectedTrustedPerson: '' as any
    }
  },
  mounted() {
    this.getTrustedPersons()
  },
  methods: {
    formatToServerDateNotifications,
    formatDateForQueryString,
    getTrustedPersons() {
      axios
        .get(API.GET_TRUSTED_PERSONS)
        .then((response: any) => {
          this.trustedPersonsList = response.data
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    getTrustedPersonsListSelectOptionsNames({ last_name, first_name, middle_name }: any) {
      return `${last_name || ''} ${first_name || ''} ${middle_name || ''}`
    },
    createNotificationsBundle() {
      const selectAllSwitcher = this.uiStore.selectAllNotifications
      const queryParams = this.uiStore.selectAllNotificationsQueryParams || ''

      // 3. Выбрали все
      // "add_certificates" - false,
      // "category" = [] - заполняем в зависимости от страницы
      // "query_params" - заполняем то что после /, если есть
      // "all" = True

      let ids: any = this.uiStore.checkedNotifications || []
      ids = ids.reduce((prev: any, next: any) => {
        return [...prev, ...next.split(',')]
      }, [])

      if ((ids && ids.length) || selectAllSwitcher) {
        const route = this.$route.name as any
        const category = getDocCategories(route)
        const queryLink = queryParams

        let payload = {
          query_link: API.SET_DOCS_EXPORT + '?' + queryLink,
          name: 'Уведомления ' + formatDateForQueryString(new Date()),
          // document_amount: ids.length,
          period_begin: formatDateForQueryString(0),
          period_end: formatDateForQueryString(new Date()),
          add_certificates: getDocCertificates(route),
          add_agreements: getDocAgreements(route),
          category,
          has_procuration: !this.onlyNotificationsUpload.length,
          query_params: '?' + queryParams,
          ids: selectAllSwitcher ? [] : ids || [],
          all_docs: selectAllSwitcher || false
        }

        if (!this.onlyNotificationsUpload.length) {
          payload = {
            ...payload,
            ...{
              trusted_person: this.selectedTrustedPerson.id,
              application_sending_date: formatToServerDateNotifications(this.application_sending_date)
            }
          }
        }

        axios
          .post(API['SET_DOCS_EXPORT_ALL'] + '?' + queryLink, payload)
          .then(() => {
            this.$emit('success')
          })
          .catch(() => {
            this.$emit('error')
          })
      }
    }
  },
  computed: {
    disableInputs() {
      return !!this.onlyNotificationsUpload.length
    },
    isDataReady() {
      const isOnlyNotifications = !!this.onlyNotificationsUpload.length
      const docsReady = checkValidDate(this.application_sending_date)
      return isOnlyNotifications || (docsReady && this.selectedTrustedPerson.id)
    },
    closeModal() {
      this.$emit('close')
    }
  }
})
</script>

<style lang="postcss" scoped>
:deep(.multiselect__placeholder) {
  @apply pt-0 text-sm;
}

:deep(.multiselect__input) {
  @apply bg-white text-sm;
}

:deep(.multiselect__single) {
  @apply mt-0 bg-transparent  text-grey;
}

:deep(.input::placeholder) {
  @apply text-grey;
}

.modal {
  :deep(.select) {
    @apply max-w-full sm:max-w-[360px];
  }

  &-subtitle {
    @apply text-sm leading-snug mb-3;
  }

  &-content {
    @apply mb-6 min-h-[50%] sm:min-h-fit max-h-[455px];
  }
}

:deep(.modal .multiselect .multiselect__content li > span::before) {
  @apply hidden;
}
:deep(.modal .multiselect .multiselect__content .multiselect__option span) {
  @apply ml-0;
}
:deep(.modal .multiselect .multiselect__content .multiselect__option--selected::after) {
  @apply hidden;
}
</style>
