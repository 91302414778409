<script setup lang="ts">
import TOrder from '../types'
import { PropType } from 'vue'
import { getSplittedInn, formatDate } from '@/utils/helpers'
import { UTooltip } from 'unit-uikit'

const props = defineProps({
  order: {
    type: Object as PropType<TOrder>,
    required: true
  },
  allOrders: {
    type: Array,
    default: []
  }
})

const getWorkerFullName = (worker: any, order: any) => {
  if (!worker.firstName || !worker.lastName) {
    const result: any = props.allOrders.find((item: any) => {
      return parseInt(item.id) === +order
    })
    if (result) {
      return `${result.worker_last_name || ''} ${result.worker_first_name || ''} ${result.worker_middle_name || ''}`
    }
  } else {
    return `${worker.firstName} ${worker.lastName} ${worker.middleName}`
  }
}

const getWorkerPhone = (phone: string) => {
  if (!phone) {
    return
  }
  const prt = phone?.toString().split('')
  return `+7-${prt[1]}${prt[2]}${prt[3]}-${prt[4]}${prt[5]}${prt[6]}-${prt[7]}${prt[8]}-${prt[9]}${prt[10]}`
}

const checkNotificationsAgreementData = (order: TOrder) => {
  let result = false
  if (order?.workPeriod?.work_started && order.notificationsAgreementData) {
    const startDate = order?.workPeriod?.work_started
    const newFormattedDate = new Date(`${startDate.slice(6, 10)}.${startDate.slice(3, 5)}.${startDate.slice(0, 2)}`)
    order.notificationsAgreementData.forEach((item: any) => {
      if (result) {
        return
      }
      const oldFormattedDate = new Date(item.start_date)
      oldFormattedDate.setHours(0)

      if (newFormattedDate.valueOf() < oldFormattedDate.valueOf()) {
        if (order.worker_id === item.worker_id) {
          result = Object.values(item).includes(order.worker_id)
        }
      }
    })
  }
  return result
}

const getNotificationStartDate = (order: TOrder) => {
  let result = false
  let currentStartDate = null
  if (order.workPeriod?.work_started && order.notificationsAgreementData) {
    const startDate = order.workPeriod?.work_started
    const newFormattedDate = new Date(`${startDate.slice(6, 10)}.${startDate.slice(3, 5)}.${startDate.slice(0, 2)}`)
    order.notificationsAgreementData.forEach((item: any) => {
      if (result) {
        return
      }
      const oldFormattedDate = new Date(item.start_date)
      // newFormattedDate < oldFormattedDate ? (order.new_agremeent_warning = true) : (order.new_agremeent_warning = false)
      if (newFormattedDate < oldFormattedDate) {
        if (order.worker_id === item.worker_id) {
          result = Object.values(item).includes(order.worker_id)
          currentStartDate = formatDate(item.start_date, false)
        }
      }
    })
  }
  return currentStartDate
}

const getNotificationEndDate = (order: TOrder) => {
  let result = false
  let currentStartDate = null
  if (order.workPeriod?.work_started && order.notificationsAgreementData) {
    const startDate = order.workPeriod?.work_started
    const newFormattedDate = new Date(`${startDate.slice(6, 10)}.${startDate.slice(3, 5)}.${startDate.slice(0, 2)}`)
    order.notificationsAgreementData.forEach((item: any) => {
      if (result) {
        return
      }
      const oldFormattedDate = new Date(item.start_date)
      // newFormattedDate < oldFormattedDate ? (order.new_agremeent_warning = true) : (order.new_agremeent_warning = false)
      if (newFormattedDate < oldFormattedDate) {
        if (order.worker_id === item.worker_id) {
          result = Object.values(item).includes(order.worker_id)
          currentStartDate = formatDate(item.end_date, false)
        }
      }
    })
  }
  return currentStartDate
}
</script>

<template>
  <div class="flex items-center">
    <UTooltip
      v-if="checkNotificationsAgreementData(order)"
      info
      class="!static mr-2 relative min-w-fit"
      :tooltip="`Действующий договор ${getNotificationStartDate(order)} - ${getNotificationEndDate(
        order
      )} будет завершен после создания заказа`"
    />
    <div class="flex flex-col">
      <h3 v-if="order?.data?.firstName || order?.data?.lastName" class="whitespace-nowrap">
        <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ getWorkerFullName(order?.data, order?.id) }}</span>
      </h3>
      <h3 v-else>Нет данных о ФИО</h3>
      <p class="font-normal text-xs1 left-3 text-grey mt-1 whitespace-nowrap">
        <span>{{ getWorkerPhone(order?.data.phone) }}</span
        >&nbsp;
        <span>{{ getSplittedInn(order?.inn) }}</span>
      </p>
    </div>
  </div>
</template>
