<template>
  <UModal
    :show="show"
    @cancel="$emit('closeModal')"
    @success="successAction"
    :title="popupTitle"
    successLabel="Сохранить"
    class="long-modal overflow-y-auto"
  >
    <template #content>
      <div class="flex flex-col items-start mb-5">
        <div class="flex">
          <span class="mr-3">{{ workerName }}</span>
        </div>
        <span class="text-xs1" v-if="workerPhone">{{ workerPhone }}</span>
      </div>

      <div class="relative mb-5">
        <UInput
          maxlength="200"
          id="taskName"
          class="flex-grow !max-w-none"
          label="Название заказа (попадет в чек оплаты)"
          :title="newSuborder.name"
          :value="newSuborder.name"
          v-model="newSuborder.name"
        />
        <p class="absolute error-text" v-if="newSuborder.name?.length > 200">Максимальная длина 200 символов</p>
        <div class="symbols-left" :class="{ error: newSuborder.name?.length > 200 }">{{ newSuborder.name?.length || 0 }} / 200</div>
      </div>

      <div class="flex mb-5">
        <div class="relative flex-grow mr-4 basis-[20%]">
          <template v-if="dateReady">
            <UDateRange
              class="!w-[210px]"
              title="Период работ"
              :start="suborderDates.start_date"
              :finish="suborderDates.finish_date"
              @update-start-date="(date: string) => { suborderDates.start_date = date }"
              @update-finish-date="(date: string) => { suborderDates.finish_date = date }"
            />
          </template>
        </div>
        <div class="relative flex-grow mr-4 basis-[20%]">
          <UInput
            id="taskQuantity"
            class="flex-grow !max-w-none"
            label="Объем работ"
            :value="quantityToChange"
            v-model="quantityToChange"
          />
        </div>
        <div class="relative flex-grow mr-4 basis-[20%]">
          <UISelect
            id="taskUnitType"
            class="flex-grow !max-w-none"
            label="Тип единиц"
            unselectedLabel="Тип единиц"
            :value="newSuborder?.unit_type"
            v-model="newSuborder.unit_type"
            :options="unitTypesList"
          />
        </div>
        <div class="relative flex-grow basis-[20%]">
          <UInput
            class="flex-grow !max-w-none"
            :class="{
              '!border-error': workerLimitError,
              'error-txt': workerLimitError
            }"
            label="Стоимость"
            :value="newSuborder.price ? newSuborder?.price.replaceAll('.', ',') : '0'"
            v-model="newSuborder.price"
            mask="valueCurrency"
            :showCurrency="true"
          />
          <UTooltip v-if="tax" percent :tooltip="d['payment-fee']" />
        </div>
      </div>

      <div v-if="tax && workerLimitError" class="error-wrapper relative mb-4">
        <span class="error-txt">Превышен максимально допустимый платеж исполнителю {{ formatPrice(limit) }}&nbsp;₽ за текущий месяц.</span
        ><br />
        Доступно для выплаты исполнителю на текущий момент
        {{ formatPrice(suborder?.month_limit || suborder?.worker?.monthly_limit) }}&nbsp;₽.<br />
        <span class="error-txt">Данное ограничение считается по следующему принципу.</span><br />
        Если у клиента не было выплат с удержанием налога за предыдущий месяц, то фактически лимит считается с 1 числа текущего месяца по 12
        число следующего.<br />
        Если у клиента в предыдущем месяце были выплаты с удержанием налога, то после оплаты налога 12 числа текущего месяца, лимит будет
        рассчитывается с 12 число текущего месяца по 12 число следующего.<br />
        Из максимально допустимого платежа за расчетный период в 250 000 рублей вычитаются все выплаты с автоматическим удержанием налога
        банком партнером за текущий расчетный период.<br />
        Это ограничение банка-партнера. Такой платеж не пройдет. Пожалуйста, скорректируйте сумму или удалите исполнителя из заказа
      </div>
      <div v-if="!tax && workerLimitError" class="error-wrapper relative mb-4">
        <span class="error-txt">Превышен максимально допустимый платеж исполнителю {{ formatPrice(limit) }}&nbsp;₽ за текущий месяц.</span
        ><br />
        Доступно для выплаты исполнителю на текущий момент
        {{ formatPrice(suborder?.month_limit_without_tax || suborder?.worker?.month_limit_without_tax) }}&nbsp;₽.<br />
      </div>

      <div class="relative mb-5">
        <div class="error-block-wrapper">
          <label class="input-wrapper !h-[10rem]">
            <h3 class="input-wrapper__title">Описание заказа</h3>
            <UTextarea
              class="leading-snug !pt-4"
              maxLength="1000"
              name="modalOrderdescription"
              id="modalOrderdescription"
              v-model="newSuborder.description"
              :value="newSuborder.description"
            />
          </label>
          <div class="absolute error-text" v-if="newSuborder.description?.length > 1000">Пожалуйста, введите не более 1000 символов</div>
          <div class="symbols-left" :class="{ error: newSuborder.description?.length > 1000 }">
            {{ newSuborder.description?.length || 0 }} / 1000
          </div>
        </div>
      </div>
    </template>
    <template #buttons>
      <div class="flex gap-3 justify-end">
        <UButton label="Отмена" color="secondary" @click="$emit('closeModal')" />
        <UButton
          label="Сохранить"
          :disabled="
            !newSuborder.price ||
            !newSuborder.quantity ||
            !newSuborder.name ||
            !suborderDates.start_date ||
            !suborderDates.finish_date ||
            newSuborder.description?.length > 1000 ||
            newSuborder.name?.length > 200 ||
            +newSuborder.price === 0 ||
            workerLimitError
          "
          @click="successAction"
        />
      </div>
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { formatDateSuborderModal, formatPrice } from '@/utils/helpers'
import { UTooltip, UModal, UButton, UISelect, UInput, UDateRange, UTextarea } from 'unit-uikit'
import d from '@/dictionary'
import { API } from '@/utils/API'

export default defineComponent({
  props: {
    show: Boolean,
    suborder: Object,
    tax: Boolean,
    limit: Number,
    limitTypes: Array,
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    UDateRange,
    UInput,
    UISelect,
    UTooltip,
    UButton,
    UModal,
    UTextarea
  },
  data() {
    return {
      quantityToChange: '',
      newSuborder: {} as any,
      suborderDates: {
        start_date: null,
        finish_date: null
      } as any,
      dateReady: false,
      unitTypesList: [],
      d
    }
  },
  created() {
    axios.get(API.GET_UNIT_TYPES).then((res: any) => {
      this.unitTypesList = res.data
    })

    const startDate = this.getStartDate(this.suborder)
    const finishDate = this.getFinishDate(this.suborder)
    if (startDate) {
      this.suborderDates.start_date = startDate
    }
    if (finishDate) {
      this.suborderDates.finish_date = finishDate
    }
    this.dateReady = true

    this.newSuborder.description = this.suborder?.description || ''
    this.newSuborder.name = this.suborder?.name || ''
    this.newSuborder.quantity = this.getQuantity(this.suborder)

    if (this.newSuborder && this.newSuborder.quantity && this.newSuborder.quantity.includes('.')) {
      this.quantityToChange = this.newSuborder.quantity.replace('.', ',')
    }

    this.newSuborder.price = this.getPrice(this.suborder)
    this.newSuborder.unit_type = this.getUnitType(this.suborder)
  },
  methods: {
    getStartDate(task: any) {
      return formatDateSuborderModal(task?.start_date || task?.initial_start_date)
    },
    getFinishDate(task: any) {
      return formatDateSuborderModal(task?.finish_date || task?.initial_finish_date)
    },
    getQuantity(task: any) {
      if (task.quantity && +task.quantity > 0) {
        return task.quantity
      }
      return task.initial_quantity
    },
    getUnitType(task: any) {
      return task.unit_type || task.unit_types?.id || task.unit_types
    },
    getPrice(task: any) {
      const price = (task.price + '').replaceAll(',', '.').replaceAll(' ', '')
      if (price && +price > 0) {
        return price
      }
      return task.initial_price
    },
    formatPrice(price: any = 0) {
      return formatPrice(price)
    },
    successAction() {
      this.newSuborder.start_date = this.suborderDates.start_date && this.suborderDates.start_date.valueOf()
      this.newSuborder.finish_date = this.suborderDates.finish_date && this.suborderDates.finish_date.valueOf()
      this.newSuborder.quantity = this.quantityToChange.replace(',', '.')
      this.$emit('successModal', this.newSuborder)
    }
  },
  computed: {
    workerLimitError() {
      let newSuborderPrice = this.newSuborder.price ? parseFloat(this.newSuborder.price.replaceAll(' ', '').replace(',', '.')) : 0
      newSuborderPrice = newSuborderPrice || 0
      if (this.tax) {
        const limit = this.suborder?.month_limit || this.suborder?.worker?.monthly_limit || 0
        return newSuborderPrice > limit
      } else {
        const limit = this.suborder?.month_limit_without_tax || this.suborder?.worker?.month_limit_without_tax || 0
        return newSuborderPrice > limit
      }
    },
    popupTitle() {
      if (this.title) {
        return this.title
      }
      return `Редактирование заказа ${this.orderid ? '№' + this.orderid : ''}`
    },
    orderid() {
      const id = this.suborder?.id
      return id || null
    },
    workerName() {
      const worker = this.suborder?.worker
      if (worker) {
        const name = worker?.worker_full_name
        return name
      }
      if (this.suborder?.last_name || this.suborder?.first_name || this.suborder?.middle_name) {
        return `${this.suborder.last_name || ''} ${this.suborder.first_name || ''} ${this.suborder.middle_name || ''}`
      }
      return ''
    },
    workerPhone() {
      if (this.suborder?.username) {
        return this.suborder.username
      }
      return ''
    }
  }
})
</script>

<style lang="postcss" scoped>
.long-modal :deep(.modal) {
  max-width: 780px;
}

.error-text {
  @apply text-xs leading-snug text-error;
}

.symbols-left {
  @apply text-xs text-grey1 absolute right-0 -bottom-3.5;
  &.error {
    @apply text-error;
  }
}

.error-txt :deep(input) {
  @apply text-error;
}
.error-txt {
  @apply text-error;
}

.error-wrapper {
  @apply text-sm font-normal leading-snug;
}
</style>
