<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { UModal, UButton } from 'unit-uikit'
import { SUBORDER_CHANGE_REASON } from '@/utils/consts'
import moment from 'moment'
import axios from 'axios'
import { API } from '@/utils/API'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()
const declinedBy = ref('')
const userId = computed(() => {
  return authStore.payload?.user_id
})

const props = defineProps({
  order: {
    type: Object,
    default: null
  },
  taskReturns: {
    type: Object,
    default: null
  }
})

const emits = defineEmits(['closeModal'])

const cancelReason = computed(() => {
  const _returns = props.taskReturns?.returns
  if (_returns && _returns.length) {
    const comment = _returns[0]?.comment
    if (comment) {
      return comment
    }
    const _reason = _returns[0]?.reason
    if (_reason && _reason[0]) {
      const _r = SUBORDER_CHANGE_REASON.find((item) => {
        return item.value === _reason[0]
      })
      return (_r && _r.label) || 'Другая причина'
    }
  }
  return ''
})

const type = computed(() => {
  const _returns = props.taskReturns?.returns
  return _returns && _returns[0]?.initiator_type
})

const getWokerInfo = async (id: string | number) => {
  const result = await axios.get(API.SINGLE_WORKER(id))
  return result
}

const initiator = computed(() => {
  if (!userId.value) {
    return null
  }
  const _returns = props.taskReturns?.returns
  const _initiator = _returns && _returns[0]?.initiator
  return _initiator
})

const date = computed(() => {
  const _returns = props.taskReturns?.returns
  const _created = _returns && _returns[0]?.created
  if (_created) {
    return moment(new Date(_created)).format('DD.MM.YYYY HH:mm')
  }
  return 'Отсутствует'
})

const title = computed(() => {
  if (type.value === 'worker') {
    return 'Отклонено исполнителем'
  }

  return 'Отклонено'
})

const closeModal = () => {
  emits('closeModal')
}

watch(initiator, async () => {
  const _initiator = initiator.value
  if (userId.value === _initiator) {
    declinedBy.value = `Заказчик отклонил Заказ №${props.taskReturns?.order} ${props.order?.name}`
  }
  if (_initiator && type.value === 'worker') {
    const { data } = await getWokerInfo(_initiator)
    const fio = `${data?.last_name || ''} ${data?.first_name || ''} ${data?.middle_name || ''}`
    declinedBy.value = `Исполнитель ${fio} отклонил Заказ №${props.taskReturns?.id} ${props.taskReturns?.description}`
  }
})
</script>

<template>
  <UModal :show="cancelReason?.length" :title="title" @cancel="closeModal">
    <template #content>
      <p>{{ declinedBy }}</p>
      <p><span class="font-bold">Дата: </span>{{ date }}</p>
      <p><span class="font-bold">Причина: </span>{{ cancelReason }}</p>
    </template>
    <template #buttons>
      <UButton class="w-full" label="Хорошо" @click="closeModal" />
    </template>
  </UModal>
</template>

.
<style lang="postcss" scoped>
:deep(.modal .-content) {
  gap: 6px !important;
}
</style>
