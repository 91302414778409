<script setup lang="ts">
import { ref } from 'vue'
import { UISelect } from 'unit-uikit'
import { EOmvdNotificationStatus } from './omvd-helpers'

const props = defineProps({
  ids: {
    type: Array,
    default: []
  },
  withSelect: {
    type: Boolean,
    default: false
  },
  disableBtns: {
    type: Boolean,
    default: false
  },
  initialDocType: {
    type: String,
    default: ''
  }
})

const options = [
  {
    id: '7',
    name: '№7. Уведомление о подписании договора'
  },
  {
    id: '8',
    name: '№8. Уведомление о расторжении договора'
  }
]

const docType = ref(props.initialDocType)

const emits = defineEmits(['clearChecked', 'switchStatus'])

const emitAction = (type: string, status: EOmvdNotificationStatus) => {
  if (!props.withSelect) {
    emits('switchStatus', { type, status })
  } else if (type) {
    emits('switchStatus', { type, status })
  }
}
</script>

<template>
  <div class="actions-container flex items-center gap-5 pl-3 pr-3 h-[68px] shrink-0">
    <p>Выбрано исполнителя: {{ ids.length }}</p>
    <template v-if="ids.length">
      <button class="clear-btn" type="button" @click="emits('clearChecked')">Снять выделение</button>
      <div class="flex-grow">
        <UISelect
          id="docTypeSelector"
          v-if="withSelect"
          class="!w-auto"
          label="Уведомление"
          unselectedLabel="Выберите документ"
          v-model="docType"
          :value="docType"
          :options="options"
        />
        <p v-else>№7. Уведомление о подписании договора</p>
      </div>
      <button class="success-btn" type="button" :disabled="disableBtns" @click="emitAction(docType, EOmvdNotificationStatus.filed)">
        Подано
      </button>
      <span>|</span>
      <button class="reject-btn" type="button" :disabled="disableBtns" @click="emitAction(docType, EOmvdNotificationStatus.rejected)">
        Отклонено
      </button>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.clear-btn {
  @apply text-accent;
}
.clear-btn:hover {
  @apply text-accentDarker;
}

.success-btn {
  @apply text-success;
}
.success-btn:hover {
  @apply text-success/80;
}

.reject-btn {
  @apply text-error;
}
.reject-btn:hover {
  @apply text-error/80;
}

.actions-container {
  @apply bg-white shadow-main;
  border-radius: 8px 8px 0px 0px;
}

:deep(.select__title) {
  @apply hidden;
}
:deep(.select select) {
  @apply mt-0;
}
</style>
