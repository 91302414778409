<template>
  <AuthLayout>
    <template #content>
      <div class="registration-page__half--wrap overflow-auto">
        <BackMobileBtn :label="regType" backURL="staff.inn" :backCustomAction="prevStep" />
        <h2 v-if="!isRegDataComplete && step !== 0" class="text-grey mt-1 mb-6 text-sm">
          Шаг {{ step }} из 2 {{ step === 1 ? 'Основная информация' : 'Банковские реквизиты' }}
        </h2>
        <div v-else class="mt-1 mb-6"></div>

        <div v-if="step === 1 && isIE && !isLLC" class="flex flex-col gap-4">
          <div class="relative">
            <UInput placeholder="Ваше ФИО" class="!w-full" :value="regDataIE.fio" v-model="regDataIE.fio" />
            <div class="validate-error" v-if="v$.regDataIE.fio.$dirty && v$.regDataIE.fio.requiredIfIE.$invalid">Обязательное поле</div>
            <div class="validate-error" v-if="v$.regDataIE.fio.maxLength.$invalid">Максимальная длинна поля составляет 150 символов</div>
          </div>
          <div class="relative">
            <UInput class="!w-full" placeholder="Номер телефона" :value="regDataIE.phone" v-model="regDataIE.phone" mask="phone" readonly />
            <div class="validate-error" v-if="v$.regDataIE.phone.$dirty && v$.regDataIE.phone.requiredIfIE.$invalid">Обязательное поле</div>
            <div class="validate-error" v-if="v$.regDataIE.phone.minLength.$invalid">Введите корректный номер телефона</div>
          </div>
          <div class="relative">
            <UInput
              class="!w-full"
              placeholder="ИНН"
              @input="checkINNorOGRN(regDataIE.INN, true, true)"
              :value="regDataIE.INN"
              v-model="regDataIE.INN"
              mask="innRegIE"
            />
            <div class="validate-error" v-if="errorMsgINN">{{ errorMsgINN }}</div>
            <div class="validate-error" v-if="v$.regDataIE.INN.$dirty && v$.regDataIE.INN.requiredIfIE.$invalid">Обязательное поле</div>
            <div class="validate-error" v-if="v$.regDataIE.INN.minLength.$invalid">ИНН состоит из 12 цифр</div>
          </div>
          <div class="relative">
            <UInput class="!w-full" placeholder="Наименование ИП" :value="regDataIE.title" v-model="regDataIE.title" />
            <div class="validate-error" v-if="v$.regDataIE.title.$dirty && v$.regDataIE.title.requiredIfIE.$invalid">Обязательное поле</div>
            <div class="validate-error" v-if="v$.regDataIE.title.maxLength.$invalid">Максимальная длинна поля составляет 150 символов</div>
          </div>
          <div class="relative">
            <UInput
              class="!w-full"
              @input="checkINNorOGRN(regDataIE.number, false, true)"
              placeholder="ОГРНИП"
              :value="regDataIE.number"
              v-model="regDataIE.number"
              mask="regDataIENumber"
            />
            <div class="validate-error" v-if="errorMsgOGRN">{{ errorMsgOGRN }}</div>
            <div class="validate-error" v-if="v$.regDataIE.number.$dirty && v$.regDataIE.number.requiredIfIE.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataIE.number.minLength.$invalid">ОГРНИП состоит из 15 цифр</div>
          </div>
          <div class="flex flex-col gap-4">
            <UButton
              id="nextBtn"
              label="Далее"
              :disabled="v$.regDataIE.$invalid || errorMsgINN !== '' || errorMsgOGRN !== ''"
              @click="nextStep()"
            />
            <UButton label="У меня уже есть аккаунт" color="secondary" @click.prevent="$router.push('/')" />
          </div>
        </div>

        <div class="flex flex-col gap-4" v-if="step === 1 && !isIE && isLLC">
          <div class="relative">
            <UInput class="!w-full" placeholder="ФИО ген. директора" :value="regDataLLC.directorFio" v-model="regDataLLC.directorFio" />
            <div class="validate-error" v-if="v$.regDataLLC.directorFio.$dirty && v$.regDataLLC.directorFio.requiredIfLLC.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataLLC.directorFio.maxLength.$invalid">
              Максимальная длинна поля составляет 150 символов
            </div>
          </div>
          <div class="relative">
            <UInput
              class="!w-full"
              placeholder="Номер телефона"
              :value="regDataLLC.phone"
              v-model="regDataLLC.phone"
              mask="phone"
              readonly
            />
            <div class="validate-error" v-if="v$.regDataLLC.phone.$dirty && v$.regDataLLC.phone.requiredIfLLC.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataLLC.phone.minLength.$invalid">Введите корректный номер телефона</div>
          </div>
          <div class="relative">
            <UInput
              @change="getDataByINN"
              class="!w-full"
              @input="checkINNorOGRN(regDataLLC.INN, true, false)"
              placeholder="ИНН"
              :value="regDataLLC.INN"
              v-model="regDataLLC.INN"
              mask="innRegLLC"
            />
            <div class="validate-error" v-if="errorMsgINN">{{ errorMsgINN }}</div>
            <div class="validate-error" v-if="v$.regDataLLC.INN.$dirty && v$.regDataLLC.INN.requiredIfLLC.$invalid">Обязательное поле</div>
            <div class="validate-error" v-if="v$.regDataLLC.INN.minLength.$invalid">ИНН состоит из 10 цифр</div>
          </div>
          <div class="relative">
            <UInput class="!w-full" placeholder="Наименование ООО" :value="regDataLLC.title" v-model="regDataLLC.title" />
            <div class="validate-error" v-if="v$.regDataLLC.title.$dirty && v$.regDataLLC.title.requiredIfLLC.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataLLC.title.maxLength.$invalid">Максимальная длинна поля составляет 150 символов</div>
          </div>
          <div class="relative">
            <UInput
              class="!w-full"
              @input="checkINNorOGRN(regDataLLC.number, false, false)"
              placeholder="ОГРН"
              :value="regDataLLC.number"
              v-model="regDataLLC.number"
              mask="regDataLLCNumber"
            />
            <div class="validate-error" v-if="errorMsgOGRN">{{ errorMsgOGRN }}</div>
            <div class="validate-error" v-if="v$.regDataLLC.number.$dirty && v$.regDataLLC.number.requiredIfLLC.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataLLC.number.minLength.$invalid">ОГРН состоит из 13 цифр</div>
          </div>

          <div class="relative">
            <UInput
              class="!w-full"
              :disabled="isFioEqual"
              placeholder="ФИО представителя*"
              :value="regDataLLC.personalFio"
              v-model="regDataLLC.personalFio"
            />
            <div class="validate-error" v-if="v$.regDataLLC.personalFio.$dirty && v$.regDataLLC.personalFio.requiredIfLLC.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataLLC.personalFio.maxLength.$invalid">
              Максимальная длинна поля составляет 150 символов
            </div>
          </div>
          <span class="text-grey1 block mb-3">*Если вы представитель компании</span>
          <UICheckbox
            v-show="regDataLLC.directorFio"
            v-model="isFioEqual"
            :value="isFioEqual"
            @click="directorFioToPersonalFio()"
            class="mb-1"
            id="fio"
            label="Данные представителя совпадают"
          />
          <div class="flex flex-col gap-4">
            <UButton
              id="nextBtn"
              label="Далее"
              :disabled="v$.regDataLLC.$invalid || errorMsgINN !== '' || errorMsgOGRN !== ''"
              @click="nextStep()"
            />
            <UButton label="У меня уже есть аккаунт" color="secondary" @click.prevent="$router.push('/')" />
          </div>
        </div>

        <div v-if="!isRegDataComplete && step === 2" class="flex flex-col gap-4 overflow-auto">
          <div class="relative">
            <UInput
              @change="getDataByBic"
              class="!w-full"
              v-if="isIE"
              placeholder="БИК"
              :value="regDataIE.BIC"
              v-model="regDataIE.BIC"
              mask="regDataBIC"
            />
            <UInput
              @change="getDataByBic"
              class="!w-full"
              v-else-if="isLLC"
              placeholder="БИК"
              :value="regDataLLC.BIC"
              v-model="regDataLLC.BIC"
              mask="regDataBIC"
            />
            <div class="validate-error" v-if="v$.regDataIE.BIC.$dirty && v$.regDataIE.BIC.requiredIfIE.$invalid">Обязательное поле</div>
            <div class="validate-error" v-if="v$.regDataLLC.BIC.$dirty && v$.regDataLLC.BIC.requiredIfLLC.$invalid">Обязательное поле</div>
            <div class="validate-error" v-if="v$.regDataIE.BIC.minLength.$invalid || v$.regDataLLC.BIC.minLength.$invalid">
              БИК состоит из 9 цифр
            </div>
          </div>
          <div class="relative">
            <UInput
              class="!w-full"
              v-if="isIE"
              placeholder="Наименование банка"
              :value="regDataIE.bankTitle"
              v-model="regDataIE.bankTitle"
            />
            <UInput
              class="!w-full"
              v-else-if="isLLC"
              placeholder="Наименование банка"
              :value="regDataLLC.bankTitle"
              v-model="regDataLLC.bankTitle"
            />
            <div class="validate-error" v-if="v$.regDataIE.bankTitle.$dirty && v$.regDataIE.bankTitle.requiredIfIE.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataLLC.bankTitle.$dirty && v$.regDataLLC.bankTitle.requiredIfLLC.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataIE.bankTitle.maxLength.$invalid || v$.regDataLLC.bankTitle.maxLength.$invalid">
              Максимальная длинна поля составляет 150 символов
            </div>
          </div>
          <div class="relative">
            <UInput
              class="!w-full"
              v-if="isIE"
              placeholder="Расчетный счет"
              :value="regDataIE.checkingAccount"
              v-model="regDataIE.checkingAccount"
              mask="regDatacheckingAccount"
            />
            <UInput
              class="!w-full"
              v-else-if="isLLC"
              placeholder="Расчетный счет"
              :value="regDataLLC.checkingAccount"
              v-model="regDataLLC.checkingAccount"
              mask="regDatacheckingAccount"
            />
            <div class="validate-error" v-if="v$.regDataIE.checkingAccount.$dirty && v$.regDataIE.checkingAccount.requiredIfIE.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-if="v$.regDataLLC.checkingAccount.$dirty && v$.regDataLLC.checkingAccount.requiredIfLLC.$invalid">
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-if="v$.regDataIE.checkingAccount.minLength.$invalid || v$.regDataLLC.checkingAccount.minLength.$invalid"
            >
              Р/С состоит из 20 цифр
            </div>
          </div>
          <div class="relative">
            <UInput
              class="!w-full"
              v-if="isIE"
              placeholder="Корреспондентский счет"
              :value="regDataIE.correspondentAccount"
              v-model="regDataIE.correspondentAccount"
              mask="regDatacheckingAccount"
            />
            <UInput
              class="!w-full"
              v-else-if="isLLC"
              placeholder="Корреспондентский счет"
              :value="regDataLLC.correspondentAccount"
              v-model="regDataLLC.correspondentAccount"
              mask="regDatacheckingAccount"
            />
            <div
              class="validate-error"
              v-if="v$.regDataIE.correspondentAccount.$dirty && v$.regDataIE.correspondentAccount.requiredIfIE.$invalid"
            >
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-if="v$.regDataLLC.correspondentAccount.$dirty && v$.regDataLLC.correspondentAccount.requiredIfLLC.$invalid"
            >
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-if="v$.regDataIE.correspondentAccount.minLength.$invalid || v$.regDataLLC.correspondentAccount.minLength.$invalid"
            >
              К/С состоит из 20 цифр
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <UButton
              id="regFinishBtn"
              label="Зарегистрироваться"
              :disabled="(isIE && v$.regDataIE.$invalid) || (isLLC && v$.regDataLLC.$invalid)"
              @click="regDataComplete()"
            />
            <UButton label="У меня уже есть аккаунт" color="secondary" @click.prevent="$router.push('/')" />
          </div>
          <span v-if="apiErrorText" class="validate-error !static">{{ apiErrorText }}</span>
        </div>

        <div class="tracking-title text-md" v-if="isRegDataComplete">
          <p class="mb-6">
            Спасибо за регистрацию! Для начала работы на платформе необходимо пройти верификацию компании и выпустить цифровую подпись.
          </p>
          <h2 class="font-medium mb-2">Этапы верификации:</h2>
          <ol class="list-decimal pl-4 mb-6">
            <li>Верификационный звонок</li>
            <li>Отправка документов</li>
            <li>Проверка компании</li>
          </ol>
          <p class="mb-6">В ближайшее время с вами свяжется наш специалист для назначения верификационного звонка и разъяснения деталей.</p>
          <UButton label="Вернуться на главную" color="secondary" @click.prevent="$router.push('/')" class="!w-full" />
        </div>

        <div v-if="orgTypeBtns" class="flex flex-col gap-4">
          <UButton id="ipRegBtn" label="ИП" @click="signUpIE" class="" />
          <UButton id="OOORegBtn" label="ООО" @click="signUpLLC" class="" />
          <UButton label="У меня уже есть аккаунт" color="secondary" @click.prevent="$router.push('/')" />
        </div>
      </div>
    </template>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { API } from '@/utils/API'
import axios from 'axios'
import { getSuggestionsByInn, getBankByBic } from '@/utils/dadata'
import useValidate from '@vuelidate/core'
import { maxLength, minLength, requiredIf } from '@vuelidate/validators'
import AuthLayout from '@/views/Registration/AuthLayout.vue'
import { UButton, UInput, UICheckbox } from 'unit-uikit'
import BackMobileBtn from '@/components/UIElements/BackMobileBtn.vue'

const fioValidation = (fio: string) =>
  (fio.split(' ').length === 3 && fio.split(' ')[2] !== '') ||
  (fio.split(' ').length === 2 && fio.split(' ')[3] !== '' && fio.split(' ')[1] !== '')

const fioValidationIE = (fio: string) => {
  const _parts = fio.trim().split(' ')
  return _parts.length >= 3 && _parts[0].length && _parts[1].length && _parts[2].length
}

export default defineComponent({
  components: {
    UICheckbox,
    AuthLayout,
    UButton,
    UInput,
    BackMobileBtn
  },
  data() {
    return {
      v$: useValidate(),
      step: 0,
      orgTypeBtns: true,
      isIE: false,
      isLLC: false,
      isRegDataComplete: false,
      isFioEqual: false,
      apiErrorText: '',
      errorMsgINN: '',
      errorMsgOGRN: '',
      regDataIE: {
        fio: '',
        phone: localStorage.getItem('userPhone'),
        title: '',
        number: '',
        INN: '',
        bankTitle: '',
        checkingAccount: '',
        correspondentAccount: '',
        BIC: ''
      } as any,
      regDataLLC: {
        directorFio: '',
        phone: localStorage.getItem('userPhone'),
        title: '',
        number: '',
        INN: '',
        personalFio: '',
        bankTitle: '',
        checkingAccount: '',
        correspondentAccount: '',
        BIC: '',
        kpp: '',
        short_name: '',
        address: '',
        registered_address: '',
        okved: ''
      } as any
    }
  },
  validations() {
    return {
      regDataIE: {
        fio: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          maxLength: maxLength(150),
          fioValidationIE
        },
        phone: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          minLength: minLength(16)
        },
        title: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          maxLength: maxLength(150)
        },
        number: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          minLength: minLength(15)
        },
        INN: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          minLength: minLength(12)
        },
        bankTitle: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          maxLength: maxLength(150)
        },
        checkingAccount: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          minLength: minLength(20)
        },
        correspondentAccount: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          minLength: minLength(20)
        },
        BIC: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          minLength: minLength(9)
        }
      },
      regDataLLC: {
        directorFio: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          maxLength: maxLength(150),
          fioValidation
        },
        phone: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          minLength: minLength(12)
        },
        title: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          maxLength: maxLength(150)
        },
        number: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          minLength: minLength(13)
        },
        INN: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          minLength: minLength(10)
        },
        personalFio: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          maxLength: maxLength(150),
          fioValidation
        },
        bankTitle: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          maxLength: maxLength(150)
        },
        checkingAccount: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          minLength: minLength(20)
        },
        correspondentAccount: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          minLength: minLength(20)
        },
        BIC: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          minLength: minLength(9)
        }
      }
    }
  },
  methods: {
    getSuggestionsByInn,
    getBankByBic,
    signUpIE() {
      this.step = 1
      this.orgTypeBtns = false
      this.isIE = true
      this.isLLC = false
    },
    signUpLLC() {
      this.step = 1
      this.orgTypeBtns = false
      this.isLLC = true
      this.isIE = false
    },
    nextStep() {
      // this.v$.$validate()

      // if (!this.v$.$error) {
      this.step = 2
      // }
    },
    prevStep() {
      if (this.step === 0) {
        this.$router.push({
          name: 'login'
        })
        return
      }
      if (this.step === 2) {
        this.step = 1
      } else if (this.step === 1) {
        this.isLLC = false
        this.isIE = false
        this.step = 0
        this.orgTypeBtns = true
      }
    },
    directorFioToPersonalFio() {
      if (this.regDataLLC.directorFio) {
        this.regDataLLC.personalFio = this.regDataLLC.directorFio
      }
    },
    regDataComplete() {
      this.v$.$validate()
      const fio = this.isIE ? this.regDataIE.fio.split(' ') : this.regDataLLC.directorFio.split(' ')
      const companyRepresentativeFio = this.regDataLLC.personalFio.split(' ')

      const regData: any = {
        phone: this.isIE ? this.regDataIE.phone.replaceAll('-', '') : this.regDataLLC.phone.replaceAll('-', ''),
        code: sessionStorage.getItem('regSmsCode'), // TODO
        account_type: 'customer',
        username: this.isIE ? this.regDataIE.phone.replaceAll('-', '') : this.regDataLLC.phone.replaceAll('-', ''),
        first_name: fio[1],
        last_name: fio[0],
        middle_name: fio[2],
        representative_first_name: companyRepresentativeFio[1] || fio[1],
        representative_last_name: companyRepresentativeFio[0] || fio[0],
        representative_middle_name: companyRepresentativeFio[2] || fio[2],
        company_name: this.isIE ? this.regDataIE.title : this.regDataLLC.title,
        ogrn: this.isIE ? this.regDataIE.number : this.regDataLLC.number,
        inn: this.isIE ? this.regDataIE.INN : this.regDataLLC.INN,
        bank: this.isIE ? this.regDataIE.bankTitle : this.regDataLLC.bankTitle,
        bik: this.isIE ? this.regDataIE.BIC : this.regDataLLC.BIC,
        checking_account: this.isIE ? this.regDataIE.checkingAccount : this.regDataLLC.checkingAccount,
        correspondent_account: this.isIE ? this.regDataIE.correspondentAccount : this.regDataLLC.correspondentAccount,
        type: this.isIE ? 'Индивидуальный Предприниматель' : 'Общество с Ограниченной Ответственностью',
        kpp: this.regDataLLC.kpp,
        short_name: this.regDataLLC.short_name ? this.regDataLLC.short_name : this.regDataLLC.title,
        address: this.regDataLLC.address,
        registered_address: this.regDataLLC.registered_address,
        okved: this.regDataLLC.okved
      }

      if (!this.regDataLLC.kpp) {
        delete regData.kpp
      }
      if (this.isIE) {
        delete regData.kpp
        delete regData.short_name
        delete regData.address
        delete regData.registered_address
        delete regData.okved
      }

      axios
        .post(API.SIGNUP_ENTITY, regData)
        .then((response: any) => {
          if (response.status === 200) {
            this.isRegDataComplete = true
            this.apiErrorText = ''
          }
        })
        .catch((error: any) => {
          console.error(error.response)
          const data = error.response.data
          const text = (data.username && data.username[0]) || (data.okved && data.okved[0])
          this.apiErrorText = text
        })
    },
    checkINNorOGRN(data: string, isINN: boolean, isIE: boolean) {
      const innLengthIE = 12
      const ogrnLengthIE = 15
      const innLengthLLC = 10
      const ogrnLengthLLC = 13
      if (data && (data.length < innLengthIE || data.length < ogrnLengthIE || data.length < innLengthLLC || data.length < ogrnLengthLLC)) {
        isINN ? (this.errorMsgINN = '') : (this.errorMsgOGRN = '')
      }
      if (
        (data && data.length === innLengthIE && isIE) ||
        (data && data.length === ogrnLengthIE && isIE) ||
        (data && data.length === innLengthLLC && !isIE) ||
        (data && data.length === ogrnLengthLLC && !isIE)
      ) {
        axios
          .post(API.CHECK_INN_OGRN, { [isINN ? 'inn' : 'ogrn']: data })
          .then(() => {
            isINN ? (this.errorMsgINN = '') : (this.errorMsgOGRN = '')
          })
          .catch((error: any) => {
            if (error && error.response.data) {
              isINN
                ? (this.errorMsgINN = error.response.data.non_field_errors[0].inn)
                : (this.errorMsgOGRN = error.response.data.non_field_errors[0].ogrn)
            }
          })
      }
    }
  },
  computed: {
    getDataByINN() {
      if (this.regDataLLC.INN.length === 10) {
        getSuggestionsByInn(this.regDataLLC.INN, (response: any) => {
          let orgData = response.data.suggestions?.[0]?.data
          this.regDataLLC.title = orgData.name?.short_with_opf
          this.regDataLLC.number = orgData.ogrn
          this.regDataLLC.address = orgData.address?.value
          this.regDataLLC.registered_address = orgData.address?.unrestricted_value
          this.regDataLLC.short_name = orgData.name?.short_with_opf
          this.regDataLLC.kpp = orgData.kpp

          const okvedRegexp = /^\d{2}(\.\d{1,2})?(\.\d{1,2})?$/
          if (orgData.okved.match(okvedRegexp)) {
            this.regDataLLC.okved = orgData.okved
          } else {
            this.regDataLLC.okved = ''
          }
        })
      }
    },
    getDataByBic() {
      if (this.regDataLLC.BIC.length === 9) {
        getBankByBic(this.regDataLLC.BIC, (response: any) => {
          this.regDataLLC.bankTitle = response.data.suggestions?.[0]?.data.name?.payment
          this.regDataLLC.correspondentAccount = response.data.suggestions?.[0]?.data.correspondent_account
        })
      } else if (this.regDataIE.BIC.length === 9) {
        getBankByBic(this.regDataIE.BIC, (response: any) => {
          this.regDataIE.bankTitle = response.data.suggestions?.[0]?.data.name?.payment
          this.regDataIE.correspondentAccount = response.data.suggestions?.[0]?.data.correspondent_account
        })
      }
    },
    regType() {
      let title = ''
      if (!this.isIE && !this.isLLC) {
        title = 'Регистрация'
      } else if (this.isIE && !this.isLLC) {
        title = 'Регистрация индивидуального предпринимателя'
      } else if (!this.isIE && this.isLLC) {
        title = 'Регистрация юридического лица'
      }
      return title
    }
  }
})
</script>

<style lang="postcss" scoped>
.registration-page {
  &__half--wrap {
    @apply flex flex-col w-[350px] mb-auto mt-0 md:my-0 mx-auto;
  }

  &__btns {
    & button {
      @apply h-[60px];
    }
  }
}

.input-container {
  @apply !pl-0 !h-[52px];
  .input {
    @apply mt-0 h-[50px] leading-[50px] text-md;
  }
}

.validate-error {
  @apply absolute top-[3.25rem] text-xs text-error;
}

:deep(.registration-page__half.-left) {
  @apply md:pb-[20px];
}
</style>
