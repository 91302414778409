<template>
  <AuthLayout>
    <template #content>
      <div class="img-wrap">
        <img src="/icons/logo.svg" alt="Logo UNIT" class="brand-logo" />
      </div>
      <div class="registration-page__half--wrap">
        <h1 class="title self-start mb-8">Выбор организации</h1>
        <p class="subtitle mb-6">Для продолжения необходимо выбрать организацию, с которой вы работаете</p>
        <div class="login-form mb-4">
          <select @change="choosenCompany(+$event.target.value)" name="company" id="company" class="company" v-model="id" required>
            <option selected disabled value="">Список организаций</option>
            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
          </select>
        </div>
        <UButton class="!w-full" label="Продолжить" @click.prevent="selectCompany" />
      </div>
    </template>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UButton } from 'unit-uikit'
import { API } from '@/utils/API'
import axios from 'axios'
import AuthLayout from '@/views/Registration/AuthLayout.vue'
import { EAccountTypes } from '@/types/api-values'
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
  components: {
    UButton,
    AuthLayout
  },
  data() {
    return {
      id: '',
      companies: [] as any
    }
  },
  methods: {
    choosenCompany(id: any) {
      this.companies.forEach((company: any) => {
        if (company.id === id) {
          sessionStorage.setItem('companyType', company.type)
        }
      })
    },
    async selectCompany() {
      const authStore = useAuthStore()
      if (this.id) {
        axios
          .post(API.AUTH_CHOOSECOMPANY, {
            company_id: this.id
          })
          .then(async (response: any) => {
            const status = response?.status
            if (status === 201 || status === 200) {
              const newAccessToken = response?.data?.updated_access_token
              const jwtString = localStorage.getItem('JWT')
              const jwt = jwtString && JSON.parse(jwtString)
              const newJWT = {
                access_token: newAccessToken,
                refresh_token: jwt?.refresh_token
              }

              if (newJWT && newJWT.access_token) {
                authStore.saveJWT(newJWT)
              }

              const payload = authStore.payload
              const type = payload?.user_type
              const verified = payload?.user_verified
              const companyVerified = payload?.company_is_verified
              // const companyId = payload.company_id
              // const edoEnabled = payload.company_staff_status_to_sign

              if (type === EAccountTypes.staff) {
                if (verified === false) {
                  this.$router.push({
                    name: 'staff.notready'
                  })
                  return
                }
                // redirect to edo app
                window.location.pathname = '/edo'
                return
              }

              if (type === EAccountTypes.customer && companyVerified !== true) {
                this.$router.push({
                  name: 'patents.current'
                })
                return
              }

              // type of account in not allowed to use app
              if (type !== EAccountTypes.customer && type !== EAccountTypes.staff) {
                authStore.logout()
                return
              }

              // Select successful
              this.$router.push('/')
            }
          })
          .catch(() => {})
      }
    }
  },
  created() {
    axios
      .get(API.AUTH_CHOOSECOMPANY)
      .then((response: any) => {
        const companies = response?.data
        if (companies && Array.isArray(companies)) {
          this.companies = companies
        } else {
          this.$router.push('/')
        }
      })
      .catch(() => {
        this.$router.push('/')
      })
  }
})
</script>

<style lang="postcss" scoped>
.img-wrap {
  @apply md:w-[350px] md:mx-auto;
}

.brand-logo {
  @apply h-7 w-auto object-contain object-left-top mb-5 mr-auto;
}

.registration-page {
  &__half {
    &--wrap {
      @apply flex flex-col w-[350px] items-center mb-auto mt-0 flex-grow md:my-0 mx-auto md:justify-center;
    }
  }
}
.login-form {
  @apply flex flex-col md:flex-grow-0;
}

.subtitle {
  @apply text-sm text-grey self-start leading-5;
}

.company {
  @apply w-[350px] border rounded-lg h-11 py-0 px-6 text-sm leading-4 tracking-title;
}

.title {
  @apply font-medium text-2xl leading-5;
}
</style>
