<template>
  <div v-if="agreement">
    <p class="whitespace-nowrap">От {{ formatDate(agreement.created) }} до {{ formatDate(agreement.exp_date) }}</p>
    <p class="font-normal text-xs1 left-3 text-grey mt-1">№ {{ agreement.id }}</p>
  </div>
  <div v-else>Отсутствует</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    worker: Object
  },
  methods: {
    formatDate(date: any) {
      try {
        return moment(new Date(date)).format('DD.MM.YYYY')
      } catch (e) {
        return ''
      }
    }
  },
  computed: {
    agreement() {
      const agreement = this.worker?.agreement
      return agreement && agreement[0]
    }
  }
})
</script>
