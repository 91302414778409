<template>
  <main-page-header title="Новый сотрудник" class="page-title"></main-page-header>
  <p class="workers-add-title">Вы можете добавить нового сотрудника, пригласив его по реферальной ссылке</p>
  <UTabs :tabs="tabs" />
  <router-view v-slot="{ Component }">
    <keep-alive include="InvitePersonal">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs } from 'unit-uikit'

export default defineComponent({
  name: 'AddWorkerWays',
  components: {
    MainPageHeader,
    UTabs
  },
  data() {
    return {
      tabs: [
        {
          routeName: 'personal.add.new',
          label: 'Приглашение сотрудника'
        },
        {
          routeName: 'personal.add.invited',
          label: 'Список приглашений'
        }
      ]
    }
  }
})
</script>

<style lang="postcss" scoped>
.workers-add-title {
  @apply text-sm font-medium h-5 leading-5 align-top -mt-10 mb-5;
}
</style>
