<template>
  <div class="flex flex-wrap lg:flex-nowrap overflow-hidden">
    <div class="flex-grow mr-4 popup-slider overflow-hidden" v-if="thereAreFiles">
      <div class="image mb-2">
        <img :src="getFileUrl" alt="Photo result" class="popup-image" />
      </div>
      <div class="thumbnails">
        <div class="popup-thumbnail" v-for="(doc, idDoc) in allFiles" :key="idDoc" @click="activeFileIndex = idDoc">
          <img :src="getPreviewFileUrl(doc.file)" alt="any image" class="item" />
        </div>
      </div>
    </div>
    <div class="flex-grow mr-4 popup-slider" v-else>
      <h3 class="text-center">Заказ создан без добавления фото</h3>
      <div class="image">
        <img src="/img/placeholders/image-placeholder.svg" alt="Photo result" class="popup-image -empty" />
      </div>
    </div>
    <div class="flex flex-col min-w-[260px] max-w-[300px] grow">
      <div class="input-wrapper mb-4">
        <h3 class="input-wrapper__title">Дата загрузки</h3>
        <p class="input-wrapper__element mt-2">{{ formatDate(results[0]?.created) || 'Нет данных' }}</p>
      </div>

      <div class="input-wrapper flex-grow mb-4">
        <h3 class="input-wrapper__title">Комментарий от исполнителя</h3>
        <p class="input-wrapper__element mt-2">{{ results[0]?.comment || 'Нет данных' }}</p>
      </div>

      <div>
        <p class="text-xs1 leading-5 text-grey mb-3">
          Если результат выполненных работ не устраивает, то можно попросить исполнителя переделать заказ. В этом случае нужно указать что
          именно, для этого нажмите кнопку <b>"Вернуть в работу"</b>.
        </p>
        <div class="flex">
          <UButton label="Вернуть в работу" color="secondary" @click.prevent="returnToWorkAction" class="mr-3" />
          <UButton label="Ок" @click.prevent="closeAction" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { UButton } from 'unit-uikit'

export default defineComponent({
  components: {
    UButton
  },
  props: {
    results: Array as any,
    closeAction: Function,
    returnToWorkAction: Function
  },
  data() {
    return {
      activeFileIndex: 0
    }
  },
  methods: {
    getPreviewFileUrl(file: string) {
      if (file) {
        const URL_CLEAR = process.env.VUE_APP_URL
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    },
    formatDate(date: any) {
      if (date) {
        return moment(new Date(date)).format('DD.MM.YY')
      }
      return ''
    }
  },
  computed: {
    allFiles() {
      return (this.results && this.results[0] && this.results[0].documents) || []
    },
    thereAreFiles() {
      const files = this.allFiles
      return !!(files && files.length)
    },
    getFileUrl() {
      const files = this.allFiles
      const file = files && files[this.activeFileIndex] && files[this.activeFileIndex].file
      if (file) {
        const URL_CLEAR = process.env.VUE_APP_URL
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    }
  }
})
</script>

<style lang="postcss" scoped>
.popup-slider {
  @apply flex flex-col;

  & > .image {
    @apply flex justify-center items-center max-h-[400px];
  }

  & > .thumbnails {
    @apply flex justify-center;
  }
}

.popup-thumbnail {
  @apply mx-[6px] w-12 h-12 overflow-hidden;

  & > .item {
    @apply h-full w-full object-contain;
  }
}

.popup-image {
  @apply h-full;

  &.-empty {
    @apply mb-10 object-cover h-52;
  }
}
</style>
