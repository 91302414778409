<template>
  <div class="flex items-center justify-center flex-col fixed z-[9999] inset-0 bg-grey1/40 pointer-events-none">
    <div class="content zoom">
      <span>U</span>
      <span>N</span>
      <span>I</span>
      <span>T</span>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.content {
  @apply font-light text-black text-2xl text-center antialiased;
  font-family: Arial;
  letter-spacing: 0.2em;
}
.content.zoom span {
  animation-name: fade;
}
.content span {
  @apply inline-block;
  animation: 0.5s linear infinite alternate;
}
.content span:nth-child(6n + 1) {
  animation-delay: 0.1s;
}
.content span:nth-child(6n + 2) {
  animation-delay: 0.2s;
}
.content span:nth-child(6n + 3) {
  animation-delay: 0.3s;
}
.content span:nth-child(6n + 4) {
  animation-delay: 0.4s;
}
.content span:nth-child(6n + 5) {
  animation-delay: 0.5s;
}
.content span:nth-child(6n + 6) {
  animation-delay: 0.6s;
}
.content span:nth-child(6n + 7) {
  animation-delay: 0.7s;
}

@keyframes fade {
  to {
    opacity: 0;
  }
}
</style>
